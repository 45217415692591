import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { RegularTextButton } from '../../buttons';
import { MusicPlaylistSearch } from '../shared/PlaylistSearch';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PlaylistState,
  GlobalState,
  VenueVideoPlaylistsResponse,
} from '../../../lib/types';
import { SET_PLAYLISTS_STATE } from '../../../store/store';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import { queryDiscoveryVideoPlaylists } from '../../../lib/gqlRequests';
import { ButtonGroup, Dialog } from '@mui/material';
import { CreatePlaylist } from '../../CreatePlaylist';
import { DeletePlaylists } from '../shared/DeletePlaylists';
import { PlaylistEdit } from '../shared/PlaylistEdit';
import { MEDIA_TYPES } from '../../../lib/constants';
import { ButtonGroupRow, PopupWindow } from '../../wrappers';

export const MusicVideo = () => {
  const { path } = useRouteMatch();
  const [deletePopup, setDeletePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const dispatch = useDispatch();
  const [needCreatePlaylist, setNeedCreatePlaylist] = useState(false);

  const { counter, selectedIds, editEntries } = useSelector(
    (state: GlobalState) => state['playlists'][MEDIA_TYPES.MUSIC_VIDEO]
  );

  const { load } = requestDataLoader<
    VenueVideoPlaylistsResponse,
    VenueVideoPlaylistsResponse['venue']['videoPlaylists']
  >({
    initialValue: [],
    identifier: 'videoPlaylistRequest',
    doRequest: () => queryDiscoveryVideoPlaylists(),
    onStateChange: (output) => {
      dispatch({
        type: SET_PLAYLISTS_STATE[MEDIA_TYPES.MUSIC_VIDEO],
        data: {
          loading: output.loading,
          playlists: output.data,
          counter: counter + 1,
          error: output.error,
        } as PlaylistState,
      });
    },
    formatResponse: (result) => result.venue.videoPlaylists,
  });

  if (counter === 0) load();

  return (
    <div>
      {/**HEADER */}
      <ButtonGroupRow>
        <ButtonGroup>
          <RegularTextButton
            text="Refresh"
            active={true}
            onClick={() => load(true)}
          />
          <RegularTextButton
            text="New Playlist"
            active={true}
            onClick={() => setNeedCreatePlaylist(true)}
          />
        </ButtonGroup>

        <ButtonGroup>
          <RegularTextButton
            text="Edit"
            active={Object.keys(editEntries).length > 0}
            onClick={() => {
              setEditPopup(true);
            }}
          />
          <RegularTextButton
            text="Delete"
            active={selectedIds.size > 0}
            onClick={() => setDeletePopup(true)}
          />
        </ButtonGroup>
      </ButtonGroupRow>

      {/**BODY */}
      <div>
        <Switch>
          <Route path={`${path}/search`}>
            <MusicPlaylistSearch mediaType={MEDIA_TYPES.MUSIC_VIDEO} />
          </Route>

          <Route path={`${path}`}>
            <Redirect to={`${path}/search`}></Redirect>
          </Route>
        </Switch>
      </div>

      {/* POPUPS */}
      <PopupWindow>
        <Dialog
          open={needCreatePlaylist}
          onBackdropClick={() => setNeedCreatePlaylist(false)}
          onClose={() => setNeedCreatePlaylist(false)}
        >
          <CreatePlaylist
            close={() => setNeedCreatePlaylist(false)}
            venueId={Number(process.env.REACT_APP_DISCOVERY_VENUE_ID)}
            mediaType={MEDIA_TYPES.MUSIC_VIDEO}
          />
        </Dialog>

        <Dialog
          open={editPopup}
          maxWidth="xl"
          onBackdropClick={() => setEditPopup(false)}
          onClose={() => setEditPopup(false)}
        >
          <PlaylistEdit
            close={() => setEditPopup(false)}
            mediaType={MEDIA_TYPES.MUSIC_VIDEO}
          />
        </Dialog>

        <Dialog
          open={deletePopup}
          onBackdropClick={() => setDeletePopup(false)}
          onClose={() => setDeletePopup(false)}
          maxWidth="xl"
        >
          <DeletePlaylists
            close={() => setDeletePopup(false)}
            mediaType={MEDIA_TYPES.MUSIC_VIDEO}
          />
        </Dialog>
      </PopupWindow>
    </div>
  );
};

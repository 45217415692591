import { FormTextInput } from '../../forms';
import { useDispatch, useSelector } from 'react-redux';
import { SET_PLAYLISTS_MANAGE_STATE } from '../../../store/store';
import { channelContentTableColumnResolver } from '../../../lib/columnResolvers';
import {
  ContentState,
  GlobalState,
  VideoTrackObject,
} from '../../../lib/types';
import {
  DataGridPro as DataGrid,
  GridColDef,
  GridColumnResizeParams,
  GridSelectionModel,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { DynamicDownloadButton } from '../../dynamicDownloadButton';
import { ComponentType, useCallback, useMemo, useState } from 'react';
import { PlayerOverlay, PreviewCell } from '../../PreviewCell';
import { ContentBody, ContentHeader } from '../../wrappers';
import { MEDIA_TYPES, PLAYLISTS_LIMIT } from '../../../lib/constants';

const CHANNEL_COLUMN_WIDTH_KEY = 'channelColumnWidth';

export const PlaylistTracksTable = () => {
  const dispatch = useDispatch();
  const { error, loading, selectedIds, tracks } = useSelector(
    (state: GlobalState) => state.playlistManagement[MEDIA_TYPES.CHANNEL]
  );

  const onRowSelectedModelCHange = useCallback(
    (m: GridSelectionModel) => {
      dispatch({
        type: SET_PLAYLISTS_MANAGE_STATE[MEDIA_TYPES.CHANNEL],
        data: {
          selectedIds: new Set(m.map((e) => Number(e))),
        } as ContentState,
      });
    },
    [dispatch]
  );

  const [confirmedText, setConfirmedText] = useState('');

  const columns: GridColDef[] = useMemo(() => {
    const cwStr = localStorage.getItem(CHANNEL_COLUMN_WIDTH_KEY);
    const columnWidth = cwStr && JSON.parse(cwStr);
    return channelContentTableColumnResolver.map((colResolver) => ({
      field: colResolver.field,
      headerName: colResolver.title,
      editable: colResolver.editable || false,
      type: colResolver.type || 'string',
      width:
        columnWidth && `${colResolver.field}` in columnWidth
          ? columnWidth[colResolver.field]
          : colResolver.flex || 100,
      hideSortIcons: true,
      headerAlign: 'left',
      valueGetter: (params) => {
        if (colResolver.prop) {
          return params.row[colResolver.prop];
        } else if (colResolver.resolve) {
          return colResolver.resolve(params.row as VideoTrackObject);
        }
        throw new Error(
          `Column resolver ${colResolver.field} (${colResolver.title}) should have either .prop or .resolve`
        );
      },
    }));
  }, []);

  const onColumnWidthChanges = useCallback((column: GridColumnResizeParams) => {
    const cwStr = localStorage.getItem(CHANNEL_COLUMN_WIDTH_KEY);
    const columnWidth: { [key: string]: number } = cwStr
      ? JSON.parse(cwStr)
      : {};
    columnWidth[column.colDef.field] = column.width;
    localStorage.setItem(CHANNEL_COLUMN_WIDTH_KEY, JSON.stringify(columnWidth));
  }, []);

  /**Since DataGrid updates selection model on each render, this workaround is used to prevent cyclic re-rendering */
  const ids = Array.from(selectedIds.values());
  const selectedRows = useMemo(() => {
    return ids;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids.join(',')]);

  const rows = useMemo(() => {
    if (confirmedText === '') {
      return tracks;
    }

    return tracks.filter(
      (track) =>
        track.name.toLowerCase().includes(confirmedText.toLocaleLowerCase()) ||
        track.artist?.name
          .toLowerCase()
          .includes(confirmedText.toLocaleLowerCase()) ||
        track.album?.name
          .toLowerCase()
          .includes(confirmedText.toLocaleLowerCase()) ||
        track.genre?.name
          .toLowerCase()
          .includes(confirmedText.toLocaleLowerCase()) ||
        track.isrc.toLowerCase().includes(confirmedText.toLocaleLowerCase())
    );
  }, [tracks, confirmedText]);

  const dataGridProps: typeof DataGrid extends ComponentType<infer T>
    ? T
    : unknown = useMemo(() => {
    return {
      checkboxSelection: true,
      columnBuffer: 50,
      rowCount: PLAYLISTS_LIMIT,
      density: 'compact',
      components: {
        Toolbar: GridToolbar,
      },
      rows,
      columnTypes: {
        contentUrlButton: {
          renderCell: (p: any) => {
            return (
              <DynamicDownloadButton
                text={'get file'}
                id={p.row.id}
                mediaType={MEDIA_TYPES.CHANNEL}
              />
            );
          },
        },
        contentPlaybackButton: {
          renderCell: (p: any) => {
            return (
              <PreviewCell mediaType={MEDIA_TYPES.CHANNEL} id={p.row.id} />
            );
          },
        },
      },
      selectionModel: selectedRows,
      onSelectionModelChange: onRowSelectedModelCHange,
      onColumnWidthChange: onColumnWidthChanges,
      columns,
      loading,
    };
  }, [
    rows,
    selectedRows,
    onRowSelectedModelCHange,
    columns,
    loading,
    onColumnWidthChanges,
  ]);

  return (
    <ContentBody>
      <ContentHeader>
        <div className="search-button">
          <FormTextInput
            id="searchChannelPlaylistText"
            type="text"
            // inputValue={filterText}
            placeholder="... enter filter text and press ENTER"
            onChange={(text) => {
              if (text === '') {
                setConfirmedText(text);
              }
            }}
            onSubmit={(text) => {
              setConfirmedText(text);
            }}
          />
        </div>
      </ContentHeader>

      {error && <div className="search-status-msg">Error: {error}</div>}

      <div style={{ height: '500px', maxHeight: '100%', width: '100%' }}>
        <DataGrid {...dataGridProps}></DataGrid>
      </div>
      <PlayerOverlay />
    </ContentBody>
  );
};

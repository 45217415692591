import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import {
  MANAGE_MUSIC_AUDIO_PATH,
  MANAGE_MUSIC_VIDEO_PATH,
  MANAGE_CHANNELS_PATH,
  MEDIA_TYPES,
} from '../../lib/constants';
import { GlobalState } from '../../lib/types';
import { MenuItem } from '../MenuItem';
import { MusicAudio } from './musicAudio/MusicAudio';
import { MusicVideo } from './musicVideo/MusicVideo';
import { Channels } from './channels/Channels';
import { ManagePlaylist as ManageAudioPlaylist } from './musicAudio/PlaylistTracks';
import { ManagePlaylist as ManageVideoPlaylist } from './musicVideo/PlaylistTracks';
import { ManagePlaylist as ManageChannels } from './channels/PlaylistTracks';

export const Playlists = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { playlistId: selectedAudioPlaylist } = useSelector(
    (state: GlobalState) => state.playlistManagement[MEDIA_TYPES.MUSIC_AUDIO]
  );
  const { playlistId: selectedVideoPlaylist } = useSelector(
    (state: GlobalState) => state.playlistManagement[MEDIA_TYPES.MUSIC_VIDEO]
  );
  const { playlistId: selectedChannelPlaylist } = useSelector(
    (state: GlobalState) => state.playlistManagement[MEDIA_TYPES.CHANNEL]
  );

  return (
    <div>
      <MenuItem
        text="MUSIC AUDIO"
        to={`${path}/${MEDIA_TYPES.MUSIC_AUDIO}`}
        selected={location.pathname.startsWith(
          `${path}/${MEDIA_TYPES.MUSIC_AUDIO}`
        )}
      ></MenuItem>
      <MenuItem
        text="MUSIC VIDEO"
        to={`${path}/${MEDIA_TYPES.MUSIC_VIDEO}`}
        selected={location.pathname.startsWith(
          `${path}/${MEDIA_TYPES.MUSIC_VIDEO}`
        )}
      ></MenuItem>
      <MenuItem
        text="AMBIENT VIDEO"
        to={`${path}/${MEDIA_TYPES.CHANNEL}`}
        selected={location.pathname.startsWith(
          `${path}/${MEDIA_TYPES.CHANNEL}`
        )}
      ></MenuItem>
      {selectedAudioPlaylist && (
        <MenuItem
          text={`Manage audio playlist ${selectedAudioPlaylist}`}
          to={`${path}/${MANAGE_MUSIC_AUDIO_PATH}/${selectedAudioPlaylist}`}
          selected={location.pathname.startsWith(
            `${path}/${MANAGE_MUSIC_AUDIO_PATH}`
          )}
        ></MenuItem>
      )}
      {selectedVideoPlaylist && (
        <MenuItem
          text={`Manage video playlist ${selectedVideoPlaylist}`}
          to={`${path}/${MANAGE_MUSIC_VIDEO_PATH}/${selectedVideoPlaylist}`}
          selected={location.pathname.startsWith(
            `${path}/${MANAGE_MUSIC_VIDEO_PATH}`
          )}
        ></MenuItem>
      )}
      {selectedChannelPlaylist && (
        <MenuItem
          text={`Manage channel ${selectedChannelPlaylist}`}
          to={`${path}/${MANAGE_CHANNELS_PATH}/${selectedChannelPlaylist}`}
          selected={location.pathname.startsWith(
            `${path}/${MANAGE_CHANNELS_PATH}`
          )}
        ></MenuItem>
      )}
      <hr />

      <Switch>
        <Route exact path={`${path}`}>
          {/**Set default path */}
          <Redirect to={`${path}/${MEDIA_TYPES.MUSIC_AUDIO}`}></Redirect>
        </Route>

        <Route path={`${path}/${MEDIA_TYPES.MUSIC_AUDIO}`}>
          <MusicAudio />
        </Route>

        <Route path={`${path}/${MEDIA_TYPES.MUSIC_VIDEO}`}>
          <MusicVideo />
        </Route>

        <Route path={`${path}/${MEDIA_TYPES.CHANNEL}`}>
          <Channels />
        </Route>

        <Route path={`${path}/${MANAGE_MUSIC_AUDIO_PATH}`}>
          <ManageAudioPlaylist />
        </Route>

        <Route path={`${path}/${MANAGE_MUSIC_VIDEO_PATH}`}>
          <ManageVideoPlaylist />
        </Route>

        <Route path={`${path}/${MANAGE_CHANNELS_PATH}`}>
          <ManageChannels />
        </Route>

        {/* <Route exact path={`${path}`}> */}
        {/**Set default path for section*/}
        {/* <Redirect to={`${path}`}></Redirect> */}
        {/* </Route> */}
      </Switch>
    </div>
  );
};

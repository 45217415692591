import { useState } from 'react';
import { FormTextInput } from './forms';

const LoginForm: (props: {
  onSubmit: (result: { email: string; password: string }) => any;
}) => JSX.Element = ({ onSubmit }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  return (
    <div className="login-form">
      <FormTextInput
        id="login_imput"
        name="email"
        type="email"
        onChange={(text) => {
          setEmail(text);
        }}
        placeholder="Email"
      ></FormTextInput>
      <FormTextInput
        id="pw_imput"
        name="password"
        type="password"
        onChange={(text) => {
          setPassword(text);
        }}
        placeholder="Password"
      ></FormTextInput>

      <div className="form-element">
        <button
          style={{ width: '100%' }}
          disabled={!email || !password}
          type="submit"
          onClick={() => {
            email && password && onSubmit({ email, password });
          }}
        >
          <div>Login to Audalize</div>
        </button>
      </div>
    </div>
  );
};

export { LoginForm };

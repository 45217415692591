import { Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MEDIA_TYPES } from '../../../lib/constants';
import { mutateEditTrackAttributes } from '../../../lib/gqlRequests';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import {
  AudioTrackObject,
  ContentState,
  GlobalState,
} from '../../../lib/types';
import { SET_CONTENT_STATE } from '../../../store/store';
import { TextCloseButton, TextSolidButton } from '../../buttons';
import { LoadingAnimation } from '../../LoadingAnimation';
import { ContentHeader, PopupWindow } from '../../wrappers';

export const MusicAudioEdit = (props: { close: Function }) => {
  const { editEntries } = useSelector(
    (state: GlobalState) => state['content'][MEDIA_TYPES.MUSIC_AUDIO]
  );
  const dispatch = useDispatch();
  const [checkout, setCheckout] = useState(false);

  useEffect(() => {
    if (Object.keys(editEntries).length === 0) {
      props.close();
    }
  }, [editEntries, props]);

  return (
    <PopupWindow>
      <Dialog
        open={checkout}
        onBackdropClick={() => setCheckout(false)}
        onClose={() => setCheckout(false)}
      >
        <EditTracksCheckout
          editEntries={editEntries}
          close={() => setCheckout(false)}
        />
      </Dialog>

      {Object.entries(editEntries).map(([id, obj]) => (
        <div className="buttons-inline" key={id}>
          <ContentHeader>{id}:</ContentHeader>
          {Object.entries(obj).map(([prop, val]) => {
            return (
              <TextCloseButton
                key={id + ':' + prop}
                text={`${prop}: ${val}`}
                active={true}
                onClick={() => {
                  delete obj[prop as keyof typeof obj];

                  const newEditEntries = { ...editEntries };
                  if (Object.keys(obj).length === 0) {
                    delete newEditEntries[id];
                  } else {
                    delete newEditEntries[id][
                      prop as keyof typeof newEditEntries['']
                    ];
                  }

                  dispatch({
                    type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_AUDIO],
                    data: {
                      editEntries: newEditEntries,
                    } as ContentState,
                  });
                }}
              />
            );
          })}
        </div>
      ))}

      <ContentHeader>
        <TextSolidButton
          text="Back"
          onClick={() => {
            props.close();
          }}
        />
        <TextSolidButton
          text="Update"
          onClick={() => {
            setCheckout(true);
          }}
        />
      </ContentHeader>
    </PopupWindow>
  );
};

export const EditTracksCheckout = (props: {
  editEntries: GlobalState['content'][MEDIA_TYPES.MUSIC_AUDIO]['editEntries'];
  close: () => void;
}) => {
  const dispatch = useDispatch();
  const { close, editEntries } = props;
  const [startedLoad, setStartedLoad] = useState(false);

  const { searchResult } = useSelector<
    GlobalState,
    GlobalState['content'][MEDIA_TYPES.MUSIC_AUDIO]
  >((state: GlobalState) => state['content'][MEDIA_TYPES.MUSIC_AUDIO]);

  const [updateState, setUpdateState] = useState<{
    editEntries?: typeof editEntries;
    searchResult?: typeof searchResult;
  }>({});

  useEffect(() => {
    if (!updateState.editEntries) return;

    dispatch({
      type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_AUDIO],
      data: {
        editEntries: updateState.editEntries,
        searchResult: updateState.searchResult,
      } as ContentState,
    });
  }, [updateState, dispatch]);

  const [editTrackStatuses, setEditTrackStatuses] = useState(
    Object.entries(editEntries).map(([id, entry]) => ({
      id: id,
      loading: false,
      error: null as Error | null,
      data: null as null | AudioTrackObject,
    }))
  );

  const loaders = Object.entries(editEntries).map(([id, entry], i) =>
    requestDataLoader({
      identifier: `editTrack/${id}`,
      initialValue: null as null | AudioTrackObject,
      doRequest: () =>
        mutateEditTrackAttributes({
          id: Number(id),
          label: entry.label,
          tempo: Number(entry.tempo),
          artistName: entry.artistName,
          name: entry.name,
          releaseYear: Number(entry.year),
        }),
      formatResponse: (res) => res.editTrackAttributes,
      onStateChange: (output) => {
        editTrackStatuses[i] = {
          ...editTrackStatuses[i],
          data: output.data,
          error: output.error,
          loading: output.loading,
        };

        if (output.data) {
          const data = output.data;
          const newEditEntries = { ...editEntries };
          delete newEditEntries[data.id];

          searchResult.forEach((r, i, arr) => {
            if (r.id !== data.id) return;

            arr[i] = data;
          });

          setUpdateState({
            editEntries: newEditEntries,
            searchResult: [...searchResult],
          });
        }

        setEditTrackStatuses([...editTrackStatuses]);
      },
    })
  );

  return (
    <PopupWindow>
      <div>Update {Object.keys(editEntries).length} tracks?</div>
      <table style={{ width: '100%' }}>
        <tbody>
          {editTrackStatuses.map(({ error, loading, id, data }, i) => (
            <tr key={id}>
              <td style={{ whiteSpace: 'nowrap' }}>{id}</td>
              <td>
                <LoadingAnimation
                  sizePx={20}
                  animate={loading}
                  color={
                    data
                      ? 'darkgreen'
                      : loading
                      ? 'orange'
                      : error
                      ? 'tomato'
                      : 'black'
                  }
                />
              </td>
              <td>
                {error
                  ? String(error?.toString())
                  : data
                  ? 'Done'
                  : loading
                  ? `Loading`
                  : 'TODO'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {startedLoad && (
        <div>Edited tracks will be available in search after a minute</div>
      )}
      <div>
        <ContentHeader>
          {startedLoad || (
            <TextSolidButton
              text="Confirm"
              onClick={() => {
                setStartedLoad(true);

                loaders.forEach(({ load }) => load());
              }}
            />
          )}

          <TextSolidButton
            text="Back"
            onClick={() => {
              close();
            }}
          />
        </ContentHeader>
      </div>
    </PopupWindow>
  );
};

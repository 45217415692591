import {
  ButtonGroup,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../lib/types';
import { RegularTextButton, TextSolidButton } from '../buttons';
import { FormTextInput } from '../forms';
import { ButtonGroupRow, PopupWindow } from '../wrappers';
import { CloneProgress } from './CloneProgress';

export const CloneSchedule = (props: { close: Function }) => {
  // const dispatch = useDispatch();
  const [filterText, setFilterText] = useState('');

  const { scheduleId } = useSelector((state: GlobalState) => state.scheduler);
  const venues = useSelector((state: GlobalState) => state.venues);

  const [selectedZoneId, setSelectedZoneId] = useState<number | null>(null);
  const [selectedVenueId, setSelectedVenueId] = useState<number | null>(null);

  const [showProgress, setShowProgress] = useState(false);

  const filteredVenues = useMemo(() => {
    const filterLowerCase = filterText.toLowerCase();

    return (
      venues?.filter(
        (v) =>
          v.name.toLowerCase().includes(filterLowerCase) ||
          v.zones.some((z) => z.name.toLowerCase().includes(filterLowerCase))
      ) || null
    );
  }, [venues, filterText]);

  const { selectedVenue } = useMemo(() => {
    if (selectedVenueId === null) {
      return {};
    }

    const selectedVenue =
      filteredVenues?.find((venue) => venue.id === selectedVenueId) || null;

    const selectedZone = selectedVenue?.zones.find(
      (z) => z.id === selectedZoneId
    );

    return { selectedVenue, selectedZone };
  }, [selectedVenueId, filteredVenues, selectedZoneId]);

  const { selectedVenueId: defaultVenueId, selectedZoneId: defaultZoneId } =
    useMemo(() => {
      return { selectedVenueId, selectedZoneId };
    }, [selectedVenueId, selectedZoneId]);

  const selector = (
    <div style={{ width: '100%' }}>
      <div>
        <FormTextInput
          id="filterVenues"
          type="text"
          placeholder="filter by venue or zone"
          onChange={(text) => {
            setFilterText(text);
          }}
        />
      </div>
      <hr />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl className="modal-vertical-scroll">
            <FormLabel id="venues-label">Venue</FormLabel>
            <RadioGroup
              aria-labelledby="venues-label"
              defaultValue={defaultVenueId}
              name="radio-buttons-group"
              onChange={(e) => {
                const selectedVenueId = Number(e.target.value);

                setSelectedVenueId(selectedVenueId);
              }}
            >
              {filteredVenues?.map((v) => (
                <FormControlLabel
                  value={v.id}
                  control={<Radio />}
                  label={v.name}
                  key={v.id}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className="modal-vertical-scroll">
            <FormLabel id="venues-label">Zone</FormLabel>
            <RadioGroup
              aria-labelledby="zones-label"
              defaultValue={defaultZoneId}
              name="radio-buttons-group"
              onChange={(e) => {
                const selectedZoneId = Number(e.target.value);

                setSelectedZoneId(selectedZoneId);
              }}
            >
              {selectedVenue?.zones.map((z) => (
                <FormControlLabel
                  value={z.id}
                  control={<Radio />}
                  label={z.name}
                  key={z.id}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <PopupWindow>
      <ButtonGroupRow>
        <ButtonGroup></ButtonGroup>
        <ButtonGroup>
          <RegularTextButton
            text="Close"
            active={true}
            onClick={() => props.close()}
          />
        </ButtonGroup>
      </ButtonGroupRow>
      {/* {venuesLoading && <LoadingAnimation animate={true} sizePx={20} />}
      {venuesError && (
        <div>
          <b>Error when fetching venues:</b> {venuesError}
        </div>
      )} */}

      {venues && selector}

      <TextSolidButton
        active={!!(selectedVenueId && selectedZoneId)}
        text="Clone"
        onClick={() => setShowProgress(true)}
      />

      <Dialog
        open={!!scheduleId && showProgress && !!selectedZoneId}
        fullWidth={true}
        onBackdropClick={() => setShowProgress(false)}
        onClose={() => setShowProgress(false)}
      >
        <CloneProgress
          scheduleId={scheduleId!}
          zoneId={selectedZoneId!}
          venueId={selectedVenueId!}
          close={() => setShowProgress(false)}
        />
      </Dialog>
    </PopupWindow>
  );
};

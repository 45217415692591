import { Box, Button, Dialog } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  queryCatalogBucketConnection,
  queryCatalogPlaylistConnection,
  queryLibraryBucketConnection,
  queryLibraryPlaylistConnection,
} from '../../lib/gqlRequests';
import { requestDataLoader } from '../../lib/requestDataLoader';
import {
  Bucket,
  LIST_SOURCE,
  LIST_TYPE,
  GlobalState,
  PlaylistObject,
  Connection,
  DraggedItemType,
  Timeslot,
  EntryState,
} from '../../lib/types';
import { ACTION_TYPES } from '../../store/store';
import { HighlightedTextButton, RegularTextButton } from '../buttons';
import { FormTextInput } from '../forms';
import { LoadingAnimation } from '../LoadingAnimation';
import { LIST_SIZE_LIMIT } from '../../lib/constants';
import {
  PlaylistConnectionResponse,
  BucketConnectionResponse,
  ConnectionState,
} from '../../lib/types';
import { RemainingSpaceSpace, UpToHalfSpace } from '../wrappers';
import { timeslotPlayableItem, timeslotType } from '../../lib/scheduleUtils';
import { RemovePlaylistCheckout } from './RemovePlaylistCheckout';
import { RemoveBucketCheckout } from './RemoveBucketCheckout';

export const SchedulerSideBar = () => {
  const dispatch = useDispatch();

  const { selectedContentType, selectedContentSource } = useSelector(
    (state: GlobalState) => state.scheduler
  );

  return (
    <Box height="90vh" style={{ overflowY: 'auto' }}>
      {/* <UpToHalfSpace> */}
      <Box style={{ overflowX: 'auto' }}>
        <SchedulePlaylists />
      </Box>
      {/* </UpToHalfSpace> */}
      <Box style={{ overflowX: 'auto' }}>
        <Box paddingTop="10px">
          <HighlightedTextButton
            text={LIST_TYPE.PLAYLIST}
            selected={selectedContentType === LIST_TYPE.PLAYLIST}
            onClick={() =>
              dispatch({
                type: ACTION_TYPES.SET_SCHEDULER,
                data: { selectedContentType: LIST_TYPE.PLAYLIST },
              } as Partial<GlobalState['scheduler']>)
            }
          />
          <HighlightedTextButton
            text={LIST_TYPE.BUCKET}
            selected={selectedContentType === LIST_TYPE.BUCKET}
            onClick={() =>
              dispatch({
                type: ACTION_TYPES.SET_SCHEDULER,
                data: { selectedContentType: LIST_TYPE.BUCKET },
              } as Partial<GlobalState['scheduler']>)
            }
          />
        </Box>
        <Box>
          <HighlightedTextButton
            text={LIST_SOURCE.LIBRARY}
            selected={selectedContentSource === LIST_SOURCE.LIBRARY}
            onClick={() =>
              dispatch({
                type: ACTION_TYPES.SET_SCHEDULER,
                data: { selectedContentSource: LIST_SOURCE.LIBRARY },
              } as Partial<GlobalState['scheduler']>)
            }
          />
          <HighlightedTextButton
            text={LIST_SOURCE.CATALOGUE}
            selected={selectedContentSource === LIST_SOURCE.CATALOGUE}
            onClick={() =>
              dispatch({
                type: ACTION_TYPES.SET_SCHEDULER,
                data: { selectedContentSource: LIST_SOURCE.CATALOGUE },
              } as Partial<GlobalState['scheduler']>)
            }
          />
        </Box>
        <List />
      </Box>
    </Box>
  );
};

const SchedulePlaylists = () => {
  const dispatch = useDispatch();
  const { scheduleTimeslots, lastTimeslotStates } = useSelector(
    (state: GlobalState) => state.scheduler
  );

  const items = useMemo(() => {
    const items = new Map<string, Timeslot>();

    if (!scheduleTimeslots) {
      return [];
    }

    scheduleTimeslots.forEach((ts) => {
      if (ts.state === EntryState.DELETED) {
        return;
      }

      const key = timeslotType(ts) + timeslotPlayableItem(ts).id;

      if (items.has(key)) {
        return;
      }

      items.set(key, ts);
    });

    const sortedItems = Array.from(items.entries())
      .sort(([keyLeft], [keyRight]) => {
        return keyLeft > keyRight ? 1 : -1;
      })
      .map(([key, value]) => ({ key, ts: value }));

    return sortedItems;
  }, [scheduleTimeslots]);

  return (
    <Box>
      <Box paddingBottom="10px">IN THIS SCHEDULE:</Box>
      <Box>
        {items.map((item) => {
          return (
            <Playable
              key={item.key}
              text={`${timeslotPlayableItem(item.ts).name} (${timeslotType(
                item.ts
              )})}`}
              itemType={timeslotType(item.ts)}
              item={timeslotPlayableItem(item.ts)}
              onRemove={() => {
                const newTimeslots = scheduleTimeslots?.map((t) => {
                  if (
                    t.playlist
                      ? t.playlist.id === item.ts.playlist?.id
                      : t.bucket!.id === item.ts.bucket?.id
                  ) {
                    return {
                      ...t,
                      originalTs: t,
                      state: EntryState.DELETED,
                    };
                  }

                  return t;
                });

                dispatch({
                  type: ACTION_TYPES.SET_SCHEDULER,
                  data: {
                    scheduleTimeslots: newTimeslots,
                    lastTimeslotStates: [...lastTimeslotStates, newTimeslots],
                  } as Partial<GlobalState['scheduler']>,
                });
              }}
            />
          );
        })}
      </Box>
      {!items.length && <Box width="100%">(no items)</Box>}
    </Box>
  );
};

const List = () => {
  const dispatch = useDispatch();
  const scheduler = useSelector((state: GlobalState) => state.scheduler);
  const venues = useSelector((state: GlobalState) => state.venues);
  const [removePlaylistId, setRemovePlaylistId] = useState(
    null as number | null
  );
  const [removeBucketId, setRemoveBucketId] = useState(null as number | null);

  const {
    selectedContentSource,
    selectedContentType,
    selectedVenueId,
    selectedZoneId,
    listFilterText,
  } = scheduler;

  const prop = useMemo(() => {
    let prop: keyof Pick<
      GlobalState['scheduler'],
      | 'libraryPlaylistConnection'
      | 'catalogPlaylistConnection'
      | 'libraryBucketConnection'
      | 'catalogBucketConnection'
    >;

    if (selectedContentSource === LIST_SOURCE.LIBRARY) {
      if (selectedContentType === LIST_TYPE.BUCKET) {
        prop = 'libraryBucketConnection';
      } else {
        prop = 'libraryPlaylistConnection';
      }
    } else {
      if (selectedContentType === LIST_TYPE.BUCKET) {
        prop = 'catalogBucketConnection';
      } else {
        prop = 'catalogPlaylistConnection';
      }
    }

    if (!prop) {
      throw new Error(
        'Cannot find necessary connection. This is a bug, report to devs'
      );
    }

    return prop;
  }, [selectedContentSource, selectedContentType]);

  const selectedZone = useMemo(() => {
    return venues
      ?.find((v) => v.id === selectedVenueId)
      ?.zones.find((z) => z.id === selectedZoneId);
  }, [venues, selectedVenueId, selectedZoneId]);

  if (!prop) {
    throw new Error(
      'Cannot find necessary connection. This is a bug, report to devs'
    );
  }

  const connectionState = scheduler[prop];

  const { load: loadSchedule } = useMemo(() => {
    if (!selectedZone || !selectedVenueId) {
      return { load: () => Promise.resolve([]) };
    }
    const endCursor = connectionState.connection?.pageInfo.endCursor;

    const requestFnc =
      prop === 'libraryPlaylistConnection'
        ? queryLibraryPlaylistConnection
        : prop === 'libraryBucketConnection'
        ? queryLibraryBucketConnection
        : prop === 'catalogPlaylistConnection'
        ? queryCatalogPlaylistConnection
        : queryCatalogBucketConnection;

    return requestDataLoader<
      PlaylistConnectionResponse | BucketConnectionResponse,
      Connection<PlaylistObject> | Connection<Bucket> | null
    >({
      doRequest: () => {
        return requestFnc({
          first: LIST_SIZE_LIMIT,
          mediaType: selectedZone.mediaType,
          venueId: selectedVenueId,
          after: endCursor || undefined,
        });
      },
      formatResponse: (response) =>
        (response as PlaylistConnectionResponse).playlistsConnection ||
        (response as BucketConnectionResponse).bucketsConnection || {
          totalCount: 0,
          pageInfo: {
            hasNextPage: false,
            hasPreviousPage: false,
          },
          edges: [],
        },
      identifier: `list/${selectedContentSource}/${selectedContentType}/${endCursor}`,
      initialValue: connectionState.connection as
        | Connection<PlaylistObject>
        | Connection<Bucket>
        | null,
      onStateChange: (state) => {
        const value = {
          error: state.error,
          loading: state.loading,
        } as typeof connectionState;

        if (state.data) {
          value.connection = {
            ...connectionState.connection,
            edges: [
              ...(connectionState.connection?.edges || []),
              ...(state.data?.edges || []),
            ],
            pageInfo: state.data?.pageInfo,
            totalCount: state.data?.totalCount,
          };
        }

        dispatch({
          type: ACTION_TYPES.SET_SCHEDULER,
          data: {
            [prop]: value,
          } as Partial<GlobalState['scheduler']>,
        });
      },
    });
  }, [
    selectedZone,
    selectedVenueId,
    connectionState.connection,
    prop,
    selectedContentSource,
    selectedContentType,
    dispatch,
  ]);

  const [lastLoadedZone, setLastLoadedZone] = useState<
    typeof selectedZone | null
  >(null);

  const uniqueItems = useMemo(() => {
    const set = new Set<string>();

    return (connectionState.connection?.edges || []).filter((edge) => {
      const key = edge.node.__typename + '/' + edge.node.id;
      if (set.has(key)) {
        return false;
      }

      set.add(key);

      if (!listFilterText) {
        return true;
      }

      return edge.node.name
        .toLowerCase()
        .includes(listFilterText.toLowerCase());
    });
  }, [connectionState.connection?.edges, listFilterText]);

  const listInnerRef = useRef<any>(null);

  const connectionExists = connectionState.connection != null;

  useEffect(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const endReached = scrollTop + clientHeight === scrollHeight;
      const needMore =
        !connectionState.loading &&
        (!connectionExists || connectionState.connection?.pageInfo.hasNextPage);
      const differentZone = selectedZone !== lastLoadedZone;

      if ((endReached && needMore) || differentZone) {
        loadSchedule();
        setLastLoadedZone(selectedZone);
      }
    }
  }, [
    connectionState.loading,
    connectionExists,
    connectionState.connection?.pageInfo.hasNextPage,
    loadSchedule,
    listInnerRef,
    lastLoadedZone,
    selectedZone,
  ]);

  return (
    <Box paddingTop="10px">
      <Box
        style={{ display: 'flex', alignItems: 'center' }}
        paddingBottom="10px"
      >
        <Box marginRight="10px" width="100%">
          <FormTextInput
            id="filterList"
            type="text"
            inputValue={listFilterText}
            placeholder="... filter"
            onChange={(text) => {
              dispatch({
                type: ACTION_TYPES.SET_SCHEDULER,
                data: { listFilterText: text } as GlobalState['scheduler'],
              });
            }}
          />
        </Box>
        <Box style={{ flexGrow: 1 }}>
          {(connectionState.loading && (
            <LoadingAnimation animate={connectionState.loading} sizePx={30} />
          )) || (
            <RegularTextButton
              text="Refresh"
              active={true}
              onClick={() => {
                loadSchedule();
                dispatch({
                  type: ACTION_TYPES.SET_SCHEDULER,
                  data: {
                    [prop]: {
                      loading: false,
                      error: null,
                    },
                  } as Partial<ConnectionState<Bucket | PlaylistObject>>,
                });
              }}
            />
          )}
        </Box>
      </Box>
      {connectionState.error}
      <Box ref={listInnerRef}>
        {uniqueItems?.map((edge) => {
          const item = edge.node;
          const itemType =
            item.__typename === 'Playlist' ? 'playlist' : 'bucket';

          const onRemove =
            selectedContentSource === LIST_SOURCE.LIBRARY
              ? () => {
                  if (itemType === 'playlist') {
                    setRemovePlaylistId(item.id);
                  } else {
                    setRemoveBucketId(item.id);
                  }
                }
              : undefined;

          return (
            <Playable
              key={edge.node.__typename + '/' + edge.node.id}
              item={item}
              itemType={itemType}
              text={`${item.name} (${item.__typename.toLowerCase()})`}
              onRemove={onRemove}
            />
          );
        })}
      </Box>

      {/* Popups */}
      <Dialog
        open={!!removePlaylistId}
        onBackdropClick={() => setRemovePlaylistId(null)}
        onClose={() => setRemovePlaylistId(null)}
      >
        <RemovePlaylistCheckout
          close={() => setRemovePlaylistId(null)}
          id={removePlaylistId!}
        />
      </Dialog>
      <Dialog
        open={!!removeBucketId}
        onBackdropClick={() => setRemoveBucketId(null)}
        onClose={() => setRemoveBucketId(null)}
      >
        <RemoveBucketCheckout
          close={() => setRemoveBucketId(null)}
          id={removeBucketId!}
        />
      </Dialog>
    </Box>
  );
};

const Playable = (props: {
  text: string;
  itemType: DraggedItemType;
  item: Bucket | PlaylistObject;
  onRemove?: () => void;
}) => {
  const dispatch = useDispatch();

  return (
    <Box
      draggable={true}
      style={{
        userSelect: 'none',
        cursor: 'move',
        // zIndex: 10,
        backgroundColor: '#ddd',
        borderRadius: '5px',
        width: '95%',
        padding: '5px',
        margin: '2px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
      onDragStart={() => {
        dispatch({
          type: ACTION_TYPES.SET_SCHEDULER,
          data: {
            draggedItem: props.item,
            draggedItemType: props.itemType,
          } as GlobalState['scheduler'],
        });
      }}
      onDragEnd={(e) => {
        dispatch({
          type: ACTION_TYPES.SET_SCHEDULER,
          data: {
            draggedItem: null,
          } as GlobalState['scheduler'],
        });
      }}
    >
      <Box style={{ overflowX: 'hidden' }}>{props.text}</Box>
      {props.onRemove && (
        <RegularTextButton text="X" onClick={props.onRemove} />
      )}
    </Box>
  );
};

import {
  LOCAL_STORAGE_VENUES_KEY,
  LOCAL_STORAGE_VENUE_ID_KEY,
  LOCAL_STORAGE_ZONE_ID_KEY,
} from '../lib/constants';
import { Venue } from '../lib/types';

class VenueService {
  getVenues() {
    const value = localStorage.getItem(LOCAL_STORAGE_VENUES_KEY);

    return (value && JSON.parse(value)) || null;
  }

  setVenues(venues: Venue[]) {
    localStorage.setItem(LOCAL_STORAGE_VENUES_KEY, JSON.stringify(venues));
  }

  setVenueId(venueId: number) {
    localStorage.setItem(LOCAL_STORAGE_VENUE_ID_KEY, String(venueId));
  }

  getVenueId() {
    const value = localStorage.getItem(LOCAL_STORAGE_VENUE_ID_KEY);
    return value ? Number(value) : null;
  }

  setZoneId(zoneId: number | null) {
    localStorage.setItem(LOCAL_STORAGE_ZONE_ID_KEY, String(zoneId));
  }

  getZoneId() {
    const value = localStorage.getItem(LOCAL_STORAGE_ZONE_ID_KEY);
    return value ? Number(value) : null;
  }
}

export const venueService = new VenueService();

import { useSelector } from 'react-redux';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { Content } from '../components/contentSection/Content';
import { MenuItem, MenuRow } from '../components/MenuItem';
import { CONTENT_PATH, PLAYLISTS_PATH, SCHEDULER_PATH } from '../lib/constants';
import { GlobalState } from '../lib/types';
import { Playlists } from './playlistsSection/Playlists';
import { Scheduler } from './scheduler/Scheduler';

export const Main: () => JSX.Element = () => {
  const location = useLocation();
  const token = useSelector((state: GlobalState) => state.authToken);

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <div>
      <MenuRow>
        <MenuItem
          text="CONTENT"
          to={CONTENT_PATH}
          selected={location.pathname.startsWith(CONTENT_PATH)}
        />
        <MenuItem
          text="PLAYLISTS"
          to={PLAYLISTS_PATH}
          selected={location.pathname.startsWith(PLAYLISTS_PATH)}
        />
        <MenuItem
          text="SCHEDULER"
          to={SCHEDULER_PATH}
          selected={location.pathname.startsWith(SCHEDULER_PATH)}
        />
        {/* <MenuItem text="TOPICS" to="/topics" /> */}
      </MenuRow>

      <Switch>
        <Route exact path="/">
          {/**Set default path */}
          <Redirect to={CONTENT_PATH}></Redirect>
        </Route>

        <Route path={CONTENT_PATH}>
          <Content />
        </Route>

        <Route path={PLAYLISTS_PATH}>
          <Playlists />
        </Route>

        <Route path={SCHEDULER_PATH}>
          <Scheduler />
        </Route>

        <Route path="/">
          <Redirect to="/"></Redirect>
        </Route>
      </Switch>
    </div>
  );
};

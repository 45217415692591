import { Box, ButtonGroup } from '@mui/material';
import { useMemo, useState } from 'react';
import { mutateRemovePlaylist } from '../../lib/gqlRequests';
import { ButtonGroupRow, PopupWindow } from '../wrappers';
import { LoadingAnimation } from '../LoadingAnimation';
import { RegularTextButton } from '../buttons';
import { requestDataLoader } from '../../lib/requestDataLoader';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../lib/types';
import { ACTION_TYPES } from '../../store/store';

export const RemovePlaylistCheckout = (props: {
  id: number;
  close: Function;
}) => {
  const dispatch = useDispatch();
  const [{ data, error, loading }, setState] = useState({
    loading: false,
    error: null as null | string,
    data: null as boolean | null,
  });

  const { load } = useMemo(() => {
    return requestDataLoader({
      doRequest: async () => {
        const result = await mutateRemovePlaylist({
          id: props.id,
        });

        return result;
      },
      onStateChange: (state) => {
        setState({
          data: state.data,
          loading: state.loading,
          error: state.error && String(state.error),
        });

        if (state.data) {
          dispatch({
            type: ACTION_TYPES.REMOVE_PLAYLIST_FROM_LIBRARY,
            data: props.id,
          });
        }
      },
      initialValue: false,
      identifier: `removePlaylist`,
      formatResponse: (result) => result.removePlaylist,
    });
  }, [props.id]);

  return (
    <PopupWindow>
      <Box>Delete playlist?</Box>
      {data && <Box color="green">Playlist deleted</Box>}
      {error && <Box color="red">{error}</Box>}
      {loading && (
        <Box display="flex" justifyContent="center">
          <LoadingAnimation animate={true} sizePx={20} color="darkblue" />
        </Box>
      )}
      <Box>
        <ButtonGroupRow>
          <ButtonGroup>
            <RegularTextButton
              text="Delete"
              active={!data && !loading}
              onClick={() => {
                load();
              }}
            />
            <RegularTextButton
              text="Close"
              active={true}
              onClick={() => {
                props.close();
              }}
            />
          </ButtonGroup>
        </ButtonGroupRow>
      </Box>
    </PopupWindow>
  );
};

import { Box, ButtonGroup, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import { mutateBackupSchedule } from '../../lib/gqlRequests';
import { ButtonGroupRow, PopupWindow } from '../wrappers';
import { LoadingAnimation } from '../LoadingAnimation';
import { RegularTextButton } from '../buttons';
import { requestDataLoader } from '../../lib/requestDataLoader';

export const BackupCheckout = (props: {
  scheduleId: number;
  close: Function;
}) => {
  const { scheduleId } = props;

  const [name, setName] = useState<string>('');

  const [{ data, error, loading }, setState] = useState({
    loading: false,
    error: null as null | string,
    data: null as number | null,
  });

  const { load } = useMemo(() => {
    return requestDataLoader({
      doRequest: async () => {
        const result = await mutateBackupSchedule({
          id: scheduleId,
          name,
        });

        return result;
      },
      onStateChange: (state) =>
        setState({
          data: state.data,
          loading: state.loading,
          error: state.error && String(state.error),
        }),
      initialValue: null as null | number,
      identifier: `backupSchedule`,
      formatResponse: (result) => result.cloneSchedule.id,
    });
  }, [scheduleId, name]);

  return (
    <PopupWindow>
      <Box>
        <TextField
          id="backup-name"
          label="Backup Name"
          variant="outlined"
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
        />
      </Box>
      {data && <Box color="green">Done! Backup schedule id: {data}</Box>}
      {error && <Box color="red">{error}</Box>}
      {loading && (
        <Box display="flex" justifyContent="center">
          <LoadingAnimation animate={true} sizePx={20} color="darkblue" />
        </Box>
      )}
      <Box>
        <ButtonGroupRow>
          <ButtonGroup>
            <RegularTextButton
              text="Backup"
              active={!data && !loading && !!name}
              onClick={() => {
                load();
              }}
            />
            <RegularTextButton
              text="Close"
              active={true}
              onClick={() => {
                props.close();
              }}
            />
          </ButtonGroup>
        </ButtonGroupRow>
      </Box>
    </PopupWindow>
  );
};

import { GlobalState, UploadFile } from '../../../lib/types';
import { TextCloseButton, TextSolidButton } from '../../buttons';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SET_CONTENT_STATE } from '../../../store/store';
import { Dialog } from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';
import { FormTextInput } from '../../forms';
import UploadMusicModal from './UploadMusicModal';
import { humanSizeBytes } from '../../../lib/utils';
import { ContentBody, ContentHeader } from '../../wrappers';
import { MEDIA_TYPES } from '../../../lib/constants';

export const MusicAudioUpload = () => {
  const uploadFiles = useSelector(
    (state: GlobalState) =>
      state['content'][MEDIA_TYPES.MUSIC_AUDIO].uploadFiles
  );
  const dispatch = useDispatch();

  const [checkout, setCheckout] = useState(false);

  const setUploadFiles = useCallback(
    (files: Array<UploadFile>) => {
      dispatch({
        type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_AUDIO],
        data: {
          uploadFiles: files,
        },
      });
    },
    [dispatch]
  );

  const handleUploadOnChange = useCallback(
    (files: File[]) => {
      setUploadFiles([
        ...uploadFiles,
        ...files
          .filter((f) => !uploadFiles.some((uplF) => uplF.file === f))
          .map((file) => ({
            file,
            name: '',
            artistName: '',
            isrc: '',
          })),
      ]);
    },
    [setUploadFiles, uploadFiles]
  );

  const removeFile = useCallback(
    (item: UploadFile) => {
      setUploadFiles(uploadFiles.filter((f) => f !== item));
    },
    [setUploadFiles, uploadFiles]
  );

  const setFileValue = useCallback(
    (item: UploadFile) => {
      setUploadFiles([
        ...uploadFiles.filter((f) => f.file.name !== item.file.name),
        item,
      ]);
    },
    [setUploadFiles, uploadFiles]
  );

  return (
    <ContentBody>
      <DropzoneArea
        initialFiles={uploadFiles.map((f) => f.file)}
        onChange={handleUploadOnChange}
        filesLimit={1000}
        maxFileSize={200 * 1024 * 1024}
        acceptedFiles={['audio/mpeg']}
        showFileNames={false}
        showPreviews={false}
        showPreviewsInDropzone={false}
        showFileNamesInPreview={false}
      />

      {!!uploadFiles.length && (
        <div className="buttons-inline">
          <TextSolidButton
            text="Clear all uploads"
            active={!!uploadFiles.length}
            onClick={() => setUploadFiles([])}
          />
        </div>
      )}

      <div>
        {uploadFiles.map((item) => (
          <ContentHeader key={item.file.name}>
            <FormTextInput
              onChange={(name) => setFileValue({ ...item, name })}
              placeholder="[Override track name]"
              type="text"
              pattern=".*\w{1,}.*"
            />

            <FormTextInput
              onChange={(artistName) => setFileValue({ ...item, artistName })}
              placeholder="[Artist name]"
              type="text"
            />

            <FormTextInput
              onChange={(isrc) => setFileValue({ ...item, isrc })}
              placeholder="[Override ISRC]"
              type="text"
            />

            <TextCloseButton
              text={`${item.file.name} (${humanSizeBytes(item.file.size)})`}
              active
              onClick={() => removeFile(item)}
            />
          </ContentHeader>
        ))}
      </div>

      {!!uploadFiles.length && (
        <div className="buttons-inline">
          <TextSolidButton
            text="Upload"
            active={
              !uploadFiles.some(
                (file) => file.name && !/.*\w{1,}.*/.test(file.name)
              )
            }
            onClick={() => setCheckout(true)}
          />
        </div>
      )}

      <Dialog
        open={checkout}
        onBackdropClick={() => setCheckout(false)}
        onClose={() => setCheckout(false)}
        maxWidth={'lg'}
      >
        <UploadMusicModal
          close={() => setCheckout(false)}
          removeFromUploadFiles={(file) =>
            setUploadFiles([...uploadFiles.filter((f) => f === file)])
          }
          files={uploadFiles.map((file) => ({
            file: file.file,
            name: file.name,
            fileName: file.name,
            artistName: file.artistName,
            isrc: file.isrc,
          }))}
        />
      </Dialog>
    </ContentBody>
  );
};

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button } from '@mui/material';

import { Link } from 'react-router-dom';

export const RegularTextButton = (props: {
  onClick?: () => void;
  active?: boolean;
  text: string;
  description?: string;
}) => {
  const active = props.active === undefined ? true : props.active;
  return (
    <button
      className={`regular-text-button`}
      onClick={props.onClick}
      disabled={!active}
      style={{ fontWeight: active ? 'bold' : 'normal' }}
      title={props.description}
    >
      {props.text}
    </button>
  );
};

export const HighlightedTextButton = (props: {
  onClick?: () => void;
  active?: boolean;
  selected?: boolean;
  text: string;
}) => {
  const active = props.active === undefined ? true : props.active;
  return (
    <button
      className={`regular-text-button ${
        active ? '' : 'regular-text-button-disabled'
      }`}
      onClick={props.onClick}
      disabled={!active}
      style={{ fontWeight: props.selected ? 'bold' : 'normal' }}
    >
      {props.text}
    </button>
  );
};

export const RegularTextRedirect = (props: {
  to: string;
  text: string;
  onClick?: () => void;
  active?: boolean;
}) => {
  const active = props.active ?? true;
  return (
    <Link to={props.to} className="regular-text-redirect">
      <button
        disabled={!active}
        className="regular-text-button"
        onClick={props.onClick}
        style={{ fontWeight: active ? 'bold' : 'normal' }}
      >
        {props.text}
      </button>
    </Link>
  );
};

export const CellRedirect = (props: {
  to: string;
  text: string;
  onClick?: () => void;
}) => {
  return (
    <Link to={props.to}>
      <button className="regular-text-button" onClick={props.onClick}>
        {props.text}
      </button>
    </Link>
  );
};

interface TextCloseButtonProps {
  text: string;
  onClick?: () => void;
  active?: boolean;
}
export const TextCloseButton = ({
  text,
  onClick,
  active = true,
}: TextCloseButtonProps) => {
  return (
    <Box style={{ marginTop: '12px', display: 'flex' }}>
      <span className="square-button-text">{text}</span>
      <Button
        variant="outlined"
        color="secondary"
        style={{ marginLeft: '12px' }}
        onClick={onClick}
        disabled={!active}
      >
        x
      </Button>
    </Box>
  );
};

interface TextSolidButtonProps {
  text: string;
  onClick?: () => void;
  active?: boolean;
}
export function TextSolidButton({
  text,
  onClick,
  active = true,
}: TextSolidButtonProps) {
  return (
    <Button
      disabled={!active}
      onClick={onClick}
      variant="outlined"
      style={{ marginLeft: '5px', marginRight: '5px' }}
    >
      {text}
    </Button>
  );
}

export const TextSolidRedirect = (props: {
  text: string;
  onClick?: () => void;
}) => {
  return (
    <div className="text-button-wrapper">
      <button onClick={props.onClick}>{props.text}</button>
    </div>
  );
};

interface PreviewCloseButtonProps {
  onClick?: () => void;
  additionalStyle?: React.CSSProperties;
}
export const PreviewCloseButton = ({
  onClick,
  additionalStyle,
}: PreviewCloseButtonProps) => {
  return (
    <Button style={{ color: '#FFF', ...additionalStyle }} onClick={onClick}>
      Close <CloseIcon />
    </Button>
  );
};

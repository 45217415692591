import { FormTextInput } from '../../forms';
import { LoadingAnimation } from '../../LoadingAnimation';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CONTENT_STATE } from '../../../store/store';
import { videoContentTableColumnResolver } from '../../../lib/columnResolvers';
import { mutateVideoTrackSearch } from '../../../lib/gqlRequests';
import {
  VideoTrackObject,
  ContentState,
  GlobalState,
  VideoTrackResponseData,
} from '../../../lib/types';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import {
  DataGridPro as DataGrid,
  GridCellEditCommitParams,
  GridColDef,
  GridColumnResizeParams,
  GridSelectionModel,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { DynamicDownloadButton } from '../../dynamicDownloadButton';
import { ComponentType, useCallback, useMemo } from 'react';
import { PlayerOverlay, PreviewCell } from '../../PreviewCell';
import { ContentBody, ContentHeader } from '../../wrappers';
import { MEDIA_TYPES, TRACKS_LIMIT } from '../../../lib/constants';

const VIDEO_COLUMN_WIDTH_KEY = 'videoColumnWidth';

export const MusicVideoSearch = () => {
  const dispatch = useDispatch();
  const {
    selectedIds: musicVideoSelectedIds,
    searchInput: musicVideoSearchInput,
    loading: musicVideoLoading,
    searchResult: musicVideoSearchResult,
    error: musicVideoError,
    editEntries,
  } = useSelector<GlobalState, GlobalState['content'][MEDIA_TYPES.MUSIC_VIDEO]>(
    (state) => state['content'][MEDIA_TYPES.MUSIC_VIDEO]
  );

  const onRowSelectedModelCHange = useCallback(
    (m: GridSelectionModel) => {
      dispatch({
        type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_VIDEO],
        data: {
          selectedIds: new Set(m.map((e) => Number(e))),
        } as ContentState,
      });
    },
    [dispatch]
  );

  const columns: GridColDef[] = useMemo(() => {
    const cwStr = localStorage.getItem(VIDEO_COLUMN_WIDTH_KEY);
    const columnWidth = cwStr && JSON.parse(cwStr);
    return videoContentTableColumnResolver.map((colResolver) => ({
      field: colResolver.field,
      headerName: colResolver.title,
      editable: colResolver.editable || false,
      type: colResolver.type || 'string',
      width:
        columnWidth && `${colResolver.field}` in columnWidth
          ? columnWidth[colResolver.field]
          : colResolver.flex || 100,
      hideSortIcons: true,
      headerAlign: 'left',
      cellClassName: (params: any) => {
        if (
          editEntries[params.id] &&
          editEntries[params.id][params.field] != null
        ) {
          return 'edited-cell';
        }
        return '';
      },
      valueGetter: (params) => {
        if (colResolver.prop) {
          return params.row[colResolver.prop];
        } else if (colResolver.resolve) {
          return colResolver.resolve(params.row as VideoTrackObject);
        }
        throw new Error(
          `Column resolver ${colResolver.field} (${colResolver.title}) should have either .prop or .resolve`
        );
      },
    }));
  }, [editEntries]);

  const rows = useMemo(() => {
    return musicVideoSearchResult.map((row) => {
      const edit = editEntries[row.id];
      if (!edit) return row;

      const newRow = { ...row };
      if (edit.tempo != null) {
        newRow.tempo = edit.tempo;
      }
      if (edit.label != null) {
        newRow.label = edit.label;
      }
      if (edit.artistName != null) {
        newRow.artist = newRow.artist || ({} as unknown as any);
        newRow.artist!.name = edit.artistName;
      }
      if (edit.year != null) {
        newRow.releaseYear = edit.year;
      }
      if (edit.name != null) {
        newRow.name = edit.name;
      }

      return newRow;
    });
  }, [editEntries, musicVideoSearchResult]);

  const onEditCellChange = useCallback(
    (c: GridCellEditCommitParams) => {
      const newEditEntries = { ...editEntries };
      newEditEntries[c.id as number] = newEditEntries[c.id as number] || {};
      newEditEntries[c.id as number][c.field] = c.value;

      dispatch({
        type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_VIDEO],
        data: {
          editEntries: newEditEntries,
        } as ContentState,
      });
    },
    [dispatch, editEntries]
  );

  const onColumnWidthChanges = useCallback((column: GridColumnResizeParams) => {
    const cwStr = localStorage.getItem(VIDEO_COLUMN_WIDTH_KEY);
    const columnWidth: { [key: string]: number } = cwStr
      ? JSON.parse(cwStr)
      : {};
    columnWidth[column.colDef.field] = column.width;
    localStorage.setItem(VIDEO_COLUMN_WIDTH_KEY, JSON.stringify(columnWidth));
  }, []);

  /**Since DataGrid updates selection model on each render, this workaround is used to prevent cyclic re-rendering */
  const ids = Array.from(musicVideoSelectedIds.values());
  const selectedRows = useMemo(() => {
    return ids;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids.join(',')]);

  const { load } = requestDataLoader<
    VideoTrackResponseData,
    VideoTrackObject[],
    [number, number, string]
  >({
    doRequest: () =>
      mutateVideoTrackSearch({
        limit: TRACKS_LIMIT,
        offset: 0,
        searchText: musicVideoSearchInput,
      }),
    onStateChange: (output) => {
      dispatch({
        type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_VIDEO],
        data: {
          searchResult: output.data,
          error: output.error,
          loading: output.loading,
        } as ContentState,
      });
    },
    initialValue: [],
    identifier: 'musicVideoTracks',
    formatResponse: (result) => result.createCatalogSearch.videoTracks,
  });

  const dataGridProps: typeof DataGrid extends ComponentType<infer T>
    ? T
    : unknown = useMemo(() => {
    return {
      checkboxSelection: true,
      columnBuffer: 50,
      rowCount: TRACKS_LIMIT,
      density: 'compact',
      components: {
        Toolbar: GridToolbar,
      },
      rows,
      columnTypes: {
        contentUrlButton: {
          renderCell: (p) => {
            return (
              <DynamicDownloadButton
                text={'get file'}
                id={p.row.id}
                mediaType={MEDIA_TYPES.MUSIC_VIDEO}
              />
            );
          },
        },
        contentPlaybackButton: {
          renderCell: (p: any) => {
            return (
              <PreviewCell mediaType={MEDIA_TYPES.MUSIC_VIDEO} id={p.row.id} />
            );
          },
        },
      },
      selectionModel: selectedRows,
      onCellEditCommit: onEditCellChange,
      onSelectionModelChange: onRowSelectedModelCHange,
      onColumnWidthChange: onColumnWidthChanges,
      columns,
      loading: musicVideoLoading,
    };
  }, [
    rows,
    selectedRows,
    onEditCellChange,
    onRowSelectedModelCHange,
    columns,
    musicVideoLoading,
    onColumnWidthChanges,
  ]);

  return (
    <ContentBody>
      <ContentHeader>
        <div className="search-button">
          <FormTextInput
            id="searchVideoText"
            type="text"
            inputValue={musicVideoSearchInput}
            placeholder="... name, album, artist"
            onChange={(text) => {
              dispatch({
                type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_VIDEO],
                data: { searchInput: text } as ContentState,
              });
            }}
            onSubmit={(searchInput) => {
              if (searchInput) {
                dispatch({
                  type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_VIDEO],
                  data: {
                    selectedIds: new Set(),
                    editEntries: {},
                  } as ContentState,
                });
                return load(0, 100, searchInput);
              }

              dispatch({
                type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_VIDEO],
                data: {
                  selectedIds: new Set(),
                  searchInput: '',
                  error: null,
                  searchResult: [],
                  editEntries: {},
                  loading: false,
                } as Partial<ContentState>,
              });
            }}
          />
        </div>
        <div style={{ paddingLeft: '10px' }}>
          <LoadingAnimation
            sizePx={50}
            color={'black'}
            animate={musicVideoLoading}
          />
        </div>
      </ContentHeader>

      {musicVideoError && (
        <div className="search-status-msg">Error: {musicVideoError}</div>
      )}

      <div style={{ height: '500px', maxHeight: '100%', width: '100%' }}>
        <DataGrid {...dataGridProps}></DataGrid>
      </div>
      <PlayerOverlay />
    </ContentBody>
  );
};

import type { Property } from 'csstype';
import type { CSSProperties } from 'react';

document.styleSheets[0].insertRule(`
@keyframes audalize-sync {
  0% {
    transform: scaleY(0.5);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.5);
  }
}
`);

document.styleSheets[0].insertRule(`
@keyframes audalize-wave {
  0% {
    transform: scaleY(0.6);
  }
  24% {
    transform: scaleY(1);
  }
  76% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.6);
  }
}
`);

document.styleSheets[0].insertRule(`
@keyframes audalize-reflect {
  0% {
    transform: scaleY(0.2);
  }
  12% {
    transform: scaleY(1);
  }
  88% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.2);
  }
}
`);

const SIZE_COEFS = {
  MAX: 1,
  MID: 0.7534246575342467,
  SMALL: 0.49657534246575347,
  MIN: 0.24657534246575344,
  HORIZ_LEN: 0.1267123287671233,
  THICKNESS: 0.07,
};

export const LoadingAnimation = (props: {
  sizePx: number;
  color?: Property.BackgroundColor;
  animate: boolean;
  period?: number;
  kind?: 'sync' | 'wave' | 'reflect';
}) => {
  let animDurationSec = props.period || 1;
  let delayMultiplier = 1;

  let kind =
    props.kind || ['sync', 'wave', 'reflect'][Math.floor(Math.random() * 3)];

  if (kind === 'sync') {
    delayMultiplier = 0.5;
  }

  if (kind === 'reflect') {
    animDurationSec = animDurationSec * 1.5;
  }

  const { SMALL, HORIZ_LEN, THICKNESS, MAX, MID, MIN } = SIZE_COEFS;
  const { sizePx: size } = props;
  const borderRadius = Math.floor(size * SIZE_COEFS.THICKNESS) + 'px';
  const borderWidth = 0;
  const backgroundColor = props.color || 'black';
  const borderCollapse: 'collapse' = 'collapse';
  const dashStyle: CSSProperties = {
    borderRadius,
    borderWidth,
    borderCollapse,
    backgroundColor,
    margin: `0 ${Math.floor(size * THICKNESS) / 2}px`,
  };

  let verticalDashStyle: CSSProperties = {
    ...dashStyle,
    width: Math.floor(size * THICKNESS),
  };

  if (props.animate) {
    verticalDashStyle = {
      ...verticalDashStyle,
      animationDirection: 'normal',
      animationName: `audalize-${kind}`,
      animationDuration: `${animDurationSec}s`,
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
    };
  }

  const horizontalDashStyle = {
    ...dashStyle,
    height: Math.floor(size * THICKNESS) + 'px',
    width: Math.floor(size * HORIZ_LEN) + 'px',
  };

  return (
    <div
      style={{
        height: Math.floor(size),
        width: Math.floor(size * 1.5),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div
        className="animated-logo-horizontal-dash"
        style={horizontalDashStyle}
      />

      <div
        className="animated-logo-vertical-dash"
        style={{
          ...verticalDashStyle,
          height: size * MIN,
          animationDelay: `${0}s`,
        }}
      />
      <div
        className="animated-logo-vertical-dash"
        style={{
          ...verticalDashStyle,
          height: size * MID,
          animationDelay: `${(7 / 8) * animDurationSec * delayMultiplier}s`,
        }}
      />
      <div
        className="animated-logo-vertical-dash"
        style={{
          ...verticalDashStyle,
          height: size * SMALL,
          animationDelay: `${(1 / 8) * animDurationSec * delayMultiplier}s`,
        }}
      />
      <div
        className="animated-logo-vertical-dash"
        style={{
          ...verticalDashStyle,
          height: size * MAX,
          animationDelay: `${(6 / 8) * animDurationSec * delayMultiplier}s`,
        }}
      />
      <div
        className="animated-logo-vertical-dash"
        style={{
          ...verticalDashStyle,
          height: size * MID,
          animationDelay: `${(2 / 8) * animDurationSec * delayMultiplier}s`,
        }}
      />
      <div
        className="animated-logo-vertical-dash"
        style={{
          ...verticalDashStyle,
          height: size * MAX,
          borderRadius,
          animationDelay: `${(5 / 8) * animDurationSec * delayMultiplier}s`,
        }}
      />
      <div
        className="animated-logo-vertical-dash"
        style={{
          ...verticalDashStyle,
          height: size * MID,
          animationDelay: `${(3 / 8) * animDurationSec * delayMultiplier}s`,
        }}
      />
      <div
        className="animated-logo-vertical-dash"
        style={{
          ...verticalDashStyle,
          height: size * SMALL,
          animationDelay: `${(4 / 8) * animDurationSec * delayMultiplier}s`,
        }}
      />

      <div
        className="animated-logo-horizontal-dash"
        style={horizontalDashStyle}
      />
    </div>
  );
};

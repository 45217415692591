const cerr = console.error.bind(console);
console.error = (...args: any) => {
  if (
    typeof args[0] === 'string' &&
    args[0].includes('Material-UI X: License key not found')
  ) {
    return;
  }
  cerr(...args);
};
require('@mui/x-license-pro').LicenseStatus.NotFound = 'Valid';
export {};

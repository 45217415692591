import { useCallback, useMemo, useState } from 'react';

import { LoadingAnimation } from '../../LoadingAnimation';
import { TextSolidButton } from '../../buttons';
import { UploadFile } from '../../../lib/types';
import { uploadMusicFile } from '../../../lib/requests';
import { ContentHeader, PopupWindow } from '../../wrappers';

interface Props {
  files: UploadFile[];
  close: () => void;
  removeFromUploadFiles: (file: UploadFile) => void;
}

type UploadStates = 'DONE' | 'LOADING' | 'ERROR' | 'UNKNOWN';

const ColorState: Record<UploadStates, string> = {
  DONE: 'darkgreen',
  ERROR: 'tomato',
  LOADING: 'orange',
  UNKNOWN: 'black',
};

export default function UploadMusicModal({
  files,
  close,
  removeFromUploadFiles,
}: Props) {
  const [processing, setProcessing] = useState(false);
  const [finished, setFinished] = useState(false);

  const [fileStatuses, setFileStatuses] = useState(
    files.map((file) => ({
      name: file.name,
      loading: false,
      fileName: file.file.name,
      error: null as string | null,
      progress: 0,
      file: file.file,
    }))
  );

  const hasError = useMemo(
    () => fileStatuses.some((f) => !!f.error),
    [fileStatuses]
  );

  const uploadState = useCallback(
    (
      progress: number,
      loading: boolean,
      error: Error | null | unknown
    ): UploadStates => {
      if (error) return 'ERROR';
      if (progress === 1 && !loading) return 'DONE';
      if (loading) return 'LOADING';
      //! Ideally dont reach this state
      return 'UNKNOWN';
    },
    []
  );

  const renderUploadState = useCallback(
    (
      state: UploadStates,
      error: Error | null | unknown,
      progress: number | null
    ) => {
      switch (state) {
        case 'DONE':
          return 'Done';
        case 'LOADING':
          if (progress) {
            return `Loading (${Math.min(progress * 100, 99).toFixed(0)}%)`;
          } else {
            return 'Loading';
          }
        case 'ERROR':
          if (error instanceof Error) {
            return error.message;
          }
          if (typeof error === 'string') {
            return error;
          }
          return 'Fatal Error Ocurred';
        case 'UNKNOWN':
        default:
          return '';
      }
    },
    []
  );

  const upload = useCallback(async () => {
    setProcessing(true);
    setFinished(false);
    const _fileStatuses = [...fileStatuses]; // internal copy
    for (const inter of Array.from(files.entries())) {
      const [i, file] = inter; // get around js downleveling as of outdated ts
      await uploadMusicFile({
        name: file.name!,
        file: file.file,
        artistName: file.artistName,
        fileName: file.file.name,
        isrc: file.isrc,
        onStatusChange: (status) => {
          const { error, loading, progress } = status;
          _fileStatuses[i] = { ..._fileStatuses[i], error, loading, progress };
          setFileStatuses([..._fileStatuses]);
        },
      });
      // if there was no upload errors, remove from upload queue
      if (!_fileStatuses[i].error) {
        removeFromUploadFiles(file);
      }
    }
    setProcessing(false);
    setFinished(true);
  }, [fileStatuses, files, removeFromUploadFiles]);

  return (
    <PopupWindow>
      <div>Upload {files.length} tracks?</div>
      <div>
        <strong>Disclaimer</strong>: New tracks will be available in search
        after a minute from upload.
      </div>
      <table style={{ width: '100%' }}>
        <tbody>
          {fileStatuses.map(({ error, loading, name, progress, fileName }) => (
            <tr key={name}>
              <td style={{ whiteSpace: 'nowrap' }}>{name || fileName}</td>
              <td>
                <LoadingAnimation
                  sizePx={20}
                  animate={loading}
                  color={ColorState[uploadState(progress, loading, error)]}
                />
              </td>
              <td>
                {renderUploadState(
                  uploadState(progress, loading, error),
                  error,
                  progress
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ContentHeader>
        {!processing && (
          <>
            <TextSolidButton
              text={hasError ? 'Retry Upload' : 'Upload'}
              onClick={upload}
            />

            <TextSolidButton
              text={finished ? 'Finish' : 'Close'}
              onClick={close}
            />
          </>
        )}
      </ContentHeader>
    </PopupWindow>
  );
}

export type Step = {
  id?: number;
  name: string | (() => string);
  state: {
    loading: boolean;
    error: string | null;
    data: string | null;
  };
  action: () => Promise<string>;
  ready: boolean | (() => boolean);
};

export type StepsLifecycleState = {
  onDone: (done: boolean) => void;
  onCanCancel: (canCancel: boolean) => void;
  onError: (errorMessage: string) => void;
  onLoading: (loading: boolean) => void;
  isCancelled: () => boolean;
  onNewSteps: (steps: Step[]) => void;
};

export async function runStep(step: Step, onChange: (step: Step) => void) {
  step.state.loading = true;

  onChange(step);

  try {
    const data = await step.action();

    step.state.loading = false;
    step.state.data = data;

    onChange(step);
  } catch (error) {
    step.state.loading = false;
    step.state.error = error;

    onChange(step);
  }
}

export function processSteps(steps: Step[], handlers: StepsLifecycleState) {
  if (steps.length === 0) return;

  let step = steps.find((s) => !s.state.data);

  if (!step) {
    handlers.onDone(true);
    handlers.onLoading(false);

    return;
  }

  if (step.state.loading) return;
  if (step.state.error) return;

  if (handlers.isCancelled()) {
    handlers.onError(`Cancelled`);

    handlers.onLoading(false);
    return;
  }

  if (!step.ready) return; // wait for further update

  if (step === steps[steps.length - 1]) {
    handlers.onCanCancel(false);
  }

  handlers.onLoading(true);

  runStep(step, (step) => {
    const index = steps.findIndex((s) => s.id === step.id);
    if (index === -1) throw new Error('step not found');

    steps[index] = { ...step };

    handlers.onNewSteps([...steps]);

    if (step.state.error) {
      handlers.onError(`Cannot apply changes: ${step.state.error}`);
      handlers.onLoading(false);
      handlers.onCanCancel(false);
    }
  });
}

import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MEDIA_TYPES } from '../../../lib/constants';
import { mutateDeletePlaylists } from '../../../lib/gqlRequests';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import {
  GlobalState,
  PlaylistManagement,
  PlaylistState,
} from '../../../lib/types';
import {
  SET_PLAYLISTS_MANAGE_STATE,
  SET_PLAYLISTS_STATE,
} from '../../../store/store';
import { TextSolidButton } from '../../buttons';
import { LoadingAnimation } from '../../LoadingAnimation';
import { ContentHeader, PopupWindow } from '../../wrappers';

export const DeletePlaylists = (props: {
  close: () => void;
  mediaType: MEDIA_TYPES;
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false,
    error: null as Error | null,
    data: false,
  });

  const { selectedIds, playlists } = useSelector<GlobalState, PlaylistState>(
    (state) => state['playlists'][props.mediaType]
  );

  const { playlistId } = useSelector<GlobalState, PlaylistManagement>(
    (state) => state['playlistManagement'][props.mediaType]
  );

  const memoizedPlaylists: { id: number; name: string }[] = useMemo(
    () =>
      playlists
        .filter((r) => selectedIds.has(r.id))
        .map((r) => ({ id: r.id, name: r.name })),
    [playlists, selectedIds]
  );

  const memoizedPlaylistMap = new Set(memoizedPlaylists.map((p) => p.id));

  const { load } = requestDataLoader({
    identifier: `deletePlaylists`,
    initialValue: false,
    doRequest: () =>
      mutateDeletePlaylists({
        ids: memoizedPlaylists.map((p) => p.id),
      }),
    formatResponse: (res) => res.deletePlaylists,
    onStateChange: (output) => {
      setState(output);

      if (output.data) {
        dispatch({
          type: SET_PLAYLISTS_STATE[props.mediaType],
          data: {
            playlists: playlists.filter((p) => !memoizedPlaylistMap.has(p.id)),
            selectedIds: new Set(
              Array.from(selectedIds.values()).filter(
                (id) => !memoizedPlaylistMap.has(id)
              )
            ),
          } as PlaylistState,
        });

        if (memoizedPlaylistMap.has(playlistId || -1)) {
          dispatch({
            type: SET_PLAYLISTS_MANAGE_STATE[props.mediaType],
            data: {
              playlistId: null,
            } as PlaylistManagement,
          });
        }
      }
    },
  });

  return (
    <PopupWindow>
      {!state.data && !state.loading && (
        <div>
          <div>Delete {memoizedPlaylists.length} playlists?</div>
          <div>{memoizedPlaylists.map((p) => p.name).join(', ')}</div>
        </div>
      )}
      {state.data && <div>Playlists deleted</div>}
      {state.error && <div>Error: {state.error}</div>}

      <LoadingAnimation
        sizePx={100}
        animate={state.loading}
        color={
          state.data
            ? 'darkgreen'
            : state.loading
            ? 'orange'
            : state.error
            ? 'tomato'
            : 'black'
        }
      />

      <ContentHeader>
        {!state.loading && !state.data && !state.error && (
          <TextSolidButton
            text="Delete"
            onClick={() => {
              load();
            }}
          />
        )}

        <TextSolidButton
          text="Back"
          onClick={() => {
            props.close();
          }}
        />
      </ContentHeader>
    </PopupWindow>
  );
};

import { Box, ButtonGroup, Dialog, Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { queryZoneRunsheet } from '../../lib/gqlRequests';
import { requestDataLoader } from '../../lib/requestDataLoader';
import {
  EntryState,
  GlobalState,
  TimeslotEntry,
  ZoneRunsheetResponse,
} from '../../lib/types';
import { ACTION_TYPES } from '../../store/store';
import { RegularTextButton } from '../buttons';
import { LoadingAnimation } from '../LoadingAnimation';
import { ButtonGroupRow } from '../wrappers';
import { Schedule } from './Schedule';
import { SelectVenueAndZone } from './SelectVenueAndZone';
import { SchedulerSideBar } from './Sidebar';
import { startOfWeek, endOfWeek, format } from 'date-fns';
import { DATE_FORMAT } from '../../lib/constants';
import {
  buildLastTimeslotState,
  flatTimeslots,
  popLastTimeslotState,
  timeslotResponseToEntry,
  timeslotsDiffer,
} from '../../lib/scheduleUtils';
import { SaveScheduleCheckout } from './SaveCheckout';
import { CloneSchedule } from './CloneSchedule';
import { ExportHistory } from './ExportHistoryPopup';
import { BackupCheckout } from './BackupCheckout';
import { RestoreScheduleBackup } from './RestoreScheduleBackup';
import { CloneSiteCheckout } from './CloneSiteCheckout';

export const Scheduler = () => {
  const dispatch = useDispatch();

  const [selectVenuePopup, setSelectVenuePopup] = useState(false);
  const [cloneSchedulePopup, setCloneSchedulePopup] = useState(false);
  const [savePopup, setSavePopup] = useState(false);
  const [historyPopup, setHistoryPopup] = useState(false);
  const [backupSchedulePopup, setBackupSchedulePopup] = useState(false);
  const [restoreSchedulePopup, setRestoreSchedulePopup] = useState(false);
  const [cloneSitePopup, setCloneSitePopup] = useState(false);

  const venues = useSelector((state: GlobalState) => state.venues);
  const {
    scheduleTimeslots,
    scheduleError,
    scheduleLoading,
    selectedVenueId,
    selectedZoneId,
    lastTimeslotStates,
    scheduleId,
    now,
  } = useSelector((state: GlobalState) => state.scheduler);

  const { load: loadShedule } = useMemo(() => {
    const from = format(startOfWeek(now), DATE_FORMAT);
    const to = format(endOfWeek(now), DATE_FORMAT);

    return requestDataLoader<
      ZoneRunsheetResponse,
      { id: number; timeslots: TimeslotEntry[] } | null
    >({
      doRequest: () =>
        queryZoneRunsheet({
          from,
          to,
          zoneId: selectedZoneId!,
        }),
      onStateChange: (output) => {
        dispatch({
          type: ACTION_TYPES.SET_SCHEDULER,
          data: {
            scheduleTimeslots: output.data?.timeslots || null,
            initialScheduleTimeslots: output.data?.timeslots || null,
            lastTimeslotStates: buildLastTimeslotState(
              [],
              output.data?.timeslots || []
            ),
            scheduleError: output.error,
            scheduleLoading: output.loading,
            scheduleId: output.data?.id,
            scheduleFrom: from,
            scheduleTo: to,
          } as Partial<GlobalState['scheduler']>,
        });
      },
      initialValue: null,
      identifier: 'loadSchedule',
      formatResponse: (result) => {
        if (!result.zone.schedule) {
          return null;
        }

        const timeslots =
          result.zone.schedule?.timeslots.map(timeslotResponseToEntry) || [];

        return {
          id: result.zone.schedule.id,
          timeslots,
        };
      },
    });
  }, [dispatch, now, selectedZoneId]);

  const { selectedVenue, selectedZone } = useMemo(() => {
    if (selectedVenueId === null) {
      return {};
    }

    const selectedVenue =
      venues?.find((venue) => venue.id === selectedVenueId) || null;

    const selectedZone = selectedVenue?.zones.find(
      (z) => z.id === selectedZoneId
    );

    return { selectedVenue, selectedZone };
  }, [selectedVenueId, venues, selectedZoneId]);

  // const [lastZoneId, setLastZoneId] = useState(selectedZoneId);
  // const [lastNow, setLastNow] = useState(now);

  useEffect(() => {
    if (!selectedZoneId) return;

    // if (!scheduleLoading && !scheduleError && !scheduleTimeslots) {
    //   loadShedule();
    //   setLastZoneId(selectedZoneId);

    //   return;
    // }

    // if (lastZoneId !== selectedZoneId || lastNow !== now) {
    loadShedule();
    // setLastZoneId(selectedZoneId);
    // setLastNow(now);
    // }
  }, [
    selectedZoneId,
    // scheduleLoading,
    // scheduleError,
    // scheduleTimeslots,
    loadShedule,
    // lastZoneId,
    // now,
    // lastNow,
  ]);

  const timeslotsChanged = useMemo(() => {
    return timeslotsDiffer(scheduleTimeslots || []);
  }, [scheduleTimeslots]);

  const refreshSchedule = useMemo(() => {
    return () => loadShedule();
  }, [loadShedule]);

  return (
    <Box width="100%">
      {/* HEADER */}
      {!(selectedVenue && selectedZone) && 'Select venue and zone'}
      {selectedVenue && selectedZone && (
        <div>
          Site: {selectedVenue?.name} &emsp; Zone: {selectedZone?.name} (
          {selectedZone?.id})
        </div>
      )}

      <hr />
      <ButtonGroupRow>
        <ButtonGroup>
          <RegularTextButton
            text="Refresh"
            active={!!selectedVenue && !!selectedZone}
            onClick={refreshSchedule}
          />
          <RegularTextButton
            text="Select Venue And Zone"
            active={true}
            onClick={() => setSelectVenuePopup(true)}
          />
          <RegularTextButton
            text="Copy Schedule to Zone"
            active={!!scheduleTimeslots?.length}
            onClick={() => setCloneSchedulePopup(true)}
          />
          <RegularTextButton
            text="Clone Site"
            active={!!selectedZoneId}
            onClick={() => setCloneSitePopup(true)}
          />
          <RegularTextButton
            text="Backup Schedule"
            active={!!scheduleId}
            onClick={() => setBackupSchedulePopup(true)}
          />
          <RegularTextButton
            text="Restore Schedule"
            active={true}
            onClick={() => setRestoreSchedulePopup(true)}
          />
          <RegularTextButton
            text="Export play history (csv)"
            active={!!selectedZoneId}
            onClick={() => setHistoryPopup(true)}
          />
        </ButtonGroup>
        <ButtonGroup>
          <RegularTextButton
            text="Clear Schedule"
            active={!!scheduleTimeslots?.length}
            description="Removes all timeslots in the editor. To apply changes faster, save with 'From scratch' option"
            onClick={() => {
              const newTimeslots =
                scheduleTimeslots?.map((t) => ({
                  ...t,
                  state: EntryState.DELETED,
                })) || [];

              dispatch({
                type: ACTION_TYPES.SET_SCHEDULER,
                data: {
                  scheduleTimeslots: newTimeslots,
                  lastTimeslotStates: buildLastTimeslotState(
                    lastTimeslotStates,
                    newTimeslots
                  ),
                } as Partial<GlobalState['scheduler']>,
              });
            }}
          />
          <RegularTextButton
            text="Undo"
            active={lastTimeslotStates.length > 1}
            onClick={() => {
              const [lastState, newStates] =
                popLastTimeslotState(lastTimeslotStates);

              dispatch({
                type: ACTION_TYPES.SET_SCHEDULER,
                data: {
                  scheduleTimeslots: lastState,
                  lastTimeslotStates: newStates,
                } as Partial<GlobalState['scheduler']>,
              });
            }}
          />
          <RegularTextButton
            text="Flat"
            active={timeslotsChanged}
            onClick={() => {
              if (!scheduleTimeslots) return;

              const { changed, timeslots: newTimeslots } =
                flatTimeslots(scheduleTimeslots);

              if (!changed) return;

              dispatch({
                type: ACTION_TYPES.SET_SCHEDULER,
                data: {
                  scheduleTimeslots: newTimeslots,
                  lastTimeslotStates: buildLastTimeslotState(
                    lastTimeslotStates,
                    newTimeslots
                  ),
                } as Partial<GlobalState['scheduler']>,
              });
            }}
          />
          <RegularTextButton
            text="Save"
            active={timeslotsChanged}
            onClick={() => {
              if (!scheduleTimeslots) return;
              const { changed, timeslots: newTimeslots } =
                flatTimeslots(scheduleTimeslots);

              if (changed) {
                dispatch({
                  type: ACTION_TYPES.SET_SCHEDULER,
                  data: {
                    scheduleTimeslots: newTimeslots,
                    lastTimeslotStates: buildLastTimeslotState(
                      lastTimeslotStates,
                      newTimeslots
                    ),
                  } as Partial<GlobalState['scheduler']>,
                });
              }

              setSavePopup(true);
            }}
          />
        </ButtonGroup>
      </ButtonGroupRow>
      {/* BODY */}
      {scheduleError && (
        <div>Error when fetching schedule: {scheduleError}</div>
      )}
      {scheduleLoading && (
        <Box
          sx={{ display: 'flex' }}
          justifyContent="center"
          alignItems="center"
          width="100%"
          paddingTop={'50px'}
        >
          <LoadingAnimation sizePx={300} animate={true} color="orange" />
        </Box>
      )}
      {scheduleTimeslots && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={3} maxHeight="50vh">
              <SchedulerSideBar />
            </Grid>
            <Grid item xs={9}>
              <Schedule />
            </Grid>
          </Grid>
        </div>
      )}
      {/* POPUPS */}
      <Dialog
        open={selectVenuePopup}
        fullWidth={true}
        onBackdropClick={() => setSelectVenuePopup(false)}
        onClose={() => setSelectVenuePopup(false)}
      >
        <SelectVenueAndZone close={() => setSelectVenuePopup(false)} />
      </Dialog>
      <Dialog
        open={savePopup}
        maxWidth="xl"
        onBackdropClick={() => setSavePopup(false)}
        onClose={() => setSavePopup(false)}
      >
        <SaveScheduleCheckout
          close={() => setSavePopup(false)}
          onDone={refreshSchedule}
        />
      </Dialog>
      <Dialog
        open={cloneSchedulePopup}
        fullWidth={true}
        onBackdropClick={() => setCloneSchedulePopup(false)}
        onClose={() => setCloneSchedulePopup(false)}
      >
        <CloneSchedule close={() => setCloneSchedulePopup(false)} />
      </Dialog>
      <Dialog
        open={historyPopup}
        fullWidth={true}
        onBackdropClick={() => setHistoryPopup(false)}
        onClose={() => setHistoryPopup(false)}
      >
        <ExportHistory
          close={() => setHistoryPopup(false)}
          zoneId={selectedZoneId!}
        />
      </Dialog>
      <Dialog
        open={backupSchedulePopup}
        fullWidth={true}
        onBackdropClick={() => setBackupSchedulePopup(false)}
        onClose={() => setBackupSchedulePopup(false)}
      >
        <BackupCheckout
          close={() => setBackupSchedulePopup(false)}
          scheduleId={scheduleId!}
        />
      </Dialog>
      <Dialog
        open={restoreSchedulePopup && !!selectedVenueId && !!selectedZoneId}
        fullWidth={true}
        onBackdropClick={() => setRestoreSchedulePopup(false)}
        onClose={() => setRestoreSchedulePopup(false)}
      >
        <RestoreScheduleBackup
          close={() => setRestoreSchedulePopup(false)}
          venueId={selectedVenueId!}
          zoneId={selectedZoneId!}
          onDone={refreshSchedule}
        />
      </Dialog>
      <Dialog
        open={cloneSitePopup}
        fullWidth={true}
        onBackdropClick={() => setCloneSitePopup(false)}
        onClose={() => setCloneSitePopup(false)}
      >
        <CloneSiteCheckout
          close={() => setCloneSitePopup(false)}
          zoneId={selectedZoneId!}
          onDone={() => {}}
        />
      </Dialog>
    </Box>
  );
};

import { Box, ButtonGroup } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  mutateCloneSchedule,
  mutateUpdateSchedule,
  mutateUpdateZone,
} from '../../lib/gqlRequests';
import { ButtonGroupRow, PopupWindow } from '../wrappers';
import { LoadingAnimation } from '../LoadingAnimation';
import { RegularTextButton } from '../buttons';
import { processSteps, Step } from '../../lib/steps';

export const CloneProgress = (props: {
  scheduleId: number;
  zoneId: number;
  venueId: number;
  close: Function;
}) => {
  const { scheduleId, zoneId } = props;

  if (!scheduleId && !zoneId) {
    throw new Error(
      'No venueId or scheduleId or zoneId or diff or scheduleFrom or scheduleTo'
    );
  }

  const [steps, setSteps] = useState<Step[]>([]);

  const newScheduleId = useRef<number | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [canCancel, setCanCancel] = useState(true);

  const initialSteps: Step[] = useMemo(() => {
    const steps: Step[] = [];

    const cloneStep: Step = {
      name: `clone schedule (${scheduleId})`,
      state: {
        loading: false,
        error: null,
        data: null,
      },
      ready: () => true,
      action: async () => {
        const result = await mutateCloneSchedule({
          id: scheduleId,
          toVenueId: props.venueId,
        });

        newScheduleId.current = result.cloneSchedule.id;

        return result.cloneSchedule.id.toString();
      },
    };

    const scheduleUpdate: Step = {
      name: () =>
        `Update schedule [${newScheduleId.current || '?'}]: isDraft = false`,
      state: {
        loading: false,
        error: null,
        data: null,
      },
      ready: () => newScheduleId.current != null,
      action: async () => {
        if (!newScheduleId.current) {
          throw new Error('No new schedule id, this shoud not have happened');
        }

        const response = await mutateUpdateSchedule({
          id: newScheduleId.current,
          isDraft: false,
        });

        return response.updateSchedule.id.toString();
      },
    };

    const zoneUpdate: Step = {
      name: () => `Update zone: scheduleId = ${newScheduleId.current || '?'}`,
      state: {
        loading: false,
        error: null,
        data: null,
      },
      ready: () => newScheduleId.current != null,
      action: async () => {
        if (!newScheduleId.current) {
          throw new Error('No new schedule id, this shoud not have happened');
        }

        const response = await mutateUpdateZone({
          zoneId: zoneId,
          scheduleId: newScheduleId.current,
        });

        return response.updateZone.id.toString();
      },
    };

    steps.push(cloneStep, scheduleUpdate, zoneUpdate);

    return steps;
  }, [props.venueId, scheduleId, zoneId]);

  useEffect(() => {
    setSteps(
      initialSteps.map((s, id) => ({
        ...s,
        id,
      }))
    );
  }, [initialSteps]);

  useEffect(() => {
    processSteps(steps, {
      isCancelled: () => cancel,
      onCanCancel: (v) => setCanCancel(v),
      onDone: (v) => {
        setDone(v);
      },
      onError: (err) => setError(err),
      onLoading: (v) => setLoading(v),
      onNewSteps: (v) => setSteps(v),
    });
  }, [cancel, steps]);

  return (
    <PopupWindow>
      <Box>
        {steps.map((step) => (
          <Box
            key={step.id}
            color={
              step.state.loading
                ? 'darkblue'
                : step.state.error
                ? 'darkred'
                : step.state.data
                ? 'darkgreen'
                : 'black'
            }
          >
            {typeof step.name === 'function' ? step.name() : step.name} -{' '}
            {step.state.loading
              ? 'loading'
              : step.state.error
              ? `error: ${step.state.error}`
              : step.state.data
              ? `done: ${step.state.data}`
              : '...'}
          </Box>
        ))}
      </Box>
      <Box>
        {error && <Box color="red">{error}</Box>}
        {loading && (
          <Box display="flex" justifyContent="center">
            <LoadingAnimation animate={true} sizePx={20} color="darkblue" />
          </Box>
        )}
        <ButtonGroupRow>
          <ButtonGroup>
            <RegularTextButton
              text="Cancel"
              active={!done && canCancel}
              onClick={() => {
                setCanCancel(false);
                setCancel(true);
              }}
            />
            <RegularTextButton
              text="Done"
              active={done}
              onClick={() => {
                props.close();
              }}
            />
          </ButtonGroup>
        </ButtonGroupRow>
      </Box>
    </PopupWindow>
  );
};

/**
 * 1  Clone schedule
 * 2. Clone playlists
 * 3. Clone buckets
 * 4. Assing
 * 2. Set isDraft: false
 * 3. Update target zone
 */

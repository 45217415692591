import { SCHEDULE_HISTORY_LIMIT } from './constants';
import {
  Bucket,
  EntryState,
  PlayableItemType,
  PlaylistObject,
  Timeslot,
  TimeslotEntry,
  TimeslotResponse,
} from './types';

// This function takes a list of overlapping timeslots for each day and truncates intersected parts.
export const flatTimeslots = (
  timeslots: TimeslotEntry[]
): { changed: boolean; timeslots: TimeslotEntry[] } => {
  let orderedTimeslots = orderTimeslots(timeslots);
  let changed = false;

  for (let i = 0; i < orderedTimeslots.length - 1; i++) {
    const current = orderedTimeslots[i];

    if (current.state === EntryState.DELETED) {
      continue;
    }

    for (let j = i + 1; j < orderedTimeslots.length; j++) {
      const next = orderedTimeslots[j];

      if (next.state === EntryState.DELETED) {
        continue;
      }

      // Break loop if when next day starts: ... | ***
      if (current.day !== next.day) {
        break;
      }

      // If next timeslot starts after current timeslot ends, skip further checks of current: ... ***
      if (current.to <= next.from) {
        break;
      }

      // If current timeslot is fully inside next timeslot, delete current:  :::::, :::**
      if (current.from === next.from && current.to <= next.to) {
        orderedTimeslots[i] = { ...current, state: EntryState.DELETED };

        changed = true;
        break;
      }

      // If next timeslot overlaps current: ...::***, ...::::
      if (next.from < current.to && next.to >= current.to) {
        orderedTimeslots[i] = {
          ...current,
          state:
            current.state === EntryState.NEW
              ? EntryState.NEW
              : EntryState.CHANGED,
          to: next.from,
          originalTs: current.originalTs || current,
        };

        changed = true;
        break;
      }

      // If next timeslot splits current: ...:::...
      if (current.from < next.from && current.to > next.to) {
        const newEntry = {
          ...current,
          from: next.to,
          to: current.to,
          id: undefined,
          state: EntryState.NEW,
        };

        orderedTimeslots[i] = {
          ...current,
          to: next.from,
          state:
            current.state === EntryState.NEW
              ? EntryState.NEW
              : EntryState.CHANGED,
          originalTs: current.originalTs || current,
        };

        orderedTimeslots = orderTimeslots([...orderedTimeslots, newEntry]);

        changed = true;
        break;
      }

      // If next timeslot begins at the same time as current and is shorter: ::::....
      if (current.from === next.from && current.to > next.to) {
        orderedTimeslots[i] = {
          ...current,
          from: next.to,
          state:
            current.state === EntryState.NEW
              ? EntryState.NEW
              : EntryState.CHANGED,
          originalTs: current.originalTs || current,
        };

        changed = true;
        break;
      }

      // other situations are not possible
      console.error(current, next);
      throw new Error('This situation is not handled by logic: current, next');
    }
  }

  return { timeslots: orderedTimeslots, changed };
};

const orderTimeslots = (timeslots: TimeslotEntry[]): TimeslotEntry[] => {
  const orderedTimeslots = [...timeslots].sort((a, b) => {
    if (a.day > b.day) return 1;
    if (a.day < b.day) return -1;
    if (a.from > b.from) return 1;
    if (a.from < b.from) return -1;

    return a.to < b.to ? 1 : -1;
  });

  return orderedTimeslots;
};

export type TimeslotsDiff = {
  deleted: TimeslotEntry[];
  updated: TimeslotEntry[];
  created: TimeslotEntry[];
};

export const timeslotsDiff = (
  oldTimeslots: TimeslotEntry[], // may be empty array if we want to create new schedule from scratch
  newTimeslots: TimeslotEntry[]
): TimeslotsDiff => {
  // marked as new, or non-deleted and non-present in old timeslots
  const created = newTimeslots.filter(
    (new_) =>
      new_.state === EntryState.NEW ||
      (new_.state !== EntryState.DELETED &&
        !oldTimeslots.find((old) => new_.id === old.id))
  );

  // marked as deleted and exist in comparable schedule to delete from
  const deleted = newTimeslots.filter(
    (new_) =>
      new_.state === EntryState.DELETED &&
      oldTimeslots.find((old) => old.id === new_.id)
  );

  const updated = newTimeslots.filter(
    (new_) =>
      new_.state === EntryState.CHANGED &&
      oldTimeslots.find((old) => old.id === new_.id)
  );

  return { deleted, updated, created };
};

export const timeslotsDiffer = (newTimeslots: TimeslotEntry[]): boolean => {
  return newTimeslots.some((new_) => new_.state !== EntryState.UNCHANGED);
};

export const timeslotType = (ts: Timeslot): PlayableItemType => {
  if (ts.bucket) {
    return 'bucket';
  }

  if (ts.playlist) {
    return 'playlist';
  }

  throw new Error('Unknown timeslot type');
};

export const timeslotPlayableItem = (ts: Timeslot): Bucket | PlaylistObject => {
  if (ts.bucket) {
    return ts.bucket;
  }

  if (ts.playlist) {
    return ts.playlist;
  }

  throw new Error('Unknown timeslot type');
};

export function buildLastTimeslotState(
  prev: TimeslotEntry[][],
  current: TimeslotEntry[]
) {
  if (prev.length === SCHEDULE_HISTORY_LIMIT) {
    prev.shift();
  }

  return [...prev, current];
}

export function popLastTimeslotState(states: TimeslotEntry[][]) {
  if (states.length < 2) {
    throw new Error('No previous state');
  }

  const prevState = states[states.length - 2];

  return [prevState, states.slice(0, states.length - 1)];
}

export function timeslotResponseToEntry(ts: TimeslotResponse): TimeslotEntry {
  const isRepeat = !!ts.repeatOf;
  const id = isRepeat ? ts.repeatOf!.id : ts.id!;

  return {
    ...ts,
    state: EntryState.UNCHANGED,
    id: id,
    isRepeat: isRepeat,
  };
}

export function getDiffForNewSchedule(
  inputDiff: TimeslotsDiff,
  newTimeslots: TimeslotEntry[]
): TimeslotsDiff {
  const newDiff: TimeslotsDiff = {} as any;
  newDiff.created = [...inputDiff.created];

  newDiff.updated = inputDiff.updated.map((ts) => {
    const nts = newTimeslots.find(
      (nts) =>
        nts?.bucket?.id === ts.originalTs?.bucket?.id &&
        nts?.playlist?.id === ts.originalTs?.playlist?.id &&
        nts?.day === ts.originalTs?.day &&
        nts?.from === ts.originalTs?.from &&
        nts?.to === ts.originalTs?.to
    );

    if (!nts) {
      throw TimeslotNotFoundError(ts);
    }

    return { ...ts, id: nts.id };
  });

  newDiff.deleted = inputDiff.deleted.map((ts) => {
    const nts = newTimeslots.find(
      (nts) =>
        nts?.bucket?.id === ts.originalTs?.bucket?.id &&
        nts?.playlist?.id === ts.originalTs?.playlist?.id &&
        nts?.day === ts.originalTs?.day &&
        nts?.from === ts.originalTs?.from &&
        nts?.to === ts.originalTs?.to
    );

    if (!nts) {
      throw TimeslotNotFoundError(ts);
    }

    return { ...ts, id: nts.id };
  });

  return newDiff;
}

function TimeslotNotFoundError(ts: Timeslot) {
  return new Error(
    `Cannot find timeslot in new timeslot list: ${JSON.stringify({
      day: ts.day,
      from: ts.from,
      to: ts.to,
      bucket: ts.bucket?.id,
      playlist: ts.playlist?.id,
    })}. This may occur when the schedule has been changed meanwhile.`
  );
}

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MEDIA_TYPES } from '../../../lib/constants';
import { mutateDeduplicateTracksInPlaylist } from '../../../lib/gqlRequests';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import { GlobalState, PlaylistManagement } from '../../../lib/types';
import { SET_PLAYLISTS_MANAGE_STATE } from '../../../store/store';
import { TextSolidButton } from '../../buttons';
import { LoadingAnimation } from '../../LoadingAnimation';
import { ContentHeader, PopupWindow } from '../../wrappers';

export const DeduplicatePlaylist = (props: {
  close: () => void;
  mediaType: MEDIA_TYPES;
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false,
    error: null as Error | null,
    data: false,
  });

  const { playlistId, duplicateCount } = useSelector(
    (state: GlobalState) => state['playlistManagement'][props.mediaType]
  );

  const { load } = requestDataLoader({
    identifier: `deletePlaylists`,
    initialValue: false,
    doRequest: () =>
      mutateDeduplicateTracksInPlaylist({
        playlistId: playlistId!,
      }),
    formatResponse: (res) => res.deduplicateTracksInPlaylist,
    onStateChange: (output) => {
      setState(output);

      if (output.data) {
        dispatch({
          type: SET_PLAYLISTS_MANAGE_STATE[props.mediaType],
          data: {
            duplicateCount: 0,
          } as PlaylistManagement,
        });
      }
    },
  });

  return (
    <PopupWindow>
      {!state.data && !state.loading && (
        <div>
          <div>Remove {duplicateCount} tracks from the playlist?</div>
        </div>
      )}
      {state.data && <div>Playlist deduplicated</div>}
      {state.error && <div>Error: {state.error}</div>}

      <LoadingAnimation
        sizePx={100}
        animate={state.loading}
        color={
          state.data
            ? 'darkgreen'
            : state.loading
            ? 'orange'
            : state.error
            ? 'tomato'
            : 'black'
        }
      />

      <ContentHeader>
        {!state.loading && !state.data && !state.error && (
          <TextSolidButton
            text="Deduplciate"
            onClick={() => {
              load();
            }}
          />
        )}

        <TextSolidButton
          text="Back"
          onClick={() => {
            props.close();
          }}
        />
      </ContentHeader>
    </PopupWindow>
  );
};

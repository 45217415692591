import {
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { queryVenues } from '../../lib/gqlRequests';
import { requestDataLoader } from '../../lib/requestDataLoader';
import { GlobalState, Venue, VenuesResponse } from '../../lib/types';
import { venueService } from '../../services/venueService';
import { ACTION_TYPES } from '../../store/store';
import { RegularTextButton } from '../buttons';
import { FormTextInput } from '../forms';
import { LoadingAnimation } from '../LoadingAnimation';
import { ButtonGroupRow, PopupWindow } from '../wrappers';

export const SelectVenueAndZone = (props: { close: Function }) => {
  const dispatch = useDispatch();
  const [filterText, setFilterText] = useState('');

  const { selectedVenueId, selectedZoneId } = useSelector(
    (state: GlobalState) => state.scheduler
  );

  const venues = useSelector((state: GlobalState) => state.venues);

  const [venuesStatus, setVenuesStatus] = useState({
    venuesError: null as string | null,
    venuesLoading: false,
  });

  const { venuesError, venuesLoading } = venuesStatus;

  const { load } = useMemo(() => {
    return requestDataLoader<VenuesResponse, Venue[] | null>({
      doRequest: () => queryVenues(),
      onStateChange: (output) => {
        if (output.data) {
          venueService.setVenues(output.data);

          dispatch({
            type: ACTION_TYPES.SET_VENUES,
            data: output.data as GlobalState['venues'],
          });
        }

        setVenuesStatus({
          venuesError: output.error ? String(output.error) : null,
          venuesLoading: output.loading,
        });
      },
      initialValue: null,
      identifier: 'getGlobalVenues',
      formatResponse: (result) => result.venues,
    });
  }, [dispatch]);

  const filteredVenues = useMemo(() => {
    const filterLowerCase = filterText.toLowerCase();

    return (
      venues?.filter(
        (v) =>
          v.name.toLowerCase().includes(filterLowerCase) ||
          v.zones.some((z) => z.name.toLowerCase().includes(filterLowerCase))
      ) || null
    );
  }, [venues, filterText]);

  const { selectedVenue } = useMemo(() => {
    if (selectedVenueId === null) {
      return {};
    }

    const selectedVenue =
      filteredVenues?.find((venue) => venue.id === selectedVenueId) || null;

    const selectedZone = selectedVenue?.zones.find(
      (z) => z.id === selectedZoneId
    );

    return { selectedVenue, selectedZone };
  }, [selectedVenueId, filteredVenues, selectedZoneId]);

  useEffect(() => {
    if (!venues) {
      load();
    }
  }, [venues, load]);

  const { selectedVenueId: defaultVenueId, selectedZoneId: defaultZoneId } =
    useMemo(() => {
      return { selectedVenueId, selectedZoneId };
    }, [selectedVenueId, selectedZoneId]);

  const selector = (
    <div style={{ width: '100%' }}>
      <div>
        <FormTextInput
          id="filterVenues"
          type="text"
          placeholder="filter by venue or zone"
          onChange={(text) => {
            setFilterText(text);
          }}
        />
      </div>
      <hr />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl className="modal-vertical-scroll">
            <FormLabel id="venues-label">Venue</FormLabel>
            <RadioGroup
              aria-labelledby="venues-label"
              defaultValue={defaultVenueId}
              name="radio-buttons-group"
              onChange={(e) => {
                const selectedVenueId = Number(e.target.value);

                dispatch({
                  type: ACTION_TYPES.SET_SCHEDULER,
                  data: {
                    selectedVenueId,
                    selectedZoneId: null,
                  } as GlobalState['scheduler'],
                });

                venueService.setVenueId(selectedVenueId);
                venueService.setZoneId(null);
              }}
            >
              {filteredVenues?.map((v) => (
                <FormControlLabel
                  value={v.id}
                  control={<Radio />}
                  label={v.name}
                  key={v.id}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className="modal-vertical-scroll">
            <FormLabel id="venues-label">Zone</FormLabel>
            <RadioGroup
              aria-labelledby="zones-label"
              defaultValue={defaultZoneId}
              name="radio-buttons-group"
              onChange={(e) => {
                const selectedZoneId = Number(e.target.value);

                dispatch({
                  type: ACTION_TYPES.SET_SCHEDULER,
                  data: {
                    selectedZoneId,
                  } as GlobalState['scheduler'],
                });

                venueService.setZoneId(selectedZoneId);
              }}
            >
              {selectedVenue?.zones.map((z) => (
                <FormControlLabel
                  value={z.id}
                  control={<Radio />}
                  label={z.name}
                  key={z.id}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <PopupWindow>
      <ButtonGroupRow>
        <ButtonGroup>
          <RegularTextButton
            text="Refresh"
            active={true}
            onClick={() => load()}
          />
        </ButtonGroup>
        <ButtonGroup>
          <RegularTextButton
            text="Close"
            active={true}
            onClick={() => props.close()}
          />
        </ButtonGroup>
      </ButtonGroupRow>
      {venuesLoading && <LoadingAnimation animate={true} sizePx={20} />}
      {venuesError && (
        <div>
          <b>Error when fetching venues:</b> {venuesError}
        </div>
      )}

      {venues && selector}
    </PopupWindow>
  );
};

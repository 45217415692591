import { useState } from 'react';
import { MEDIA_TYPES } from '../lib/constants';
import { queryTrackCOntentUrl } from '../lib/gqlRequests';
import { requestDataLoader } from '../lib/requestDataLoader';
import { RegularTextButton } from './buttons';
import { LoadingAnimation } from './LoadingAnimation';

type ContentResponse = {
  url: string;
  name: string;
  blob?: Blob;
};

export const DynamicDownloadButton = (props: {
  id: number;
  text: string;
  mediaType: MEDIA_TYPES;
}) => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(null as Error | null);
  const [data, setData] = useState(null as ContentResponse | null);

  const { load } = requestDataLoader({
    initialValue: undefined as undefined | ContentResponse,
    doRequest: async () => {
      const data = await queryTrackCOntentUrl({
        id: props.id,
        mediaType: props.mediaType,
      });

      const contentAddr =
        props.mediaType === MEDIA_TYPES.MUSIC_AUDIO
          ? data.track.processedContent.contentUrl
          : data.track.contentUrl;

      try {
        const res = await fetch(contentAddr, {
          mode: 'cors',
        });

        const url = new URL(contentAddr);
        const extension = url.pathname.match(/\.[A-z0-9]{2,4}$/gi)?.[0];
        return {
          url: contentAddr,
          name: data.track.name + extension,
          blob: await res.blob(),
        };
      } catch (err) {
        return {
          url: contentAddr,
          name: data.track.name,
          blob: undefined,
        };
      }
    },
    identifier: `trackContent/${props.id}`,
    formatResponse: (res) => res,
    onStateChange: (state) => {
      setLoading(state.loading);
      setError(state.error);
      state.data && setData(state.data);

      if (state.data?.blob) {
        // Create an object URL
        const url = URL.createObjectURL(state.data.blob);

        // Download file
        download(url, state.data.name);

        // Release the object URL
        URL.revokeObjectURL(url);
      }
    },
  });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        justifySelf: 'center',
        width: '100%',
      }}
    >
      {loading ? (
        <LoadingAnimation
          sizePx={35}
          period={2}
          color={'blue'}
          animate={true}
        />
      ) : data?.url ? (
        <a href={data.url} download={true}>
          {'save'}
        </a>
      ) : (
        <RegularTextButton active={!loading} onClick={load} text={props.text} />
      )}
    </div>
  );
};

const download = (path: string, filename: string) => {
  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
};

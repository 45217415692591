export const ContentBody = (props: { children: any }) => {
  return <div className="content-container">{props.children}</div>;
};

export const ContentHeader = (props: { children: any }) => {
  return <div className="full-width-row">{props.children}</div>;
};

export const PopupWindow = (props: { children?: any }) => {
  return <div className="popup-window">{props.children}</div>;
};

export const ButtonGroupRow = (props: { children?: any }) => {
  return <div className="button-group-row">{props.children}</div>;
};

export const ButtonGroup = (props: { children?: any }) => {
  return <div className="button-group">{props.children}</div>;
};

export const UpToHalfSpace = (props: { children?: any }) => {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      {props.children}
    </div>
  );
};

export const RemainingSpaceSpace = (props: { children?: any }) => {
  return (
    <div
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '100%',
      }}
    >
      {props.children}
    </div>
  );
};

export const VertScroll = (props: { children?: any; height: string }) => {
  return (
    <div
      style={{
        overflowY: 'auto',
        width: '100%',
        height: props.height,
      }}
    >
      {props.children}
    </div>
  );
};

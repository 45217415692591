import axios from 'axios';
import { store } from '../store/store';

export const uploadFile = async ({
  path,
  file,
  contentType,
  headers,
  onUploadProgress,
}: {
  path: string;
  file: Blob;
  contentType: string;
  headers?: { [key: string]: string };
  onUploadProgress?: Function;
}) => {
  const address = `${process.env.REACT_APP_API_ADDRESS}${path}`;

  const url = new URL(address).toString();
  try {
    const res = await axios.post(encodeURI(url), file, {
      headers: {
        authorization: `Bearer ${store.getState().authToken}`,
        'content-type': contentType,
        ...headers,
      },
      onUploadProgress:
        onUploadProgress &&
        ((event: any) => {
          onUploadProgress(event);
        }),
      responseType: 'json',
    });
    if (!res.data.success) {
      throw Error(res.data.message);
    }
    return res.data;
  } catch (err) {
    if (err && typeof err === 'object' && 'response' in err) {
      const resError = err as Record<string, any>;
      throw new Error(resError.response.data.message);
    }

    throw err;
  }
};

type requestStatus = {
  loading: boolean;
  progress: number;
  error: string | null;
};

export const uploadMusicFile = async ({
  name,
  file,
  artistName,
  fileName,
  isrc,
  onStatusChange,
}: {
  name: string;
  file: Blob;
  fileName: string;
  artistName?: string;
  isrc?: string;
  onStatusChange?: (status: requestStatus) => void;
}) => {
  const status: requestStatus = {
    error: null,
    progress: 0,
    loading: false,
  };

  try {
    let headers: Record<string, string> = {
      'file-name': encodeURIComponent(fileName),
      'track-name': encodeURIComponent(name),
    };

    if (artistName) {
      headers['artist-name'] = encodeURIComponent(artistName);
    }

    if (isrc) {
      headers['isrc'] = encodeURIComponent(isrc);
    }

    await uploadFile({
      path: `/upload/uploadAudioContent/${name}`,
      file,
      contentType: 'audio/mpeg',
      onUploadProgress: (data: any) => {
        const progress = data.loaded / data.total;
        status.progress = progress;
        status.loading = true;
        onStatusChange?.({ ...status });
      },
      headers,
    });
  } catch (err: unknown) {
    status.loading = false;

    let errorMsg = 'Unknown Error';
    if (err instanceof Error) {
      errorMsg = err.message;
    }
    if (typeof err === 'string') {
      errorMsg = err;
    }

    status.error = errorMsg;
    onStatusChange?.({ ...status });
  }

  status.loading = false;
  onStatusChange?.({ ...status });

  return status;
};

export const uploadVideoFile = async ({
  name,
  file,
  artistName,
  fileName,
  onStatusChange,
}: {
  name: string;
  file: Blob;
  fileName: string;
  artistName?: string;
  onStatusChange?: (status: requestStatus) => void;
}) => {
  const status: requestStatus = {
    error: null,
    progress: 0,
    loading: false,
  };

  try {
    const headers: { [key: string]: string } = artistName
      ? { 'artist-name': encodeURIComponent(artistName) }
      : {};
    headers['file-name'] = encodeURIComponent(fileName);
    headers['track-name'] = encodeURIComponent(name);

    await uploadFile({
      path: `/upload/uploadMusicVideoContent/${name}`,
      file,
      contentType: 'audio/mpeg',
      onUploadProgress: (data: any) => {
        const progress = data.loaded / data.total;
        status.progress = progress;
        status.loading = true;
        onStatusChange && onStatusChange({ ...status });
      },
      headers,
    });
  } catch (err) {
    status.loading = false;

    let errorMsg = 'Unknown Error';
    if (err instanceof Error) {
      errorMsg = err.message;
    }
    if (typeof err === 'string') {
      errorMsg = err;
    }

    status.error = errorMsg;
    onStatusChange && onStatusChange({ ...status });
  }

  status.loading = false;
  onStatusChange && onStatusChange({ ...status });

  return status;
};

export const uploadChannelFile = async ({
  file,
  name,
  playlistId,
  onStatusChange,
}: {
  file: Blob;
  name: string;
  playlistId: number;
  onStatusChange?: (status: requestStatus) => void;
}) => {
  const status: requestStatus = {
    error: null,
    progress: 0,
    loading: false,
  };

  try {
    const playlistIdParam = encodeURIComponent(playlistId);
    const trackNameParam = encodeURIComponent(name);

    await uploadFile({
      path: `/upload/uploadChannelContent/${playlistIdParam}/${trackNameParam}`,
      file,
      contentType: 'audio/mpeg',
      onUploadProgress: (data: any) => {
        const progress = data.loaded / data.total;
        status.progress = progress;
        status.loading = true;
        onStatusChange && onStatusChange({ ...status });
      },
    });
  } catch (err) {
    status.loading = false;

    let errorMsg = 'Unknown Error';
    if (err instanceof Error) {
      errorMsg = err.message;
    }
    if (typeof err === 'string') {
      errorMsg = err;
    }

    status.error = errorMsg;
    onStatusChange && onStatusChange({ ...status });
  }

  status.loading = false;
  onStatusChange && onStatusChange({ ...status });

  return status;
};

export const getPlayHistory = async ({
  zoneId,
  from,
  to,
}: {
  zoneId: number;
  from: string;
  to: string;
}): Promise<string | null> => {
  if (!zoneId || !from || !to) {
    throw new Error('Invalid arguments');
  }

  const address = `${process.env.REACT_APP_API_ADDRESS}/rest/play-history/${zoneId}`;

  const url = new URL(address);
  url.searchParams.append('from', from);
  url.searchParams.append('to', to);

  try {
    const res = await axios.get(encodeURI(url.toString()), {
      headers: {
        authorization: `Bearer ${store.getState().authToken}`,
      },
    });

    if (res.status === 204) {
      return null;
    }
    if (res.status === 200) {
      return res.data as string;
    }
    throw new Error('unexpected response');
  } catch (err) {
    if (err && typeof err === 'object' && err.response) {
      const resError = err as Record<string, any>;
      throw new Error(resError.response.data.message);
    }

    throw err;
  }
};

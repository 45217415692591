import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MEDIA_TYPES } from '../../../lib/constants';
import { mutateDeleteS3Track } from '../../../lib/gqlRequests';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import { ContentState, GlobalState } from '../../../lib/types';
import {
  SET_CONTENT_STATE,
  SET_PLAYLISTS_MANAGE_STATE,
} from '../../../store/store';
import { TextSolidButton } from '../../buttons';
import { LoadingAnimation } from '../../LoadingAnimation';
import { ContentHeader, PopupWindow } from '../../wrappers';

export const DeleteTracksCheckout = (props: {
  close: () => void;
  mediaType: MEDIA_TYPES;
}) => {
  const { close } = props;
  const dispatch = useDispatch();

  const [startedLoad, setStartedLoad] = useState(false);
  const { searchResult, selectedIds } = useSelector<GlobalState, ContentState>(
    (state) => state['content'][props.mediaType]
  );
  const { tracks: manageTracks, selectedIds: manageSelectedIds } = useSelector(
    (state: GlobalState) => state['playlistManagement'][props.mediaType]
  );

  const tracks: { id: number; name: string }[] = searchResult
    .filter((r) => selectedIds.has(r.id))
    .map((r) => ({ id: r.id, name: r.name }));

  const [deleteTrackStatuses, setDeleteTrackStatuses] = useState(
    tracks.map((track) => ({
      id: track.id,
      name: track.name,
      loading: false,
      error: null as Error | null,
      data: false,
    }))
  );

  useEffect(() => {
    const deletedIds = new Set<number>(
      deleteTrackStatuses.filter((e) => !!e.data).map((s) => s.id)
    );

    const newSearchResult = searchResult.filter((r) => !deletedIds.has(r.id));
    const newSelectedIds = new Set(
      Array.from(selectedIds).filter((id) => !deletedIds.has(id))
    );

    if (
      newSearchResult.length !== searchResult.length ||
      newSelectedIds.size !== selectedIds.size
    ) {
      dispatch({
        type: SET_CONTENT_STATE[props.mediaType],
        data: {
          searchResult: newSearchResult,
          selectedIds: newSelectedIds,
        },
      });
    }

    const newManageTracks = manageTracks.filter((t) => !deletedIds.has(t.id));
    const newManageSelectedIds = new Set(
      Array.from(manageSelectedIds).filter((id) => !deletedIds.has(id))
    );

    if (
      newManageSelectedIds.size !== manageSelectedIds.size ||
      newManageTracks.length !== manageTracks.length
    ) {
      dispatch({
        type: SET_PLAYLISTS_MANAGE_STATE[props.mediaType],
        data: {
          tracks: newManageTracks,
          selectedIds: newManageSelectedIds,
        },
      });
    }
  }, [
    deleteTrackStatuses,
    dispatch,
    manageSelectedIds,
    manageTracks,
    props.mediaType,
    searchResult,
    selectedIds,
  ]);

  const loaders = deleteTrackStatuses.map((status, i) =>
    requestDataLoader({
      identifier: `deleteTracks/${props.mediaType}/${status.id}`,
      initialValue: false,
      doRequest: () => mutateDeleteS3Track({ id: status.id }),
      formatResponse: (res) => true,
      onStateChange: (output) => {
        deleteTrackStatuses[i] = {
          ...deleteTrackStatuses[i],
          data: output.data,
          error: output.error,
          loading: output.loading,
        };

        setDeleteTrackStatuses([...deleteTrackStatuses]);
      },
    })
  );

  return (
    <PopupWindow>
      <div>Delete {tracks.length} tracks?</div>
      <table style={{ width: '100%' }}>
        <tbody>
          {deleteTrackStatuses.map(({ id, name, data, error, loading }) => (
            <tr key={id}>
              <td style={{ whiteSpace: 'nowrap' }}>{name}</td>
              <td>
                <LoadingAnimation
                  sizePx={20}
                  animate={loading}
                  color={
                    data
                      ? 'darkgreen'
                      : loading
                      ? 'orange'
                      : error
                      ? 'tomato'
                      : 'black'
                  }
                />
              </td>
              <td>{data ? 'Done' : loading ? 'Loading' : error || 'TODO'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        <ContentHeader>
          {startedLoad || (
            <TextSolidButton
              text="Confirm"
              onClick={() => {
                setStartedLoad(true);
                loaders.forEach((l) => l.load());
              }}
            />
          )}
          <TextSolidButton
            text="Back"
            onClick={() => {
              close();
            }}
          />
        </ContentHeader>
      </div>
    </PopupWindow>
  );
};

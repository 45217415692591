import {
  GlobalState,
  PlaylistState,
  UploadFile,
  VenueChannelPlaylistsResponse,
} from '../../../lib/types';
import { RWMutex, runWithMutexW } from 'rw-mutex-ts';
import { TextCloseButton, TextSolidButton } from '../../buttons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import { SET_CONTENT_STATE, SET_PLAYLISTS_STATE } from '../../../store/store';
import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';
import { FormTextInput } from '../../forms';
import { LoadingAnimation } from '../../LoadingAnimation';
import { humanSizeBytes } from '../../../lib/utils';
import { uploadChannelFile } from '../../../lib/requests';
import { ContentBody, ContentHeader, PopupWindow } from '../../wrappers';
import { MEDIA_TYPES } from '../../../lib/constants';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import { queryDiscoveryChannelPlaylists } from '../../../lib/gqlRequests';

export const ChannelUpload = () => {
  const uploadFiles = useSelector(
    (state: GlobalState) => state['content'][MEDIA_TYPES.CHANNEL].uploadFiles
  );
  const dispatch = useDispatch();
  const setUploadFiles = (files: typeof uploadFiles) => {
    dispatch({
      type: SET_CONTENT_STATE[MEDIA_TYPES.CHANNEL],
      data: {
        uploadFiles: files,
      },
    });
  };

  const { loading, playlists, error } = useSelector<
    GlobalState,
    GlobalState['playlists'][MEDIA_TYPES.CHANNEL]
  >((state: GlobalState) => state['playlists'][MEDIA_TYPES.CHANNEL]);

  const { load } = requestDataLoader<
    VenueChannelPlaylistsResponse,
    VenueChannelPlaylistsResponse['venue']['channelPlaylists']
  >({
    initialValue: [],
    identifier: 'channelPlaylistRequest',
    doRequest: () => queryDiscoveryChannelPlaylists(),
    onStateChange: (output) => {
      dispatch({
        type: SET_PLAYLISTS_STATE[MEDIA_TYPES.CHANNEL],
        data: {
          loading: output.loading,
          playlists: output.data,
          error: output.error,
        } as PlaylistState,
      });
    },
    formatResponse: (result) => result.venue.channelPlaylists,
  });

  useEffect(() => {
    if (loading || error) {
      return;
    }

    load();
  }, [error, load, loading]);

  const key = uploadFiles.map((f) => f.file.name).join(',') || '_';

  const [checkout, setCheckout] = useState(false);

  const [selectedPlaylist, setSelectedPlaylist] = useState<
    | {
        name: string;
        id: number;
      }
    | undefined
  >();

  return (
    <ContentBody>
      <DropzoneArea
        key={key}
        initialFiles={uploadFiles.map((f) => f.file)}
        onChange={(files) => {
          setUploadFiles([
            ...uploadFiles,
            ...files
              .filter((f) => !uploadFiles.some((uplF) => uplF.file === f))
              .map((file) => ({
                file,
                name: file.name,
              })),
          ]);
        }}
        filesLimit={1000}
        maxFileSize={200 * 1024 * 1024}
        acceptedFiles={['video/*']}
        showFileNames={false}
        showPreviews={false}
        showPreviewsInDropzone={false}
        showFileNamesInPreview={false}
      />
      <div>
        {loading ? (
          `Loading channels...`
        ) : error ? (
          `Error: ${error}. Try reloading the page`
        ) : (
          <FormControl fullWidth>
            <InputLabel id="channel-pick">Channel</InputLabel>
            <Select
              labelId="channel-pick"
              id="upload-channel-select"
              label="Select channel"
              onChange={(e) => {
                setSelectedPlaylist(
                  playlists.find((p) => p.id === Number(e.target.value))
                );
              }}
            >
              {playlists.map((p) => (
                <MenuItem key={p.id} value={p.id}>
                  {p.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      {uploadFiles.length > 0 && (
        <div className="buttons-inline">
          <TextSolidButton
            text="Clear"
            active={uploadFiles.length > 0}
            onClick={() => setUploadFiles([])}
          />
        </div>
      )}
      <div>
        {uploadFiles.map((item, i) => (
          <ContentHeader key={i}>
            <FormTextInput
              onChange={(val) => {
                item.name = val;
                setUploadFiles([...uploadFiles]);
              }}
              placeholder="[Override track name]"
              type="text"
              pattern=".*\w{1,}.*"
              inputValue={item.name}
            />

            <div style={{ whiteSpace: 'nowrap' }}>
              {item.name && `Name: ${item.name}`}
            </div>

            <TextCloseButton
              text={`${item.file.name} (${humanSizeBytes(item.file.size)})`}
              active={true}
              onClick={() =>
                setUploadFiles(uploadFiles.filter((f) => f !== item))
              }
            />
          </ContentHeader>
        ))}
      </div>
      {uploadFiles.length > 0 && (
        <div className="buttons-inline">
          <TextSolidButton
            text="Upload"
            active={
              !!selectedPlaylist &&
              !uploadFiles.some(
                (file) => file.name && !/.*\w{1,}.*/.test(file.name)
              )
            }
            onClick={() => setCheckout(true)}
          />
        </div>
      )}
      <Dialog
        open={checkout && !!selectedPlaylist}
        onBackdropClick={() => setCheckout(false)}
        onClose={() => setCheckout(false)}
        maxWidth={'lg'}
      >
        <UploadChannelsCheckout
          close={(files) => {
            setCheckout(false);
            setUploadFiles(uploadFiles.filter((f) => !files.includes(f.file)));
          }}
          entries={uploadFiles.map(
            (file) =>
              ({
                file: file.file,
                name: file.name,
              } as UploadFile)
          )}
          playlistId={selectedPlaylist?.id || 0}
        />
      </Dialog>
    </ContentBody>
  );
};

export const UploadChannelsCheckout = (props: {
  entries: UploadFile[];
  playlistId: number;
  close: (files: File[]) => void;
}) => {
  const { entries, close } = props;
  const [startedLoad, setStartedLoad] = useState(false);
  const [fileStatuses, setFileStatuses] = useState(
    entries.map((file) => ({
      name: file.name,
      loading: false,
      error: null as string | null,
      progress: 0,
      file: file.file,
    }))
  );
  const mxRef = useRef(new RWMutex());

  return (
    <PopupWindow>
      <div>Upload {entries.length} tracks?</div>
      <table style={{ width: '100%' }}>
        <tbody>
          {fileStatuses.map(({ error, loading, name, progress }, i) => (
            <tr key={i}>
              <td style={{ whiteSpace: 'nowrap' }}>{name}</td>
              <td>
                <LoadingAnimation
                  sizePx={20}
                  animate={loading}
                  color={
                    error
                      ? 'tomato'
                      : progress === 1 && !loading
                      ? 'darkgreen'
                      : loading
                      ? `orange`
                      : 'black'
                  }
                />
              </td>
              <td>
                {!!error
                  ? error
                  : progress === 1 && !loading
                  ? 'Done'
                  : loading
                  ? `Loading (${Math.min(progress * 100, 99).toFixed(0)}%)`
                  : 'TODO'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {startedLoad && (
        <div>New tracks will be available in search after a minute</div>
      )}
      <div>
        <ContentHeader>
          {startedLoad || (
            <TextSolidButton
              text="Confirm"
              onClick={() => {
                setStartedLoad(true);
                entries.forEach((e, i) =>
                  runWithMutexW(mxRef.current, () =>
                    uploadChannelFile({
                      name: e.name!,
                      file: e.file,
                      playlistId: props.playlistId,
                      onStatusChange: (status) => {
                        fileStatuses[i].error = status.error;
                        fileStatuses[i].loading = status.loading;
                        fileStatuses[i].progress = status.progress;
                        setFileStatuses([...fileStatuses]);
                      },
                    })
                  )
                );
              }}
            />
          )}

          {fileStatuses.some(
            (f) => !f.error && !f.loading && f.progress === 1
          ) ? (
            <TextSolidButton
              text="Close (remove uploaded)"
              onClick={() => {
                close(
                  fileStatuses
                    .filter((f) => !f.error && !f.loading && f.progress === 1)
                    .map((f) => f.file)
                );
              }}
            />
          ) : (
            <TextSolidButton
              text="Close"
              onClick={() => {
                close([]);
              }}
            />
          )}
        </ContentHeader>
      </div>
    </PopupWindow>
  );
};

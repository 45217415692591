import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GlobalState,
  SpotifyPlaylistImportTrackMatchInput,
} from '../../../lib/types';

import { ACTION_TYPES } from '../../../store/store';
import {
  SpotifyPlaylist,
  SpotifyPlaylistsResponse,
  SpotifyPlaylistTracksResponse,
  spotifyService,
} from '../../../services/spotifyService';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import { TextSolidButton } from '../../buttons';
import {
  mutateAddTrackToPlaylist,
  mutateMatchSpotifyPlaylistTracks,
} from '../../../lib/gqlRequests';
import { SpotifyPlaylistComponent } from './SpotifyPlaylistComponent';
import { FormTextInput } from '../../forms';
import { MATCH_TRACKS_LIMIT } from '../../../lib/constants';
import { PopupWindow } from '../../wrappers';

export const AppendFromSpotify = (props: {
  playlists: { id: number; name: string }[];
}) => {
  const dispatch = useDispatch();

  const [filterText, setFilterText] = useState('');

  const [authError, setAuthError] = useState<null | string>(null);
  const { spotifyTokenData } = useSelector<GlobalState, GlobalState>(
    (state) => state
  );
  const [selectedSpotifyPlaylists, setSelectedPlaylist] = useState<
    SpotifyPlaylistsResponse['items']
  >([]);

  const [fetchUserPlaylistStatus, setFetchUserPlaylistStatus] = useState({
    data: null as null | SpotifyPlaylistsResponse,
    error: null as any,
    loading: false,
  });

  const {
    data: spotifyPlaylistReponse,
    error: spotifyImportError,
    loading: spotifyImportLoading,
  } = fetchUserPlaylistStatus;

  const { load: loadPlaylists } = useMemo(
    () =>
      requestDataLoader({
        identifier: `spotifyPlaylists`,
        initialValue: null as null | SpotifyPlaylistsResponse,
        doRequest: () => {
          return spotifyService.getUserPlaylists();
        },
        formatResponse: (res) => res,
        onStateChange: (output) => {
          setFetchUserPlaylistStatus(output);
        },
      }),
    []
  );

  const [appendPlaylistStatuses, setAppendPlaylistStatus] = useState({
    data: null as null | number,
    error: null as any,
    loading: false,
  });

  const { load: runAppend } = useMemo(
    () =>
      requestDataLoader({
        identifier: `spotifyPlaylists`,
        initialValue: null as null | number,
        doRequest: (allwoExplicit: boolean) => {
          return appendPlaylistsFromSpotifyPlaylist(
            selectedSpotifyPlaylists,
            allwoExplicit,
            props.playlists.map((p) => p.id)
          );
        },
        formatResponse: (data) => data,
        onStateChange: (output) => {
          setAppendPlaylistStatus(output);
        },
      }),
    [props.playlists, selectedSpotifyPlaylists]
  );

  useEffect(() => {
    if (spotifyTokenData) {
      return;
    }

    spotifyService
      .authorizeSpotify()
      .then((res) => {
        dispatch({
          type: ACTION_TYPES.SET_SPOTIFY_TOKEN_DATA,
          spotifyTokenData: res,
        });
      })
      .catch((err) => {
        setAuthError(err);
      });
  }, [spotifyTokenData, dispatch]);

  useEffect(() => {
    if (!spotifyTokenData) {
      return;
    }

    loadPlaylists();
  }, [spotifyTokenData, loadPlaylists]);

  if (authError) {
    return <div>Authentication error: {authError}</div>;
  }

  if (!spotifyTokenData) {
    return <div>Authentication Spotify user...</div>;
  }

  if (!spotifyImportLoading && !spotifyPlaylistReponse && !spotifyImportError) {
    return <PopupWindow></PopupWindow>;
  }

  if (spotifyImportError) {
    return <PopupWindow>Error: {spotifyImportError}</PopupWindow>;
  }

  if (spotifyImportLoading) {
    return <PopupWindow>Loading...</PopupWindow>;
  }

  const filteredPlaylists =
    spotifyPlaylistReponse?.items.filter((item) => {
      return (
        item.tracks.total > 0 &&
        item.name.toLowerCase().includes(filterText.toLocaleLowerCase())
      );
    }) || [];

  return (
    <PopupWindow>
      <FormTextInput
        id="searchSpotifyPlaylists"
        type="text"
        inputValue={filterText}
        placeholder="filter by name"
        onChange={(text) => {
          setFilterText(text);
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: 'space-around',
        }}
      >
        {filteredPlaylists.map((playlist) => (
          <SpotifyPlaylistComponent
            key={playlist.id}
            imageUrl={playlist.images[0]?.url}
            name={playlist.name}
            trackCount={playlist.tracks.total}
            selected={selectedSpotifyPlaylists.includes(playlist)}
            onClick={() => {
              if (appendPlaylistStatuses.loading) {
                return;
              }

              if (selectedSpotifyPlaylists.includes(playlist)) {
                return setSelectedPlaylist(
                  selectedSpotifyPlaylists.filter((p) => p !== playlist)
                );
              }

              setSelectedPlaylist([...selectedSpotifyPlaylists, playlist]);
            }}
            loading={
              appendPlaylistStatuses.loading &&
              selectedSpotifyPlaylists.includes(playlist)
            }
          />
        ))}
      </div>

      <div
        style={{
          position: 'sticky',
          bottom: '0px',
          backgroundColor: 'white',
          padding: '10px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className="button-group" style={{ paddingBottom: '10px' }}>
          <TextSolidButton
            text="Import (all)"
            active={!!selectedSpotifyPlaylists}
            onClick={() => {
              runAppend(true);
            }}
          />

          <TextSolidButton
            text="Import (non-explicit)"
            active={!!selectedSpotifyPlaylists}
            onClick={() => {
              runAppend(false);
            }}
          />
        </div>
        <div>
          Audalize playlists: {props.playlists.map((p) => p.name).join(', ')}
        </div>
        {appendPlaylistStatuses.loading && <div>Appending playlists...</div>}
        {appendPlaylistStatuses.error && (
          <div>
            Error while appending playlist: {appendPlaylistStatuses.error}
          </div>
        )}
        {appendPlaylistStatuses.data && (
          <div>
            Successfully appended {props.playlists.length} playlists with{' '}
            {appendPlaylistStatuses.data} tracks
          </div>
        )}
      </div>
    </PopupWindow>
  );
};

async function appendPlaylistsFromSpotifyPlaylist(
  spotifyPlaylists: SpotifyPlaylist[],
  allowExplicit: boolean,
  audalizePlaylistIds: number[]
): Promise<number> {
  const spotifyTrackItems: SpotifyPlaylistTracksResponse['items'] = [];

  for (const spl of spotifyPlaylists) {
    const spotifyTracks = await spotifyService.getTracksInPlaylist(
      spl.tracks.href
    );

    spotifyTrackItems.push(...spotifyTracks.items);
  }

  if (spotifyTrackItems.length === 0) {
    throw new Error(`No tracks in the playlist`);
  }

  let tracksInpput: SpotifyPlaylistImportTrackMatchInput[];

  const uniqueTrackIds = new Set<string>();
  spotifyTrackItems.forEach((t) =>
    uniqueTrackIds.add(t.track.external_ids.isrc)
  );

  const uniqueSpotifyTracks = spotifyTrackItems.filter((t) =>
    uniqueTrackIds.delete(t.track.external_ids.isrc)
  );

  if (allowExplicit) {
    tracksInpput = uniqueSpotifyTracks.map((item) => ({
      isrc: item.track.external_ids.isrc,
      spotifyAlbumName: item.track.album.name,
      spotifyArtistNames: item.track.artists.map((artist) => artist.name),
      spotifyTrackId: item.track.id,
      spotifyTrackName: item.track.name,
    }));
  } else {
    tracksInpput = uniqueSpotifyTracks
      .filter((track) => !track.track.explicit)
      .map((item) => ({
        isrc: item.track.external_ids.isrc,
        spotifyAlbumName: item.track.album.name,
        spotifyArtistNames: item.track.artists.map((artist) => artist.name),
        spotifyTrackId: item.track.id,
        spotifyTrackName: item.track.name,
      }));
  }

  if (tracksInpput.length === 0) {
    throw new Error(`All tracks are explicit`);
  }

  const batchSize = MATCH_TRACKS_LIMIT;
  let affectedTracks = 0;

  for (let i = 0; i < tracksInpput.length; i += batchSize) {
    const batch = tracksInpput.slice(i, i + batchSize);

    const response = await mutateMatchSpotifyPlaylistTracks({
      tracks: batch,
    });

    const trackIds = response.matchSpotifyPlaylistTracks
      .filter((item) => item.firstMatchingTrack)
      .map((item) => item.firstMatchingTrack!.id);

    if (trackIds.length === 0) {
      throw new Error(`No Spotify matched Audalize tracks`);
    }

    for (const playlistId of audalizePlaylistIds) {
      await mutateAddTrackToPlaylist({
        playlistId,
        trackIds,
      });
    }

    affectedTracks += trackIds.length;
  }

  return affectedTracks;
}

import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router';
import { RegularTextButton, RegularTextRedirect } from '../../buttons';
import { ButtonGroup, Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CONTENT_STATE } from '../../../store/store';
import { AddToPlaylist } from './MusicAudioAddToPlaylist';
import { MusicContentSearch } from './MusicAudioContentSearch';
import {
  AudioTrackObject,
  ContentState,
  GlobalState,
  LastAudioTracksResponseData,
} from '../../../lib/types';
import { MusicAudioUpload } from './MusicAudioUpload';
import { useState } from 'react';
import { ActivateTracksCheckout } from '../shared/ActivateTracksCheckout';
import { BlockTracksCheckout } from '../shared/BlockTracksCheckout';
import { MusicAudioEdit } from './MusicAudioEdit';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import { queryLastAudioTracks } from '../../../lib/gqlRequests';
import { MEDIA_TYPES } from '../../../lib/constants';
import { DeactivateTracksCheckout } from '../shared/DeactivateTracksCheckout';
import { DeleteTracksCheckout } from '../shared/DeleteTracksCheckout';
import { ButtonGroupRow } from '../../wrappers';

export const MusicAudio = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const { selectedIds, editEntries } = useSelector(
    (state: GlobalState) => state['content'][MEDIA_TYPES.MUSIC_AUDIO]
  );

  const [deletePopup, setDeletePopup] = useState(false);
  const [addToPlaylistPopup, setAddToPlaylistPopup] = useState(false);
  const [activatePopup, setActivatePopup] = useState(false);
  const [deactivatePopup, setDeactivatePopup] = useState(false);
  const [blockPopup, setBlockPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);

  const { load } = requestDataLoader<
    LastAudioTracksResponseData,
    AudioTrackObject[],
    [number]
  >({
    doRequest: (limit: number) =>
      queryLastAudioTracks({
        limit,
      }),
    onStateChange: (output) => {
      dispatch({
        type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_AUDIO],
        data: {
          searchResult: output.data,
          error: output.error,
          loading: output.loading,
        } as ContentState,
      });
    },
    initialValue: [],
    identifier: 'musicAudioTracks',
    formatResponse: (result) => result.lastUploadedTracks,
  });

  return (
    <div>
      {/**HEADER */}
      <ButtonGroupRow>
        <ButtonGroup>
          <RegularTextRedirect
            text="Upload Music Audio"
            to={`${path}/upload`}
            active={true}
          />
          {location.pathname === `${path}/search` ? (
            <RegularTextRedirect
              text="New Search"
              to={`${path}/search`}
              active={true}
              onClick={() => {
                dispatch({
                  type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_AUDIO],
                  data: {
                    selectedIds: new Set(),
                    searchInput: '',
                    error: null,
                    searchResult: [],
                    loading: false,
                  } as Partial<ContentState>,
                });
              }}
            />
          ) : (
            <RegularTextRedirect
              active={true}
              text="Search"
              to={`${path}/search`}
            />
          )}
          <RegularTextButton
            text="Last Uploaded"
            active={true}
            onClick={() => {
              dispatch({
                type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_AUDIO],
                data: {
                  selectedIds: new Set(),
                  searchInput: '',
                  error: null,
                  searchResult: [],
                  loading: false,
                } as Partial<ContentState>,
              });

              return load(50);
            }}
          />
        </ButtonGroup>

        <ButtonGroup>
          <RegularTextButton
            text="Add to Playlist"
            active={!!selectedIds.size}
            onClick={() => setAddToPlaylistPopup(true)}
          />
          <RegularTextButton
            text="Delete"
            active={!!selectedIds.size}
            onClick={() => setDeletePopup(true)}
          />
          <RegularTextButton
            text="Activate"
            active={!!selectedIds.size}
            onClick={() => setActivatePopup(true)}
          />
          <RegularTextButton
            text="Deactivate"
            active={!!selectedIds.size}
            onClick={() => setDeactivatePopup(true)}
          />
          <RegularTextButton
            text="Edit"
            active={Object.keys(editEntries).length > 0}
            onClick={() => {
              setEditPopup(true);
            }}
          />
          <RegularTextButton
            text="Block"
            active={!!selectedIds.size}
            onClick={() => setBlockPopup(true)}
          />
          <RegularTextButton text="Show Similar" active={!!selectedIds.size} />
        </ButtonGroup>
      </ButtonGroupRow>

      {/**BODY */}
      <div>
        <Switch>
          <Route path={`${path}/search`}>
            <MusicContentSearch />
          </Route>
          <Route exact path={`${path}/upload`}>
            <MusicAudioUpload />
          </Route>
          <Route path={`${path}`}>
            <Redirect to={`${path}/search`}></Redirect>
          </Route>
        </Switch>
      </div>

      {/**POPUPS */}

      <Dialog
        open={deletePopup}
        onBackdropClick={() => setDeletePopup(false)}
        onClose={() => setDeletePopup(false)}
        maxWidth="xl"
      >
        <DeleteTracksCheckout
          close={() => setDeletePopup(false)}
          mediaType={MEDIA_TYPES.MUSIC_AUDIO}
        />
      </Dialog>

      <Dialog
        open={addToPlaylistPopup}
        maxWidth="xl"
        onBackdropClick={() => setAddToPlaylistPopup(false)}
        onClose={() => setAddToPlaylistPopup(false)}
      >
        <AddToPlaylist close={() => setAddToPlaylistPopup(false)} />
      </Dialog>

      <Dialog
        open={activatePopup}
        maxWidth="xl"
        onBackdropClick={() => setActivatePopup(false)}
        onClose={() => setActivatePopup(false)}
      >
        <ActivateTracksCheckout
          close={() => setActivatePopup(false)}
          mediaType={MEDIA_TYPES.MUSIC_AUDIO}
        />
      </Dialog>

      <Dialog
        open={deactivatePopup}
        maxWidth="xl"
        onBackdropClick={() => setDeactivatePopup(false)}
        onClose={() => setDeactivatePopup(false)}
      >
        <DeactivateTracksCheckout
          close={() => setDeactivatePopup(false)}
          mediaType={MEDIA_TYPES.MUSIC_AUDIO}
        />
      </Dialog>

      <Dialog
        open={blockPopup}
        maxWidth="xl"
        onBackdropClick={() => setBlockPopup(false)}
        onClose={() => setBlockPopup(false)}
      >
        <BlockTracksCheckout
          close={() => setBlockPopup(false)}
          mediaType={MEDIA_TYPES.MUSIC_AUDIO}
        />
      </Dialog>

      <Dialog
        open={editPopup}
        maxWidth="xl"
        onBackdropClick={() => setEditPopup(false)}
        onClose={() => setEditPopup(false)}
      >
        <MusicAudioEdit close={() => setEditPopup(false)} />
      </Dialog>
    </div>
  );
};

import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { MEDIA_TYPES } from '../../lib/constants';
import { MenuItem } from '../MenuItem';
import { Channels } from './channels/Channels';
import { MusicAudio } from './musicAudio/MusicAudio';
import { MusicVideo } from './musicVideo/MusicVideo';

export const Content = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <div>
      <MenuItem
        text="MUSIC AUDIO"
        to={`${path}/${MEDIA_TYPES.MUSIC_AUDIO}`}
        selected={location.pathname.startsWith(
          `${path}/${MEDIA_TYPES.MUSIC_AUDIO}`
        )}
      ></MenuItem>
      <MenuItem
        text="MUSIC VIDEO"
        to={`${path}/${MEDIA_TYPES.MUSIC_VIDEO}`}
        selected={location.pathname.startsWith(
          `${path}/${MEDIA_TYPES.MUSIC_VIDEO}`
        )}
      ></MenuItem>
      <MenuItem
        text="AMBIENT VIDEO"
        to={`${path}/${MEDIA_TYPES.CHANNEL}`}
        selected={location.pathname.startsWith(
          `${path}/${MEDIA_TYPES.CHANNEL}`
        )}
      ></MenuItem>
      <hr />

      <Switch>
        <Route exact path={`${path}`}>
          {/**Set default path */}
          <Redirect to={`${path}/${MEDIA_TYPES.MUSIC_AUDIO}`}></Redirect>
        </Route>

        <Route path={`${path}/${MEDIA_TYPES.MUSIC_AUDIO}`}>
          {/**Set default path */}
          <MusicAudio />
        </Route>

        <Route path={`${path}/${MEDIA_TYPES.MUSIC_VIDEO}`}>
          {/**Set default path */}
          <MusicVideo />
        </Route>

        <Route path={`${path}/${MEDIA_TYPES.CHANNEL}`}>
          {/**Set default path */}
          <Channels />
        </Route>

        <Route path={`${path}/${MEDIA_TYPES.CHANNEL}`}>
          {/**Set default path */}
        </Route>

        <Route exact path={`${path}`}>
          {/**Set default path for section*/}
          <Redirect to={`${path}`}></Redirect>
        </Route>
      </Switch>
    </div>
  );
};

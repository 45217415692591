import { GlobalState, UploadFile } from '../../../lib/types';
import { RWMutex, runWithMutexW } from 'rw-mutex-ts';
import { TextCloseButton, TextSolidButton } from '../../buttons';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';

import { SET_CONTENT_STATE } from '../../../store/store';
import { Dialog } from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';
import { FormTextInput } from '../../forms';
import { LoadingAnimation } from '../../LoadingAnimation';
import { humanSizeBytes } from '../../../lib/utils';
import { uploadVideoFile } from '../../../lib/requests';
import { ContentBody, ContentHeader, PopupWindow } from '../../wrappers';
import { MEDIA_TYPES } from '../../../lib/constants';

export const MusicVideoUpload = () => {
  const uploadFiles = useSelector(
    (state: GlobalState) =>
      state['content'][MEDIA_TYPES.MUSIC_VIDEO].uploadFiles
  );
  const dispatch = useDispatch();
  const setUploadFiles = (files: typeof uploadFiles) => {
    dispatch({
      type: SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_VIDEO],
      data: {
        uploadFiles: files,
      },
    });
  };

  const key = uploadFiles.map((f) => f.file.name).join(',') || '_';

  const [checkout, setCheckout] = useState(false);

  return (
    <ContentBody>
      <DropzoneArea
        key={key}
        initialFiles={uploadFiles.map((f) => f.file)}
        onChange={(files) => {
          setUploadFiles([
            ...uploadFiles,
            ...files
              .filter((f) => !uploadFiles.some((uplF) => uplF.file === f))
              .map((file) => ({
                file,
                name: '',
                artistName: '',
              })),
          ]);
        }}
        filesLimit={1000}
        maxFileSize={200 * 1024 * 1024}
        acceptedFiles={['video/*']}
        showFileNames={false}
        showPreviews={false}
        showPreviewsInDropzone={false}
        showFileNamesInPreview={false}
      />

      {uploadFiles.length > 0 && (
        <div className="buttons-inline">
          <TextSolidButton
            text="Clear"
            active={uploadFiles.length > 0}
            onClick={() => setUploadFiles([])}
          />
        </div>
      )}

      <div>
        {uploadFiles.map((item, i) => (
          <ContentHeader key={i}>
            <FormTextInput
              onChange={(val) => {
                item.name = val;
                setUploadFiles([...uploadFiles]);
              }}
              placeholder="[Override track name]"
              type="text"
              pattern=".*\w{1,}.*"
              inputValue={item.name}
            />

            <FormTextInput
              onChange={(val) => {
                item.artistName = val;
                setUploadFiles([...uploadFiles]);
              }}
              placeholder="[Artist name]"
              type="text"
              inputValue={item.artistName}
            />

            <div style={{ whiteSpace: 'nowrap' }}>
              {item.name && `Name: ${item.name}`}
              {item.artistName && `Artist: ${item.artistName}`}
            </div>

            <TextCloseButton
              text={`${item.file.name} (${humanSizeBytes(item.file.size)})`}
              active={true}
              onClick={() =>
                setUploadFiles(uploadFiles.filter((f) => f !== item))
              }
            />
          </ContentHeader>
        ))}
      </div>

      {uploadFiles.length > 0 && (
        <div className="buttons-inline">
          <TextSolidButton
            text="Upload"
            active={
              !uploadFiles.some(
                (file) => file.name && !/.*\w{1,}.*/.test(file.name)
              )
            }
            onClick={() => setCheckout(true)}
          />
        </div>
      )}

      <Dialog
        open={checkout}
        onBackdropClick={() => setCheckout(false)}
        onClose={() => setCheckout(false)}
        maxWidth={'lg'}
      >
        <UploadMusicVideosCheckout
          close={(files) => {
            setCheckout(false);
            setUploadFiles(uploadFiles.filter((f) => !files.includes(f.file)));
          }}
          files={uploadFiles.map((file) => ({
            file: file.file,
            name: file.name,
            fileName: file.name,
            artistName: file.artistName,
          }))}
        />
      </Dialog>
    </ContentBody>
  );
};

export const UploadMusicVideosCheckout = (props: {
  files: UploadFile[];
  close: (files: File[]) => void;
}) => {
  const { files, close } = props;
  const [startedLoad, setStartedLoad] = useState(false);
  const [fileStatuses, setFileStatuses] = useState(
    files.map((file) => ({
      name: file.name,
      loading: false,
      fileName: file.file.name,
      error: null as string | null,
      progress: 0,
      file: file.file,
    }))
  );
  const mxRef = useRef(new RWMutex());

  return (
    <PopupWindow>
      <div>Upload {files.length} tracks?</div>
      <table style={{ width: '100%' }}>
        <tbody>
          {fileStatuses.map(
            ({ error, loading, name, progress, fileName }, i) => (
              <tr key={i}>
                <td style={{ whiteSpace: 'nowrap' }}>{name || fileName}</td>
                <td>
                  <LoadingAnimation
                    sizePx={20}
                    animate={loading}
                    color={
                      error
                        ? 'tomato'
                        : progress === 1 && !loading
                        ? 'darkgreen'
                        : loading
                        ? `orange`
                        : 'black'
                    }
                  />
                </td>
                <td>
                  {!!error
                    ? error
                    : progress === 1 && !loading
                    ? 'Done'
                    : loading
                    ? `Loading (${Math.min(progress * 100, 99).toFixed(0)}%)`
                    : 'TODO'}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
      {startedLoad && (
        <div>New tracks will be available in search after a minute</div>
      )}
      <div>
        <ContentHeader>
          {startedLoad || (
            <TextSolidButton
              text="Confirm"
              onClick={() => {
                setStartedLoad(true);
                files.forEach((file, i) =>
                  runWithMutexW(mxRef.current, () =>
                    uploadVideoFile({
                      name: file.name!,
                      file: file.file,
                      artistName: file.artistName,
                      fileName: file.file.name,
                      onStatusChange: (status) => {
                        fileStatuses[i].error = status.error;
                        fileStatuses[i].loading = status.loading;
                        fileStatuses[i].progress = status.progress;
                        setFileStatuses([...fileStatuses]);
                      },
                    })
                  )
                );
              }}
            />
          )}

          {fileStatuses.some(
            (f) => !f.error && !f.loading && f.progress === 1
          ) ? (
            <TextSolidButton
              text="Close (remove uploaded)"
              onClick={() => {
                close(
                  fileStatuses
                    .filter((f) => !f.error && !f.loading && f.progress === 1)
                    .map((f) => f.file)
                );
              }}
            />
          ) : (
            <TextSolidButton
              text="Close"
              onClick={() => {
                close([]);
              }}
            />
          )}
        </ContentHeader>
      </div>
    </PopupWindow>
  );
};

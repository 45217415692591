import { Box, ButtonGroup } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  mutateCloneSchedule,
  mutateCreateSchedule,
  mutateCreateTimeslot,
  mutateDeleteTimeslot,
  mutateUpdateSchedule,
  mutateUpdateTimeslot,
  mutateUpdateZone,
  queryScheduleTimeslots,
} from '../../lib/gqlRequests';
import {
  getDiffForNewSchedule,
  timeslotResponseToEntry,
  TimeslotsDiff,
} from '../../lib/scheduleUtils';
import { ButtonGroupRow, PopupWindow } from '../wrappers';
import { timeslotPlayableItem, timeslotType } from '../../lib/scheduleUtils';
import { LoadingAnimation } from '../LoadingAnimation';
import { RegularTextButton } from '../buttons';
import { TimeslotEntry } from '../../lib/types';
import { processSteps, Step } from '../../lib/steps';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { DATE_FORMAT } from '../../lib/constants';

export const SaveProgress = (props: {
  diff: TimeslotsDiff;
  fromScratch: boolean;
  onlyThisWeek: boolean;
  deleteOverlapped: boolean;
  venueId: number;
  scheduleId: number;
  zoneId: number;
  close: Function;
  onSuccess: Function;
  scheduleNow: Date;
}) => {
  const {
    diff,
    deleteOverlapped,
    fromScratch,
    onlyThisWeek,
    scheduleId,
    venueId,
    zoneId,
    scheduleNow,
  } = props;

  if (!venueId && !scheduleId && !zoneId && !diff && !scheduleNow) {
    throw new Error(
      'No venueId or scheduleId or zoneId or diff or scheduleFrom or scheduleTo'
    );
  }

  const scheduleFrom = format(startOfWeek(scheduleNow), DATE_FORMAT);
  const scheduleTo = format(endOfWeek(scheduleNow), DATE_FORMAT);

  const [steps, setSteps] = useState<Step[]>([]);

  const newScheduleId = useRef<number | null>(null);
  const localDiff = useRef<TimeslotsDiff>(diff);
  const newTimeslots = useRef<TimeslotEntry[] | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [canCancel, setCanCancel] = useState(true);

  const initialSteps: Step[] = useMemo(() => {
    const steps: Step[] = [];

    if (fromScratch) {
      const createStep: Step = {
        name: 'create new schedule',
        state: {
          loading: false,
          error: null,
          data: null,
        },
        ready: () => true,
        action: async () => {
          const result = await mutateCreateSchedule({ venueId });

          newScheduleId.current = result.createSchedule.id;

          return result.createSchedule.id.toString();
        },
      };

      steps.push(createStep);
    } else {
      const cloneStep: Step = {
        name: `clone schedule (${scheduleId})`,
        state: {
          loading: false,
          error: null,
          data: null,
        },
        ready: () => true,
        action: async () => {
          const result = await mutateCloneSchedule({ id: scheduleId });

          newScheduleId.current = result.cloneSchedule.id;

          return result.cloneSchedule.id.toString();
        },
      };

      steps.push(cloneStep);
    }

    return steps;
  }, [fromScratch, scheduleId, venueId]);

  const newTimeslotsSteps: Step[] = useMemo(() => {
    const steps: Step[] = [];

    if (fromScratch) return steps;

    const newTimeslotsStep: Step = {
      name: () =>
        `get timeslots for schedule (${newScheduleId.current || '?'})`,
      state: {
        loading: false,
        error: null,
        data: null,
      },
      ready: () => newScheduleId.current != null,
      action: async () => {
        if (!newScheduleId.current) {
          throw new Error('No new schedule id, this shoud not have happened');
        }

        const result = await queryScheduleTimeslots({
          scheduleId: newScheduleId.current,
          from: scheduleFrom,
          to: scheduleTo,
        });

        if (!result.schedule) throw new Error('No schedule returned from API');

        newTimeslots.current = result.schedule.timeslots.map(
          timeslotResponseToEntry
        );

        return `${result.schedule.timeslots.length} timeslots`;
      },
    };

    steps.push(newTimeslotsStep);

    const newDiffStep: Step = {
      name: () =>
        `match old timeslots against new schedule (${
          newScheduleId.current || '?'
        })`,
      state: {
        loading: false,
        error: null,
        data: null,
      },
      ready: () => !!newTimeslots.current && !!localDiff.current,
      action: async () => {
        if (!localDiff.current) {
          throw new Error('No localDiff, this shoud not have happened');
        }

        if (!newTimeslots.current) {
          throw new Error('No new timeslots, this shoud not have happened');
        }

        const newDiff = getDiffForNewSchedule(
          localDiff.current,
          newTimeslots.current
        );

        localDiff.current = newDiff;

        return `${
          newDiff.created.length +
          newDiff.deleted.length +
          newDiff.updated.length
        } timeslots`;
      },
    };

    steps.push(newDiffStep);

    return steps;
  }, [fromScratch, scheduleFrom, scheduleTo]);

  const timeslotSteps: Step[] = useMemo(() => {
    const steps: Step[] = [];

    for (let i = 0; i < localDiff.current.deleted.length; i++) {
      const ts = localDiff.current.deleted[i];

      const step: Step = {
        name: () =>
          `Detele timeslot for ${timeslotType(ts)}: ${
            timeslotPlayableItem(ts).name
          } (${ts.day}: ${ts.from}-${ts.to})`,
        state: {
          loading: false,
          error: null,
          data: null,
        },
        ready: () => true,
        action: async () => {
          const ts = localDiff.current.deleted[i];

          await mutateDeleteTimeslot({
            id: ts.id!,
            includingRepeats: !onlyThisWeek,
          });

          return 'done';
        },
      };

      steps.push(step);
    }

    for (let i = 0; i < localDiff.current.created.length; i++) {
      const ts = localDiff.current.created[i];

      const step: Step = {
        name: () =>
          `Create timeslot for ${timeslotType(ts)}: ${
            timeslotPlayableItem(ts).name
          } (${ts.day}: ${ts.from}-${ts.to})`,
        state: {
          loading: false,
          error: null,
          data: null,
        },
        ready: () => newScheduleId.current != null,
        action: async () => {
          if (!newScheduleId.current!) {
            throw new Error('No new schedule id, this shoud not have happened');
          }

          const ts = localDiff.current.created[i];

          const result = await mutateCreateTimeslot({
            scheduleId: newScheduleId.current!,
            day: ts.day,
            from: ts.from,
            to: ts.to,
            bucketId: ts.bucket?.id,
            playlistId: ts.playlist?.id,
            deleteOverlapped,
            repeatUntilDate: ts.repeatUntilDate, // implement when needed
          });

          return result.createTimeslot.id.toString();
        },
      };

      steps.push(step);
    }

    for (let i = 0; i < localDiff.current.updated.length; i++) {
      const ts = localDiff.current.updated[i];

      const step: Step = {
        name: () =>
          `Update timeslot for ${timeslotType(ts)}: ${
            timeslotPlayableItem(ts).name
          } (${ts.day}: ${ts.from}-${ts.to})`,
        state: {
          loading: false,
          error: null,
          data: null,
        },
        ready: () => true,
        action: async () => {
          const ts = localDiff.current.updated[i];

          const result = await mutateUpdateTimeslot({
            id: ts.id!,
            freezeTimeslotsInAWeek: onlyThisWeek,
            deleteOverlapped,
            values: {
              from: ts.from,
              to: ts.to,
              bucketId: ts.bucket?.id,
              playlistId: ts.playlist?.id,
              repeatUntilDate: ts.repeatUntilDate, // implement when needed
            },
          });

          return result.updateTimeslot.id.toString();
        },
      };

      steps.push(step);
    }

    return steps;
  }, [deleteOverlapped, onlyThisWeek]);

  const commitSteps = useMemo(() => {
    const steps: Step[] = [];

    const scheduleUpdate: Step = {
      name: () =>
        `Update schedule [${newScheduleId.current || '?'}]: isDraft = false`,
      state: {
        loading: false,
        error: null,
        data: null,
      },
      ready: () => newScheduleId.current != null,
      action: async () => {
        if (!newScheduleId.current) {
          throw new Error('No new schedule id, this shoud not have happened');
        }

        const response = await mutateUpdateSchedule({
          id: newScheduleId.current,
          isDraft: false,
        });

        return response.updateSchedule.id.toString();
      },
    };

    const zoneUpdate: Step = {
      name: () => `Update zone: scheduleId = ${newScheduleId.current || '?'}`,
      state: {
        loading: false,
        error: null,
        data: null,
      },
      ready: () => newScheduleId.current != null,
      action: async () => {
        if (!newScheduleId.current) {
          throw new Error('No new schedule id, this shoud not have happened');
        }

        const response = await mutateUpdateZone({
          zoneId: zoneId,
          scheduleId: newScheduleId.current,
        });

        return response.updateZone.id.toString();
      },
    };

    steps.push(scheduleUpdate, zoneUpdate);

    return steps;
  }, [zoneId]);

  useEffect(() => {
    setSteps(
      [
        ...initialSteps,
        ...newTimeslotsSteps,
        ...timeslotSteps,
        ...commitSteps,
      ].map((s, id) => ({
        ...s,
        id,
      }))
    );
  }, [initialSteps, timeslotSteps, commitSteps, newTimeslotsSteps]);

  useEffect(() => {
    processSteps(steps, {
      isCancelled: () => cancel,
      onCanCancel: (v) => setCanCancel(v),
      onDone: (v) => {
        setDone(v);
      },
      onError: (err) => setError(err),
      onLoading: (v) => setLoading(v),
      onNewSteps: (v) => setSteps(v),
    });
  }, [cancel, steps]);

  useEffect(() => {
    if (done) {
      props.onSuccess();
    }
  }, [done, props]);

  return (
    <PopupWindow>
      <Box>
        {steps.map((step) => (
          <Box
            key={step.id}
            color={
              step.state.loading
                ? 'darkblue'
                : step.state.error
                ? 'darkred'
                : step.state.data
                ? 'darkgreen'
                : 'black'
            }
          >
            {typeof step.name === 'function' ? step.name() : step.name} -{' '}
            {step.state.loading
              ? 'loading'
              : step.state.error
              ? `error: ${step.state.error}`
              : step.state.data
              ? `done: ${step.state.data}`
              : '...'}
          </Box>
        ))}
      </Box>
      <Box>
        {error && <Box color="red">{error}</Box>}
        {loading && (
          <Box display="flex" justifyContent="center">
            <LoadingAnimation animate={true} sizePx={20} color="darkblue" />
          </Box>
        )}
        <ButtonGroupRow>
          <ButtonGroup>
            <RegularTextButton
              text="Cancel"
              active={!done && canCancel}
              onClick={() => {
                setCanCancel(false);
                setCancel(true);
              }}
            />
            <RegularTextButton
              text="Done"
              active={done}
              onClick={() => {
                props.close();
              }}
            />
          </ButtonGroup>
        </ButtonGroupRow>
      </Box>
    </PopupWindow>
  );
};

/**
 * 1 - a. Create new schedule
 * 1 - b. Clone schedule
 * 1 - b.1 - fetch timeslots for cloned schedule
 * 1 - b.2 - Match changed playlists agains cloned schedule
 * 2. Apply timeslot updates
 *    2.1 create
 *    2.2 update
 *    2.3 delete
 * 4. Set isDraft: false
 * 5. Update Zone
 */

import {
  Box,
  ButtonGroup,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  timeslotPlayableItem,
  timeslotsDiff,
  timeslotType,
} from '../../lib/scheduleUtils';
import { GlobalState } from '../../lib/types';
import { RegularTextButton } from '../buttons';
import { ButtonGroupRow, PopupWindow, VertScroll } from '../wrappers';
import { SaveProgress } from './SaveProgress';

export const SaveScheduleCheckout = (props: {
  close: Function;
  onDone: Function;
}) => {
  const [fromScratch, setFromScratch] = useState(false);
  const [onlyThisWeek, setOnlyThisWeek] = useState(false);
  const [deleteOverlapped, setDeleteOverlapped] = useState(false);
  const [apply, setApply] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    initialScheduleTimeslots,
    scheduleTimeslots,
    selectedVenueId,
    scheduleId,
    selectedZoneId,
    now,
  } = useSelector((state: GlobalState) => state.scheduler);

  const comparableSchedule = useMemo(() => {
    return (!fromScratch && initialScheduleTimeslots) || [];
  }, [fromScratch, initialScheduleTimeslots]);

  const diff = useMemo(() => {
    const diff = timeslotsDiff(comparableSchedule, scheduleTimeslots || []);
    return diff;
  }, [comparableSchedule, scheduleTimeslots]);

  return (
    <PopupWindow>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '1rem',
        }}
      >
        <Box>How would you like to save this schedule?</Box>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(v) => {
                  setFromScratch(v.target.checked);
                }}
                checked={fromScratch}
              />
            }
            label="From scratch"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled
                onChange={(v) => {
                  setOnlyThisWeek(v.target.checked);
                }}
                checked={onlyThisWeek}
              />
            }
            label="Only this week"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(v) => {
                  setDeleteOverlapped(v.target.checked);
                }}
                checked={deleteOverlapped}
              />
            }
            label="Delete overlapped"
          />
        </FormGroup>

        <VertScroll height="25vh">
          {diff.created.length > 0 && (
            <Box>
              <Box fontWeight="bold">To create:</Box>
              <Box>
                {diff.created.map((t) => (
                  <Box
                    key={`${timeslotPlayableItem(t).id}/${t.day}/${t.from}-${
                      t.to
                    }}`}
                  >
                    {timeslotType(t)}: {timeslotPlayableItem(t).name} ({t.day}:{' '}
                    {t.from}-{t.to})
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {diff.updated.length > 0 && (
            <Box>
              <Box fontWeight="bold">To update:</Box>
              <Box>
                {diff.updated.map((t) => (
                  <Box
                    key={`${timeslotPlayableItem(t).id}/${t.day}/${t.from}-${
                      t.to
                    }`}
                  >
                    {timeslotType(t)}: {timeslotPlayableItem(t).name} ({t.day}:{' '}
                    {t.from}-{t.to})
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {diff.deleted.length > 0 && (
            <Box>
              <Box fontWeight="bold">To delete:</Box>
              <Box>
                {diff.deleted.map((t) => (
                  <Box
                    key={`${timeslotPlayableItem(t).id}/${t.day}/${t.from}-${
                      t.to
                    }`}
                  >
                    {timeslotType(t)}: {timeslotPlayableItem(t).name} ({t.day}:{' '}
                    {t.from}-{t.to})
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </VertScroll>
      </Box>

      <ButtonGroupRow>
        <ButtonGroup>
          <RegularTextButton
            text="Save"
            active={true}
            onClick={() => {
              setApply(true);
            }}
          />
        </ButtonGroup>
        <ButtonGroup>
          <RegularTextButton
            text="Close"
            active={true}
            onClick={() => props.close()}
          />
        </ButtonGroup>
      </ButtonGroupRow>

      <Dialog
        open={apply}
        fullWidth={true}
        onBackdropClick={() => {
          setApply(false);
          if (success) {
            if (success) {
              props.onDone();
              props.close();
            }
          }
        }}
        onClose={() => {
          setApply(false);
          if (success) {
            if (success) {
              props.onDone();
              props.close();
            }
          }
        }}
      >
        <SaveProgress
          deleteOverlapped={deleteOverlapped}
          diff={diff}
          fromScratch={fromScratch}
          onlyThisWeek={onlyThisWeek}
          venueId={selectedVenueId!}
          scheduleId={scheduleId!}
          zoneId={selectedZoneId!}
          close={() => {
            if (success) {
              props.onDone();
            }
            props.close();
          }}
          onSuccess={() => {
            setSuccess(true);
          }}
          scheduleNow={now}
        />
      </Dialog>
    </PopupWindow>
  );
};

import { gql } from '@apollo/client/core';
import { client } from './apolloClient';
import { joinErrors } from './utils';

export async function authenticateUser(email: string, password: string) {
  const result = await client.mutate<{
    loginWithPassword: { loginToken: string };
  }>({
    mutation: gql`
      mutation login($email: String!, $password: String!) {
        loginWithPassword(email: $email, password: $password) {
          loginToken
        }
      }
    `,
    variables: { email, password },
  });

  const token = result?.data?.loginWithPassword?.loginToken;

  if (!result.data && !result.errors)
    throw new Error(
      'No errors returned by @apollo/client despite they have been returned by Server'
    );

  const err = joinErrors(result?.errors);

  if (err) {
    throw new Error(`Request errored: ${err}`);
  }

  if (!token) throw new Error('No token returned');

  return token;
}

import { Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { playlistColumnResolver } from '../../../lib/columnResolvers';
import { MEDIA_TYPES } from '../../../lib/constants';
import { queryDiscoveryVideoPlaylists } from '../../../lib/gqlRequests';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import {
  PlaylistState,
  GlobalState,
  VenueVideoPlaylistsResponse,
} from '../../../lib/types';
import { getParentPath } from '../../../lib/utils';
import { SET_PLAYLISTS_STATE } from '../../../store/store';
import { AddToPlaylistCheckout } from '../../AddTracksToPlaylists';
import {
  RegularTextButton,
  TextCloseButton,
  TextSolidButton,
} from '../../buttons';
import { CreatePlaylist } from '../../CreatePlaylist';
import { FormTextInput } from '../../forms';
import { LoadingAnimation } from '../../LoadingAnimation';
import { ContentHeader, PopupWindow } from '../../wrappers';

export const AddToPlaylist = (props: { close: Function }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { loading, playlists, counter, error } = useSelector(
    (state: GlobalState) => state['playlists'][MEDIA_TYPES.MUSIC_VIDEO]
  );
  const [filterText, setFilterText] = useState('');
  const [checkout, setCheckout] = useState(false);
  const [needCreatePlaylist, setNeedCreatePlaylist] = useState(false);
  const [pickedPlaylist, setPickedPlaylists] = useState<Set<number>>(new Set());
  const { searchResult, selectedIds } = useSelector(
    (state: GlobalState) => state['content'][MEDIA_TYPES.MUSIC_VIDEO]
  );
  const [pickedTrackIds, setPickedTrackIds] = useState<Set<number>>(
    new Set(selectedIds)
  );

  /**If last track removed, return back to search */
  useEffect(() => {
    if (pickedTrackIds.size === 0) {
      props.close();
    }
  }, [pickedTrackIds, props]);

  const { load } = requestDataLoader<
    VenueVideoPlaylistsResponse,
    VenueVideoPlaylistsResponse['venue']['videoPlaylists']
  >({
    initialValue: [],
    identifier: 'videoPlaylistRequest',
    doRequest: () => queryDiscoveryVideoPlaylists(),
    onStateChange: (output) => {
      dispatch({
        type: SET_PLAYLISTS_STATE[MEDIA_TYPES.MUSIC_VIDEO],
        data: {
          loading: output.loading,
          playlists: output.data,
          counter: counter + 1,
          error: output.error,
        } as PlaylistState,
      });
    },
    formatResponse: (result) => result.venue.videoPlaylists,
  });

  if (counter === 0) load();

  /**If last track removed, return back to search */
  if (pickedTrackIds.size === 0) {
    history.push(getParentPath(path));
  }

  const trackIdsCheckout = Array.from(pickedTrackIds);
  const playlistsCheckout = playlists
    .filter((pl) => pickedPlaylist.has(pl.id))
    .map((pl) => ({ id: pl.id, name: pl.name }));

  return (
    <PopupWindow>
      <Dialog
        open={needCreatePlaylist}
        onBackdropClick={() => setNeedCreatePlaylist(false)}
        onClose={() => setNeedCreatePlaylist(false)}
      >
        <CreatePlaylist
          close={() => setNeedCreatePlaylist(false)}
          venueId={Number(process.env.REACT_APP_DISCOVERY_VENUE_ID)}
          mediaType={MEDIA_TYPES.MUSIC_AUDIO}
        />
      </Dialog>

      <Dialog
        open={checkout}
        onBackdropClick={() => setCheckout(false)}
        onClose={() => setCheckout(false)}
      >
        <AddToPlaylistCheckout
          close={() => setCheckout(false)}
          trackIds={trackIdsCheckout}
          playlists={playlistsCheckout}
        />
      </Dialog>

      <div className="self-start">Picked tracks:</div>
      <div className="buttons-inline">
        {searchResult
          .filter((t) => pickedTrackIds.has(t.id))
          .map((t) => (
            <TextCloseButton
              key={t.id}
              text={t.name}
              active={pickedTrackIds.size > 1}
              onClick={() => {
                pickedTrackIds.delete(t.id) &&
                  setPickedTrackIds(new Set(pickedTrackIds));
              }}
            />
          ))}
      </div>
      <div className="self-start">Picked playlists:</div>
      <div className="buttons-inline">
        {playlists
          .filter((p) => pickedPlaylist.has(p.id))
          .map((p) => (
            <TextCloseButton
              key={p.id}
              text={p.name}
              onClick={() =>
                pickedPlaylist.delete(p.id) &&
                setPickedPlaylists(new Set(pickedPlaylist))
              }
            />
          ))}
      </div>
      <ContentHeader>
        <TextSolidButton
          text="Add tracks to playlists"
          active={pickedPlaylist.size > 0 && pickedTrackIds.size > 0}
          onClick={() => {
            setCheckout(true);
          }}
        />

        <TextSolidButton
          text="New Playlist"
          active={true}
          onClick={() => {
            setNeedCreatePlaylist(true);
          }}
        />
      </ContentHeader>
      <ContentHeader>
        <div className="search-button">
          <FormTextInput
            id="searchVideoText"
            type="text"
            placeholder="filter by name"
            onChange={(text) => {
              setFilterText(text);
            }}
          />
        </div>
        <div>
          <RegularTextButton
            text="Refetch all playlists"
            onClick={() => {
              load();
            }}
          />
        </div>
      </ContentHeader>
      <table className="content-table">
        <thead>
          <tr>
            <th key="cb">
              <div className="content-table-header">✓</div>
            </th>
            {playlistColumnResolver.map((col) => (
              <th key={col.title}>
                <div className="content-table-header">{col.title}</div>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {playlists.map((row, i) => {
            if (
              !row.name
                .toLocaleLowerCase()
                .includes(filterText.toLocaleLowerCase())
            )
              return undefined;

            return (
              <tr key={row.id}>
                <td>
                  <input
                    key="cb"
                    type="checkbox"
                    checked={pickedPlaylist.has(row.id)}
                    onChange={(e) => {
                      e.target.checked
                        ? pickedPlaylist.add(row.id)
                        : pickedPlaylist.delete(row.id);
                      setPickedPlaylists(new Set(pickedPlaylist));
                    }}
                  />
                </td>
                {playlistColumnResolver.map((col) => {
                  const val: any = col.prop
                    ? row[col.prop as keyof typeof row]
                    : col.resolve?.(row);
                  return <td key={col.title}>{val}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {loading && (
        <div style={{ paddingLeft: '10px' }}>
          <LoadingAnimation sizePx={300} color={'black'} animate={true} />
        </div>
      )}
      {error && <div>{error}</div>}
    </PopupWindow>
  );
};

import { Link } from 'react-router-dom';

export const MenuItem: (props: {
  text: string;
  selected?: boolean;
  onSelect?: () => void;
  to: string;
}) => JSX.Element = (props) => {
  return (
    <Link to={props.to} className="menu-item">
      <button
        className="link-button"
        style={{ fontWeight: props.selected ? 'bold' : 'normal' }}
        onClick={() => props?.onSelect?.()}
      >
        {props.text}
      </button>
    </Link>
  );
};

export const MenuRow = (props: any) => {
  return <div className="menu-row">{props.children}</div>;
};

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MEDIA_TYPES,
  PLAYLISTS_MANAGE_CHANNELS_PATH_PREFIX,
  PLAYLISTS_MANAGE_MUSIC_AUDIO_PATH_PREFIX,
  PLAYLISTS_MANAGE_MUSIC_VIDEO_PATH_PREFIX,
} from '../lib/constants';
import { GlobalState, PlaylistState } from '../lib/types';
import { mutateNewPlaylist } from '../lib/gqlRequests';
import { requestDataLoader } from '../lib/requestDataLoader';
import {
  API_MEDIA_TYPES,
  PlaylistManagement,
  PlaylistObject,
} from '../lib/types';
import {
  SET_PLAYLISTS_MANAGE_STATE,
  SET_PLAYLISTS_STATE,
} from '../store/store';
import { CellRedirect, TextSolidButton } from './buttons';
import { FormTextInput } from './forms';
import { LoadingAnimation } from './LoadingAnimation';
import { ContentHeader, PopupWindow } from './wrappers';

export const CreatePlaylist = (props: {
  mediaType: MEDIA_TYPES;
  venueId: number;
  close: () => void;
}) => {
  const dispatch = useDispatch();
  const { playlists } = useSelector(
    (state: GlobalState) => state['playlists'][props.mediaType]
  );
  const [name, setName] = useState('');
  const [state, setState] = useState({
    loading: false,
    error: null as Error | null,
    data: null as PlaylistObject | null,
  });

  const { load } = requestDataLoader({
    identifier: `newPlaylist`,
    initialValue: null as PlaylistObject | null,
    doRequest: () =>
      mutateNewPlaylist({
        mediaType:
          props.mediaType === MEDIA_TYPES.MUSIC_AUDIO
            ? API_MEDIA_TYPES.MUSIC
            : props.mediaType === MEDIA_TYPES.MUSIC_VIDEO
            ? API_MEDIA_TYPES.VIDEO
            : API_MEDIA_TYPES.CHANNEL,
        name: name,
        venueId: props.venueId,
      }),
    formatResponse: (res) => res.createPlaylist,
    onStateChange: (output) => {
      setState(output);

      if (output.data) {
        dispatch({
          type: SET_PLAYLISTS_STATE[props.mediaType],
          data: {
            playlists: [...playlists, output.data],
          } as PlaylistState,
        });
      }
    },
  });

  return (
    <PopupWindow>
      <div>Enter name for playlist ({props.mediaType}):</div>
      <div>
        <FormTextInput
          type="text"
          placeholder="playlist name"
          onChange={(text) => {
            setName(text);
          }}
        />
      </div>

      {state.data && (
        <div>
          Created playlist: <span>{state.data.name}</span>
          {'. Proceed to '}
          <CellRedirect
            text="Manage"
            onClick={() => {
              dispatch({
                type: SET_PLAYLISTS_MANAGE_STATE[props.mediaType],
                data: {
                  playlistId: state.data!.id,
                } as PlaylistManagement,
              });
            }}
            to={`${
              props.mediaType === MEDIA_TYPES.MUSIC_AUDIO
                ? PLAYLISTS_MANAGE_MUSIC_AUDIO_PATH_PREFIX
                : props.mediaType === MEDIA_TYPES.MUSIC_VIDEO
                ? PLAYLISTS_MANAGE_MUSIC_VIDEO_PATH_PREFIX
                : PLAYLISTS_MANAGE_CHANNELS_PATH_PREFIX
            }/${state.data.id}`}
          />
          {' or create one more '}
        </div>
      )}
      {state.error && <div>Error: {state.error}</div>}

      <LoadingAnimation
        sizePx={100}
        animate={state.loading}
        color={
          state.data
            ? 'darkgreen'
            : state.loading
            ? 'orange'
            : state.error
            ? 'tomato'
            : 'black'
        }
      />

      <ContentHeader>
        {!state.loading && (
          <TextSolidButton
            text="Create"
            onClick={() => {
              load();
            }}
          />
        )}

        <TextSolidButton
          text="Back"
          onClick={() => {
            props.close();
          }}
        />
      </ContentHeader>
    </PopupWindow>
  );
};

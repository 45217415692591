import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MEDIA_TYPES } from '../../../lib/constants';
import { mutateSetBlacklisted } from '../../../lib/gqlRequests';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import { ContentState, GlobalState } from '../../../lib/types';
import { SET_CONTENT_STATE } from '../../../store/store';
import { TextSolidButton } from '../../buttons';
import { LoadingAnimation } from '../../LoadingAnimation';
import { ContentHeader, PopupWindow } from '../../wrappers';

export const BlockTracksCheckout = (props: {
  close: () => void;
  mediaType: MEDIA_TYPES;
}) => {
  const { close } = props;
  const dispatch = useDispatch();

  const [startedLoad, setStartedLoad] = useState(false);
  const { searchResult, selectedIds } = useSelector<GlobalState, ContentState>(
    (state) => state['content'][props.mediaType]
  );

  const tracks: { id: number; name: string }[] = searchResult
    .filter((r) => selectedIds.has(r.id))
    .map((r) => ({ id: r.id, name: r.name }));

  const [blockTrackStatuses, setBlockTrackStatuses] = useState(
    tracks.map((track) => ({
      id: track.id,
      name: track.name,
      loading: false,
      error: null as Error | null,
      data: false,
    }))
  );

  const loaders = blockTrackStatuses.map((status, i) =>
    requestDataLoader({
      identifier: `deactivateTracks/${props.mediaType}/${status.id}`,
      initialValue: false,
      doRequest: () =>
        mutateSetBlacklisted({
          trackId: status.id,
          blacklisted: true,
          removeFromQueues: true,
        }),
      formatResponse: (res) => true,
      onStateChange: (output) => {
        blockTrackStatuses[i] = {
          ...blockTrackStatuses[i],
          data: output.data,
          error: output.error,
          loading: output.loading,
        };

        if (output.data) {
          searchResult.forEach((row) => {
            if (row.id === status.id) {
              row.isBlacklisted = true;
            }
          });

          dispatch({
            type: SET_CONTENT_STATE[props.mediaType],
            data: {
              searchResult: [...searchResult],
            },
          });
        }

        setBlockTrackStatuses([...blockTrackStatuses]);
      },
    })
  );

  return (
    <PopupWindow>
      <div>
        Block {tracks.length} tracks? They will be unavailable in Portal Search
        and removed from all schedules and players as soon as possible
      </div>
      <table style={{ width: '100%' }}>
        <tbody>
          {blockTrackStatuses.map(({ id, name, data, error, loading }) => (
            <tr key={id}>
              <td style={{ whiteSpace: 'nowrap' }}>{name}</td>
              <td>
                <LoadingAnimation
                  sizePx={20}
                  animate={loading}
                  color={
                    data
                      ? 'darkgreen'
                      : loading
                      ? 'orange'
                      : error
                      ? 'tomato'
                      : 'black'
                  }
                />
              </td>
              <td>{data ? 'Done' : loading ? 'Loading' : error || 'TODO'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        <ContentHeader>
          {startedLoad || (
            <TextSolidButton
              text="Confirm"
              onClick={() => {
                setStartedLoad(true);
                loaders.forEach((l) => l.load());
              }}
            />
          )}
          <TextSolidButton
            text="Back"
            onClick={() => {
              close();
            }}
          />
        </ContentHeader>
      </div>
    </PopupWindow>
  );
};

import { Box, ButtonGroup, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mutateCloneZoneToNewVenu } from '../../lib/gqlRequests';
import { requestDataLoader } from '../../lib/requestDataLoader';
import { CloneZoneToNewVenueResponse, GlobalState } from '../../lib/types';
import { ACTION_TYPES } from '../../store/store';
import { RegularTextButton } from '../buttons';
import { LoadingAnimation } from '../LoadingAnimation';
import { ButtonGroupRow, PopupWindow } from '../wrappers';

export const CloneSiteCheckout = (props: {
  zoneId: number;
  close: Function;
  onDone: Function;
}) => {
  const dispatch = useDispatch();

  const [venueName, setVenueName] = useState('');

  const venues = useSelector((state: GlobalState) => state.venues);

  const [{ data, error, loading }, setState] = useState({
    loading: false,
    error: null as null | string,
    data: null as CloneZoneToNewVenueResponse['cloneZoneToNewVenue'] | null,
  });

  const { load } = useMemo(() => {
    return requestDataLoader({
      doRequest: async () => {
        const result = await mutateCloneZoneToNewVenu({
          venueName,
          zoneId: props.zoneId,
        });

        return result;
      },
      onStateChange: (state) => {
        const newZone = state.data;

        if (newZone) {
          dispatch({
            type: ACTION_TYPES.SET_VENUES,
            data: [...(venues || []), newZone.venue],
          });
        }

        setState({
          data: newZone,
          loading: state.loading,
          error: state.error && String(state.error),
        });
      },
      initialValue: null as
        | null
        | CloneZoneToNewVenueResponse['cloneZoneToNewVenue'],
      identifier: `getScheduleBackups`,
      formatResponse: (result) => result.cloneZoneToNewVenue,
    });
  }, [dispatch, props.zoneId, venueName, venues]);

  return (
    <PopupWindow>
      <Box textAlign="center">
        This will create a new venue and clone current zone into it (with
        schedule and library)
      </Box>
      <TextField
        id="venue-name"
        label="New venue name"
        variant="outlined"
        onChange={(e) => setVenueName(e.target.value)}
      />
      {loading && <LoadingAnimation animate={true} sizePx={100} />}
      {error && <div color="red">{error}</div>}
      {data && (
        <div>
          <Box>Done!</Box>
          <RegularTextButton
            text="Go to new zone's schedule"
            onClick={() => {
              dispatch({
                type: ACTION_TYPES.SET_SCHEDULER,
                data: {
                  draggedItem: null,
                  draggedItemType: null,
                  initialScheduleTimeslots: null,
                  lastTimeslotStates: [],
                  listFilterText: '',
                  scheduleError: null,
                  scheduleId: null,
                  scheduleLoading: false,
                  scheduleTimeslots: null,
                  selectedVenueId: data.venue.id,
                  selectedZoneId: data.id,
                } as Partial<GlobalState['scheduler']>,
              });
              props.close();
            }}
          />
        </div>
      )}
      <ButtonGroupRow>
        <ButtonGroup>
          <RegularTextButton
            text="Apply"
            active={true}
            onClick={() => {
              load();
            }}
          />
        </ButtonGroup>
        <ButtonGroup>
          <RegularTextButton
            text="Close"
            active={true}
            onClick={() => props.close()}
          />
        </ButtonGroup>
      </ButtonGroupRow>
    </PopupWindow>
  );
};

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import './styles.css';

import { store } from './store/store';
import { Login } from './components/Login';
import { Main } from './components/Main';
import { Header } from './components/Header';
import { SpotifyCallback } from './components/callbacks/spotifyCallback';
import { SPOTIFY_CALLBACK_PATH } from './lib/constants';

function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path={'/' + SPOTIFY_CALLBACK_PATH}
            component={SpotifyCallback}
          />
          <Route path="/" component={Main} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

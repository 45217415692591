import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GlobalState,
  PlaylistManagement,
  PlaylistObject,
  PlaylistState,
  SpotifyPlaylistImportTrackMatchInput,
} from '../../../lib/types';

import {
  ACTION_TYPES,
  SET_PLAYLISTS_MANAGE_STATE,
  SET_PLAYLISTS_STATE,
} from '../../../store/store';
import {
  SpotifyPlaylist,
  SpotifyPlaylistsResponse,
  spotifyService,
} from '../../../services/spotifyService';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import { CellRedirect, TextSolidButton } from '../../buttons';
import {
  mutateAddTracksToSpotifyPlaylistImport,
  mutateCreatePlaylistFromSpotifyPlaylistImport,
  mutateCreateSpotifyPlaylistImport,
} from '../../../lib/gqlRequests';
import {
  MEDIA_TYPES,
  PLAYLISTS_MANAGE_MUSIC_AUDIO_PATH_PREFIX,
} from '../../../lib/constants';
import { SpotifyPlaylistComponent } from './SpotifyPlaylistComponent';
import { FormTextInput } from '../../forms';
import { PopupWindow } from '../../wrappers';

export const ImportFromSpotify = () => {
  const dispatch = useDispatch();

  const [authError, setAuthError] = useState<null | string>(null);
  const {
    spotifyTokenData,
    playlists: {
      [MEDIA_TYPES.MUSIC_AUDIO]: { playlists },
    },
  } = useSelector<GlobalState, GlobalState>((state: GlobalState) => state);
  const [selectedPlaylist, setSelectedPlaylist] =
    useState<SpotifyPlaylistsResponse['items'][0]>();

  const [fetchUserPlaylistStatus, setFetchUserPlaylistStatus] = useState({
    data: null as null | SpotifyPlaylistsResponse,
    error: null as any,
    loading: false,
  });

  const [filterText, setFilterText] = useState('');

  const { load: loadPlaylists } = useMemo(
    () =>
      requestDataLoader({
        identifier: `spotifyPlaylists`,
        initialValue: null as null | SpotifyPlaylistsResponse,
        doRequest: () => {
          return spotifyService.getUserPlaylists();
        },
        formatResponse: (res) => res,
        onStateChange: (output) => {
          setFetchUserPlaylistStatus(output);
        },
      }),
    []
  );

  const {
    data: spotifyPlaylistReponse,
    error: spotifyImportError,
    loading: spotifyImportLoading,
  } = fetchUserPlaylistStatus;

  const [createPlaylistStatus, setCreatePlaylistStatus] = useState({
    data: null as null | PlaylistObject,
    error: null as any,
    loading: false,
  });

  const { load: createPlaylist } = useMemo(
    () =>
      requestDataLoader({
        identifier: `spotifyPlaylists`,
        initialValue: null as null | PlaylistObject,
        doRequest: (allwoExplicit: boolean) => {
          return createPlaylistFromSpotifyPlaylist(
            selectedPlaylist!,
            allwoExplicit
          );
        },
        formatResponse: (data) => data,
        onStateChange: (output) => {
          setCreatePlaylistStatus(output);

          if (output.data) {
            dispatch({
              type: SET_PLAYLISTS_STATE[MEDIA_TYPES.MUSIC_AUDIO],
              data: {
                playlists: [...playlists, output.data],
              } as PlaylistState,
            });
          }
        },
      }),
    [dispatch, playlists, selectedPlaylist]
  );

  useEffect(() => {
    if (spotifyTokenData) {
      return;
    }

    spotifyService
      .authorizeSpotify()
      .then((res) => {
        dispatch({
          type: ACTION_TYPES.SET_SPOTIFY_TOKEN_DATA,
          spotifyTokenData: res,
        });
      })
      .catch((err) => {
        setAuthError(err);
      });
  }, [spotifyTokenData, dispatch]);

  useEffect(() => {
    if (!spotifyTokenData) {
      return;
    }

    loadPlaylists();
  }, [spotifyTokenData, loadPlaylists]);

  if (authError) {
    return <div>Authentication error: {authError}</div>;
  }

  if (!spotifyTokenData) {
    return <div>Authentication Spotify user...</div>;
  }

  if (!spotifyImportLoading && !spotifyPlaylistReponse && !spotifyImportError) {
    return <PopupWindow></PopupWindow>;
  }

  if (spotifyImportError) {
    return <PopupWindow>Error: {spotifyImportError}</PopupWindow>;
  }

  if (spotifyImportLoading) {
    return <PopupWindow>Loading...</PopupWindow>;
  }

  const showedPlaylists =
    spotifyPlaylistReponse?.items.filter((item) => {
      return (
        item.tracks.total > 0 &&
        item.name.toLowerCase().includes(filterText.toLocaleLowerCase())
      );
    }) || [];

  return (
    <PopupWindow>
      <FormTextInput
        id="searchSpotifyPlaylists"
        type="text"
        inputValue={filterText}
        placeholder="filter by name"
        onChange={(text) => {
          setFilterText(text);
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: 'space-around',
        }}
      >
        {showedPlaylists.map((playlist) => (
          <SpotifyPlaylistComponent
            key={playlist.id}
            imageUrl={playlist.images[0]?.url}
            name={playlist.name}
            trackCount={playlist.tracks.total}
            selected={selectedPlaylist === playlist}
            onClick={() => {
              if (createPlaylistStatus.loading) {
                return;
              }

              if (playlist === selectedPlaylist) {
                return setSelectedPlaylist(undefined);
              }

              setSelectedPlaylist(playlist);
            }}
            loading={
              createPlaylistStatus.loading && selectedPlaylist === playlist
            }
          />
        ))}
      </div>

      <div
        style={{
          position: 'sticky',
          bottom: '0px',
          backgroundColor: 'white',
          padding: '10px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className="button-group" style={{ paddingBottom: '10px' }}>
          <TextSolidButton
            text="Import (all)"
            active={!!selectedPlaylist}
            onClick={() => {
              createPlaylist(true);
            }}
          />

          <TextSolidButton
            text="Import (non-explicit)"
            active={!!selectedPlaylist}
            onClick={() => {
              createPlaylist(false);
            }}
          />
        </div>

        {createPlaylistStatus.loading && <div>Creating playlist...</div>}
        {createPlaylistStatus.error && (
          <div>Error while creating playlist: {createPlaylistStatus.error}</div>
        )}
        {createPlaylistStatus.data && (
          <div>
            Successfully created playlist: {createPlaylistStatus.data.name}{' '}
            {' -> '}
            <CellRedirect
              text="Manage"
              onClick={() => {
                dispatch({
                  type: SET_PLAYLISTS_MANAGE_STATE[MEDIA_TYPES.MUSIC_AUDIO],
                  data: {
                    playlistId: createPlaylistStatus.data?.id,
                  } as PlaylistManagement,
                });
              }}
              to={`${PLAYLISTS_MANAGE_MUSIC_AUDIO_PATH_PREFIX}/${createPlaylistStatus.data?.id}`}
            />
          </div>
        )}
      </div>
    </PopupWindow>
  );
};

async function createPlaylistFromSpotifyPlaylist(
  spotifyPlaylist: SpotifyPlaylist,
  allowExplicit: boolean
) {
  const spotifyTracks = await spotifyService.getTracksInPlaylist(
    spotifyPlaylist.tracks.href
  );

  if (spotifyTracks.items.length === 0) {
    throw new Error(`No tracks in the playlist`);
  }

  const createdPlaylistImportResult = await mutateCreateSpotifyPlaylistImport({
    name: `${spotifyPlaylist.name} (Spotify - ${spotifyPlaylist.owner.display_name})`,
    spotifyPlaylistId: spotifyPlaylist.id,
  });

  const id = createdPlaylistImportResult.createSpotifyPlaylistImport.id;

  let tracksInpput: SpotifyPlaylistImportTrackMatchInput[];

  const uniqueTrackIds = new Set<string>();
  spotifyTracks.items.forEach((t) =>
    uniqueTrackIds.add(t.track.external_ids.isrc)
  );

  const uniqueSpotifyTracks = spotifyTracks.items.filter((t) =>
    uniqueTrackIds.delete(t.track.external_ids.isrc)
  );

  if (allowExplicit) {
    tracksInpput = uniqueSpotifyTracks.map((item) => ({
      isrc: item.track.external_ids.isrc,
      spotifyAlbumName: item.track.album.name,
      spotifyArtistNames: item.track.artists.map((artist) => artist.name),
      spotifyTrackId: item.track.id,
      spotifyTrackName: item.track.name,
    }));
  } else {
    tracksInpput = uniqueSpotifyTracks
      .filter((track) => !track.track.explicit)
      .map((item) => ({
        isrc: item.track.external_ids.isrc,
        spotifyAlbumName: item.track.album.name,
        spotifyArtistNames: item.track.artists.map((artist) => artist.name),
        spotifyTrackId: item.track.id,
        spotifyTrackName: item.track.name,
      }));
  }

  if (tracksInpput.length === 0) {
    throw new Error(`All tracks are explicit`);
  }

  await mutateAddTracksToSpotifyPlaylistImport({
    spotifyPlaylistImportId: id,
    tracks: tracksInpput,
  });

  const audalizePlaylistResponse =
    await mutateCreatePlaylistFromSpotifyPlaylistImport({
      spotifyPlaylistImportId: id,
    });

  return audalizePlaylistResponse.createPlaylistFromSpotifyPlaylistImport;
}

import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Box, ButtonGroup } from '@mui/material';
import { format, add } from 'date-fns';
import { useMemo, useState } from 'react';
import { DATE_FORMAT } from '../../lib/constants';
import { requestDataLoader } from '../../lib/requestDataLoader';
import { getPlayHistory } from '../../lib/requests';
import { RegularTextButton } from '../buttons';
import { LoadingAnimation } from '../LoadingAnimation';
import { ButtonGroupRow, PopupWindow } from '../wrappers';

export const ExportHistory = (props: { close: Function; zoneId: number }) => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [state, setState] = useState({
    loading: false,
    error: null as null | Error,
    data: undefined as undefined | null | string,
  });

  const from = format(fromDate, DATE_FORMAT);
  const to = format(add(toDate, { days: 1 }), DATE_FORMAT);

  const { load } = useMemo(() => {
    return requestDataLoader({
      doRequest: async () => {
        const result = await getPlayHistory({
          from,
          to,
          zoneId: props.zoneId,
        });

        return result;
      },
      onStateChange: setState,
      initialValue: undefined,
      identifier: `getPlayHistory/${fromDate}/${toDate}`,
      formatResponse: (result) => result,
    });
  }, [fromDate, toDate, from, to, props.zoneId]);

  return (
    <PopupWindow>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          variant="inline"
          format={DATE_FORMAT}
          margin="normal"
          id="date-picker-from"
          label="From"
          value={fromDate}
          onChange={(date) => {
            if (!date) return;

            setFromDate(date);
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          variant="inline"
          format={DATE_FORMAT}
          margin="normal"
          id="date-picker-to"
          label="To"
          value={toDate}
          onChange={(date) => {
            if (!date) return;

            setToDate(date);
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
      <Box justifyContent="center" alignItems="center" display="flex">
        {state.loading && (
          <LoadingAnimation animate={true} sizePx={100} color="orange" />
        )}
        {state.error && <div>{state.error.message}</div>}
        {state.data === null && <Box>No history within specified period</Box>}
        {state.data && (
          <div>
            <RegularTextButton
              text="Save"
              onClick={() =>
                downloadTxtFile(
                  `${props.zoneId}_${from}_${to}.csv`,
                  state.data!!
                )
              }
            />
          </div>
        )}
      </Box>
      <ButtonGroupRow>
        <ButtonGroup>
          <RegularTextButton
            text="Get history"
            active={!state.loading}
            onClick={() => load()}
          />
        </ButtonGroup>
        <ButtonGroup>
          <RegularTextButton
            text="Close"
            active={true}
            onClick={() => props.close()}
          />
        </ButtonGroup>
      </ButtonGroupRow>
    </PopupWindow>
  );
};

const downloadTxtFile = (filename: string, data: string) => {
  const element = document.createElement('a');
  const file = new Blob([data], {
    type: 'text/csv',
  });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
};

import { Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MEDIA_TYPES } from '../../../lib/constants';
import { mutateUpdatePlaylist } from '../../../lib/gqlRequests';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import {
  ContentState,
  GlobalState,
  PlaylistObject,
  PlaylistState,
} from '../../../lib/types';
import { SET_PLAYLISTS_STATE } from '../../../store/store';
import { TextCloseButton, TextSolidButton } from '../../buttons';
import { LoadingAnimation } from '../../LoadingAnimation';
import { ContentHeader, PopupWindow } from '../../wrappers';

export const PlaylistEdit = (props: {
  close: Function;
  mediaType: MEDIA_TYPES;
}) => {
  const { editEntries } = useSelector(
    (state: GlobalState) => state['playlists'][props.mediaType]
  );
  const dispatch = useDispatch();
  const [checkout, setCheckout] = useState(false);

  useEffect(() => {
    if (Object.keys(editEntries).length === 0) {
      props.close();
    }
  }, [editEntries, props]);

  return (
    <PopupWindow>
      <Dialog
        open={checkout}
        onBackdropClick={() => setCheckout(false)}
        onClose={() => setCheckout(false)}
      >
        <EditPlaylistsCheckout
          close={() => setCheckout(false)}
          editEntries={editEntries}
          mediaType={props.mediaType}
        />
      </Dialog>

      {Object.entries(editEntries).map(([id, obj]) => (
        <div className="buttons-inline" key={id}>
          <ContentHeader>{id}:</ContentHeader>
          {Object.entries(obj).map(([prop, val]) => {
            return (
              <TextCloseButton
                key={id + ':' + prop}
                text={`${prop}: ${val}`}
                active={true}
                onClick={() => {
                  delete obj[prop as keyof typeof obj];

                  const newEditEntries = { ...editEntries };
                  if (Object.keys(obj).length === 0) {
                    delete newEditEntries[id];
                  } else {
                    delete newEditEntries[id][
                      prop as keyof typeof newEditEntries['']
                    ];
                  }

                  dispatch({
                    type: SET_PLAYLISTS_STATE[props.mediaType],
                    data: {
                      editEntries: newEditEntries,
                    } as ContentState,
                  });
                }}
              />
            );
          })}
        </div>
      ))}

      <ContentHeader>
        <TextSolidButton
          text="Back"
          onClick={() => {
            props.close();
          }}
        />
        <TextSolidButton
          text="Update"
          onClick={() => {
            setCheckout(true);
          }}
        />
      </ContentHeader>
    </PopupWindow>
  );
};

export const EditPlaylistsCheckout = (props: {
  close: () => void;
  editEntries: PlaylistState['editEntries'];
  mediaType: MEDIA_TYPES;
}) => {
  const dispatch = useDispatch();
  const { close, editEntries } = props;
  const [startedLoad, setStartedLoad] = useState(false);

  const { playlists } = useSelector<
    GlobalState,
    GlobalState['playlists'][MEDIA_TYPES]
  >((state: GlobalState) => state['playlists'][props.mediaType]);

  const [updateStatuses, setUpdateStatuses] = useState(
    Object.entries(editEntries).map(([id, entry]) => ({
      id: id,
      loading: false,
      error: null as Error | null,
      data: null as null | PlaylistObject,
    }))
  );

  const [removedEdits, setRemovedEdits] = useState<number[]>([]);

  useEffect(() => {
    const newEditEntries = { ...editEntries };
    for (const id of removedEdits) {
      delete newEditEntries[id];
    }

    dispatch({
      type: SET_PLAYLISTS_STATE[props.mediaType],
      data: {
        editEntries: newEditEntries,
      } as PlaylistState,
    });
  }, [removedEdits, dispatch, editEntries, props.mediaType]);

  const loaders = Object.entries(editEntries).map(([id, entry], i) =>
    requestDataLoader({
      identifier: `updatePlaylist/${id}`,
      initialValue: null as null | PlaylistObject,
      doRequest: () => {
        const params = {
          isActive: !!entry.isActive,
          isCore: !!entry.isCore,
          name: entry.name!,
        };

        return mutateUpdatePlaylist(Number(id), params);
      },
      formatResponse: (res) => res.updatePlaylist,
      onStateChange: (output) => {
        updateStatuses[i] = {
          ...updateStatuses[i],
          data: output.data,
          error: output.error,
          loading: output.loading,
        };

        if (output.data) {
          const data = output.data;
          setRemovedEdits([...removedEdits, data.id]);

          playlists.forEach((r, i, arr) => {
            if (r.id !== data.id) return;

            arr[i] = { ...arr[i], ...data };
          });

          dispatch({
            type: SET_PLAYLISTS_STATE[props.mediaType],
            data: {
              playlists: [...playlists],
            } as PlaylistState,
          });
        }

        setUpdateStatuses([...updateStatuses]);
      },
    })
  );

  return (
    <PopupWindow>
      <div>Update {Object.keys(editEntries).length} playlists?</div>
      <table style={{ width: '100%' }}>
        <tbody>
          {updateStatuses.map(({ error, loading, id, data }, i) => (
            <tr key={id}>
              <td style={{ whiteSpace: 'nowrap' }}>{id}</td>
              <td>
                <LoadingAnimation
                  sizePx={20}
                  animate={loading}
                  color={
                    data
                      ? 'darkgreen'
                      : loading
                      ? 'orange'
                      : error
                      ? 'tomato'
                      : 'black'
                  }
                />
              </td>
              <td>
                {error
                  ? String(error?.toString())
                  : data
                  ? 'Done'
                  : loading
                  ? `Loading`
                  : 'TODO'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <ContentHeader>
          {startedLoad || (
            <TextSolidButton
              text="Confirm"
              onClick={() => {
                setStartedLoad(true);

                loaders.forEach(({ load }) => load());
              }}
            />
          )}

          <TextSolidButton
            text="Back"
            onClick={() => {
              close();
            }}
          />
        </ContentHeader>
      </div>
    </PopupWindow>
  );
};

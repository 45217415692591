import { createStore, Reducer } from 'redux';
import { MEDIA_TYPES } from '../lib/constants';
import { buildLastTimeslotState } from '../lib/scheduleUtils';
import { LIST_SOURCE, LIST_TYPE, GlobalState } from '../lib/types';
import { removeUndefinedProperties } from '../lib/utils';
import { spotifyService } from '../services/spotifyService';
import { venueService } from '../services/venueService';

if (!process.env.REACT_APP_API_ADDRESS) {
  throw new Error(`Incorrect build: missing API_ADDRESS`);
}

export enum ACTION_TYPES {
  SET_AUTH_DATA,
  SET_AUTH_REF,
  SET_LAST_PATH,

  SET_MUSIC_AUDIO_STATE,
  SET_MUSIC_VIDEO_STATE,
  SET_CHANNELS_STATE,

  SET_AUDIO_PLAYLISTS_STATE,
  SET_VIDEO_PLAYLISTS_STATE,
  SET_CHANNEL_PLAYLISTS_STATE,

  SET_AUDIO_PLAYLISTS_MANAGE_STATE,
  SET_VIDEO_PLAYLISTS_MANAGE_STATE,
  SET_CHANNEL_PLAYLISTS_MANAGE_STATE,

  SET_PREVIEW,

  SET_SPOTIFY_TOKEN_DATA,

  SET_VENUES,
  SET_SCHEDULER,

  REMOVE_PLAYLIST_FROM_LIBRARY,
  REMOVE_BUCKET_FROM_LIBRARY,
}

export const SET_CONTENT_STATE = {
  [MEDIA_TYPES.MUSIC_AUDIO]: ACTION_TYPES.SET_MUSIC_AUDIO_STATE,
  [MEDIA_TYPES.MUSIC_VIDEO]: ACTION_TYPES.SET_MUSIC_VIDEO_STATE,
  [MEDIA_TYPES.CHANNEL]: ACTION_TYPES.SET_CHANNELS_STATE,
};

export const SET_PLAYLISTS_STATE = {
  [MEDIA_TYPES.MUSIC_AUDIO]: ACTION_TYPES.SET_AUDIO_PLAYLISTS_STATE,
  [MEDIA_TYPES.MUSIC_VIDEO]: ACTION_TYPES.SET_VIDEO_PLAYLISTS_STATE,
  [MEDIA_TYPES.CHANNEL]: ACTION_TYPES.SET_CHANNEL_PLAYLISTS_STATE,
};

export const SET_PLAYLISTS_MANAGE_STATE = {
  [MEDIA_TYPES.MUSIC_AUDIO]: ACTION_TYPES.SET_AUDIO_PLAYLISTS_MANAGE_STATE,
  [MEDIA_TYPES.MUSIC_VIDEO]: ACTION_TYPES.SET_VIDEO_PLAYLISTS_MANAGE_STATE,
  [MEDIA_TYPES.CHANNEL]: ACTION_TYPES.SET_CHANNEL_PLAYLISTS_MANAGE_STATE,
};

const musicAudioSelectedIds = new Set<number>();

export const initialState: GlobalState = {
  apiAddress: process.env.REACT_APP_API_ADDRESS,
  authToken: window.localStorage.getItem('authToken'),
  userEmail: window.localStorage.getItem('userEmail'),
  authRequestObjectRef: {},
  lastLoggedInPath: '/',

  content: {
    [MEDIA_TYPES.MUSIC_AUDIO]: {
      searchResult: [],
      selectedIds: musicAudioSelectedIds,
      searchInput: '',
      loading: false,
      error: null,
      editEntries: {},

      uploadFiles: [],
    },

    [MEDIA_TYPES.MUSIC_VIDEO]: {
      searchResult: [],
      selectedIds: musicAudioSelectedIds,
      searchInput: '',
      loading: false,
      error: null,
      editEntries: {},

      uploadFiles: [],
    },

    [MEDIA_TYPES.CHANNEL]: {
      searchResult: [],
      selectedIds: musicAudioSelectedIds,
      searchInput: '',
      loading: false,
      error: null,
      editEntries: {},

      uploadFiles: [],
    },
  },

  preview: {
    visible: false,
    url: null,
  },

  playlists: {
    [MEDIA_TYPES.MUSIC_AUDIO]: {
      playlists: [],
      counter: 0,
      error: null,
      loading: false,
      editEntries: {},
      selectedIds: new Set(),
      filterText: '',
    },

    [MEDIA_TYPES.MUSIC_VIDEO]: {
      playlists: [],
      counter: 0,
      error: null,
      loading: false,
      editEntries: {},
      selectedIds: new Set(),
      filterText: '',
    },

    [MEDIA_TYPES.CHANNEL]: {
      playlists: [],
      counter: 0,
      error: null,
      loading: false,
      editEntries: {},
      selectedIds: new Set(),
      filterText: '',
    },
  },

  playlistManagement: {
    [MEDIA_TYPES.MUSIC_AUDIO]: {
      playlistId: null,
      tracks: [],
      selectedIds: new Set(),
      filterText: '',
      loading: false,
      error: null,
      duplicateCount: 0,
    },

    [MEDIA_TYPES.MUSIC_VIDEO]: {
      playlistId: null,
      tracks: [],
      selectedIds: new Set(),
      filterText: '',
      loading: false,
      error: null,
      duplicateCount: 0,
    },

    [MEDIA_TYPES.CHANNEL]: {
      playlistId: null,
      tracks: [],
      selectedIds: new Set(),
      filterText: '',
      loading: false,
      error: null,
      duplicateCount: 0,
    },
  },

  spotifyTokenData: spotifyService.readSpotifyToken(),

  scheduler: {
    selectedVenueId: venueService.getVenueId(),
    selectedZoneId: venueService.getZoneId(),

    scheduleError: null,
    scheduleLoading: false,
    scheduleTimeslots: null,
    initialScheduleTimeslots: null,
    scheduleId: null,

    now: new Date(),

    lastTimeslotStates: buildLastTimeslotState([], []),

    draggedItem: null,
    draggedItemType: null,

    catalogPlaylistConnection: {
      connection: null,
      error: null,
      loading: false,
    },
    catalogBucketConnection: {
      connection: null,
      error: null,
      loading: false,
    },
    libraryBucketConnection: {
      connection: null,
      error: null,
      loading: false,
    },
    libraryPlaylistConnection: {
      connection: null,
      error: null,
      loading: false,
    },
    listFilterText: '',

    selectedContentSource: LIST_SOURCE.LIBRARY,
    selectedContentType: LIST_TYPE.PLAYLIST,
  },

  venues: venueService.getVenues(),
};

const appReducer: Reducer<GlobalState> = (
  state = initialState,
  action
): GlobalState => {
  switch (action.type) {
    case ACTION_TYPES.SET_AUTH_DATA:
      const { userEmail = null, authToken = null } = action.authData || {};
      return { ...state, authToken, userEmail };
    case ACTION_TYPES.SET_AUTH_REF:
      return { ...state, authRequestObjectRef: action.authRequestObjectRef };
    case ACTION_TYPES.SET_LAST_PATH:
      return { ...state, lastLoggedInPath: action.lastLoggedInPath };
    case SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_AUDIO]:
      return {
        ...state,
        content: {
          ...state.content,
          musicAudio: {
            ...state.content.musicAudio,
            //Remove undefined properties
            ...removeUndefinedProperties(action.data),

            selectedIds:
              action.data.selectedIds === undefined
                ? state.content.musicAudio.selectedIds
                : new Set<number>(action.data.selectedIds),
          },
        },
      };
    case SET_CONTENT_STATE[MEDIA_TYPES.MUSIC_VIDEO]:
      return {
        ...state,
        content: {
          ...state.content,
          musicVideo: {
            ...state.content.musicVideo,
            //Remove undefined properties
            ...removeUndefinedProperties(action.data),

            selectedIds:
              action.data.selectedIds === undefined
                ? state.content.musicVideo.selectedIds
                : new Set<number>(action.data.selectedIds),
          },
        },
      };
    case SET_CONTENT_STATE[MEDIA_TYPES.CHANNEL]:
      return {
        ...state,
        content: {
          ...state.content,
          channel: {
            ...state.content.channel,
            ...removeUndefinedProperties(action.data),

            selectedIds:
              action.data.selectedIds === undefined
                ? state.content.channel.selectedIds
                : new Set<number>(action.data.selectedIds),
          },
        },
      };
    case ACTION_TYPES.SET_PREVIEW:
      return {
        ...state,
        preview: {
          ...action.data,
        },
      };
    case SET_PLAYLISTS_STATE[MEDIA_TYPES.MUSIC_AUDIO]:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          [MEDIA_TYPES.MUSIC_AUDIO]: {
            ...state.playlists[MEDIA_TYPES.MUSIC_AUDIO],
            ...removeUndefinedProperties(action.data),
          },
        },
      };
    case SET_PLAYLISTS_STATE[MEDIA_TYPES.MUSIC_VIDEO]:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          [MEDIA_TYPES.MUSIC_VIDEO]: {
            ...state.playlists[MEDIA_TYPES.MUSIC_VIDEO],
            ...removeUndefinedProperties(action.data),
          },
        },
      };
    case SET_PLAYLISTS_STATE[MEDIA_TYPES.CHANNEL]:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          [MEDIA_TYPES.CHANNEL]: {
            ...state.playlists[MEDIA_TYPES.CHANNEL],
            ...removeUndefinedProperties(action.data),
          },
        },
      };
    case SET_PLAYLISTS_MANAGE_STATE[MEDIA_TYPES.MUSIC_AUDIO]:
      return {
        ...state,
        playlistManagement: {
          ...state.playlistManagement,
          [MEDIA_TYPES.MUSIC_AUDIO]: {
            ...state.playlistManagement.musicAudio,
            ...removeUndefinedProperties(action.data),
          },
        },
      };
    case SET_PLAYLISTS_MANAGE_STATE[MEDIA_TYPES.MUSIC_VIDEO]:
      return {
        ...state,
        playlistManagement: {
          ...state.playlistManagement,
          [MEDIA_TYPES.MUSIC_VIDEO]: {
            ...state.playlistManagement.musicVideo,
            ...removeUndefinedProperties(action.data),
          },
        },
      };
    case SET_PLAYLISTS_MANAGE_STATE[MEDIA_TYPES.CHANNEL]:
      return {
        ...state,
        playlistManagement: {
          ...state.playlistManagement,
          [MEDIA_TYPES.CHANNEL]: {
            ...state.playlistManagement.channel,
            ...removeUndefinedProperties(action.data),
          },
        },
      };
    case ACTION_TYPES.SET_SPOTIFY_TOKEN_DATA:
      return {
        ...state,
        spotifyTokenData: action.spotifyTokenData,
      };
    case ACTION_TYPES.SET_VENUES:
      return {
        ...state,
        venues: action.data,
      };
    case ACTION_TYPES.SET_SCHEDULER:
      console.log('dispatch', action.data);
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          ...removeUndefinedProperties(action.data),
        },
      };
    case ACTION_TYPES.REMOVE_PLAYLIST_FROM_LIBRARY:
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          libraryPlaylistConnection: {
            ...state.scheduler.libraryPlaylistConnection,
            connection: state.scheduler.libraryPlaylistConnection
              .connection && {
              ...state.scheduler.libraryPlaylistConnection.connection,
              edges:
                state.scheduler.libraryPlaylistConnection.connection.edges.filter(
                  (edge) => edge.node.id !== action.data
                ),
            },
          },
        },
      };
    case ACTION_TYPES.REMOVE_BUCKET_FROM_LIBRARY:
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          libraryBucketConnection: {
            ...state.scheduler.libraryBucketConnection,
            connection: state.scheduler.libraryBucketConnection.connection && {
              ...state.scheduler.libraryBucketConnection.connection,
              edges:
                state.scheduler.libraryBucketConnection.connection.edges.filter(
                  (edge) => edge.node.id !== action.data
                ),
            },
          },
        },
      };
    default:
      return state;
  }
};

export const store = createStore(appReducer);

import { FormTextInput } from '../../forms';
import { useDispatch, useSelector } from 'react-redux';
import {
  SET_PLAYLISTS_MANAGE_STATE,
  SET_PLAYLISTS_STATE,
} from '../../../store/store';
import { fullPlaylistColumnResolver } from '../../../lib/columnResolvers';
import {
  ContentState,
  GlobalState,
  PlaylistObject,
  PlaylistState,
  PlaylistManagement,
} from '../../../lib/types';
import {
  DataGridPro as DataGrid,
  GridCellEditCommitParams,
  GridColDef,
  GridColumnResizeParams,
  GridSelectionModel,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { ComponentType, useCallback, useMemo, useState } from 'react';
import { PlayerOverlay } from '../../PreviewCell';
import { CellRedirect } from '../../buttons';
import { ContentBody, ContentHeader } from '../../wrappers';
import {
  MEDIA_TYPES,
  PLAYLISTS_LIMIT,
  PLAYLISTS_MANAGE_CHANNELS_PATH_PREFIX,
  PLAYLISTS_MANAGE_MUSIC_AUDIO_PATH_PREFIX,
  PLAYLISTS_MANAGE_MUSIC_VIDEO_PATH_PREFIX,
} from '../../../lib/constants';

const COLUMN_WIDTH_KEY = 'columnWidth';

export const MusicPlaylistSearch = (props: { mediaType: MEDIA_TYPES }) => {
  const dispatch = useDispatch();

  const { selectedIds, filterText, editEntries, playlists, error, loading } =
    useSelector<GlobalState, GlobalState['playlists'][MEDIA_TYPES]>(
      (state) => state['playlists'][props.mediaType]
    );

  const onRowSelectedModelCHange = useCallback(
    (m: GridSelectionModel) => {
      dispatch({
        type: SET_PLAYLISTS_STATE[props.mediaType],
        data: {
          selectedIds: new Set(m.map((e) => Number(e))),
        } as ContentState,
      });
    },
    [dispatch, props.mediaType]
  );

  const onEditCellChange = useCallback(
    (c: GridCellEditCommitParams) => {
      const newEditEntries = { ...editEntries };
      newEditEntries[c.id as number] = newEditEntries[c.id as number] || {};
      newEditEntries[c.id as number][c.field] = c.value;

      const newPlaylists = [...playlists];
      newPlaylists.forEach((playlist) => {
        if (playlist.id !== c.id) {
          return;
        }
        const key = c.field as keyof PlaylistObject;
        (playlist[key] as unknown as any) = c.value;
      });

      dispatch({
        type: SET_PLAYLISTS_STATE[props.mediaType],
        data: {
          editEntries: newEditEntries,
          playlists: newPlaylists,
        } as PlaylistState,
      });
    },
    [dispatch, editEntries, playlists, props.mediaType]
  );

  const columns: GridColDef[] = useMemo(() => {
    const cwStr = localStorage.getItem(COLUMN_WIDTH_KEY);
    const columnWidth = cwStr && JSON.parse(cwStr);
    return fullPlaylistColumnResolver.map((colResolver) => ({
      field: colResolver.field,
      headerName: colResolver.title,
      editable: colResolver.editable || false,
      type: colResolver.type || 'string',
      width:
        columnWidth && `${colResolver.field}` in columnWidth
          ? columnWidth[colResolver.field]
          : colResolver.flex || 100,
      // hideSortIcons: false,
      headerAlign: 'left',
      cellClassName: (params: any) => {
        if (editEntries[params.id]?.[params.field] != null) {
          return 'edited-cell';
        }
        return '';
      },
      valueGetter: (params) => {
        if (colResolver.prop) {
          return params.row[colResolver.prop];
        } else if (colResolver.resolve) {
          return colResolver.resolve(params.row as PlaylistObject);
        }
        throw new Error(
          `Column resolver ${colResolver.field} (${colResolver.title}) should have either .prop or .resolve`
        );
      },
    }));
  }, [editEntries]);

  const [confirmedText, setConfirmedText] = useState(filterText);

  const rows = useMemo(() => {
    const filteredPlaylists = playlists.filter((p) => {
      return p.name.toLowerCase().includes(confirmedText.toLowerCase());
    });

    return filteredPlaylists;
  }, [playlists, confirmedText]);

  const onColumnWidthChanges = useCallback(
    (column: GridColumnResizeParams) => {
      const cwStr = localStorage.getItem(COLUMN_WIDTH_KEY + props.mediaType);
      const columnWidth: { [key: string]: number } = cwStr
        ? JSON.parse(cwStr)
        : {};
      columnWidth[column.colDef.field] = column.width;
      localStorage.setItem(
        COLUMN_WIDTH_KEY + props.mediaType,
        JSON.stringify(columnWidth)
      );
    },
    [props.mediaType]
  );

  /**Since DataGrid updates selection model on each render, this workaround is used to prevent cyclic re-rendering */
  const ids = Array.from(selectedIds.values());
  const selectedRows = useMemo(() => {
    return ids;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids.join(',')]);

  const dataGridProps: typeof DataGrid extends ComponentType<infer T>
    ? T
    : unknown = useMemo(() => {
    return {
      checkboxSelection: true,
      columnBuffer: 50,
      rowCount: PLAYLISTS_LIMIT,
      // density: 'full',
      components: {
        Toolbar: GridToolbar,
      },
      rows,
      columnTypes: {
        tabLink: {
          renderCell: (params: any) => {
            const playlistId = params.row.id;

            return (
              <CellRedirect
                text="manage"
                onClick={() => {
                  dispatch({
                    type: SET_PLAYLISTS_MANAGE_STATE[props.mediaType],
                    data: { playlistId } as PlaylistManagement,
                  });
                }}
                to={`${
                  props.mediaType === MEDIA_TYPES.MUSIC_AUDIO
                    ? PLAYLISTS_MANAGE_MUSIC_AUDIO_PATH_PREFIX
                    : props.mediaType === MEDIA_TYPES.MUSIC_VIDEO
                    ? PLAYLISTS_MANAGE_MUSIC_VIDEO_PATH_PREFIX
                    : PLAYLISTS_MANAGE_CHANNELS_PATH_PREFIX
                }/${playlistId}`}
              />
            );
          },
        },
      },
      selectionModel: selectedRows,
      onCellEditCommit: onEditCellChange,
      onSelectionModelChange: onRowSelectedModelCHange,
      onColumnWidthChange: onColumnWidthChanges,
      columns,
      loading,
    };
  }, [
    rows,
    selectedRows,
    onEditCellChange,
    onRowSelectedModelCHange,
    onColumnWidthChanges,
    columns,
    loading,
    props.mediaType,
    dispatch,
  ]);

  return (
    <ContentBody>
      <ContentHeader>
        <div className="search-button">
          <FormTextInput
            id="searchAudioPlaylistText"
            type="text"
            inputValue={filterText}
            placeholder="... enter filter text and press ENTER"
            onChange={(text) => {
              dispatch({
                type: SET_PLAYLISTS_STATE[props.mediaType],
                data: { filterText: text || '' },
              });
              if (text === '') {
                setConfirmedText(text);
              }
            }}
            onSubmit={(text) => {
              setConfirmedText(text);
            }}
          />
        </div>
      </ContentHeader>

      {error && <div className="search-status-msg">Error: {error}</div>}

      <div style={{ height: '500px', maxHeight: '100%', width: '100%' }}>
        <DataGrid {...dataGridProps}></DataGrid>
      </div>
      <PlayerOverlay />
    </ContentBody>
  );
};

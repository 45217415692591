import { useDispatch, useSelector } from 'react-redux';
import {
  EntryState,
  GlobalState,
  PlaylistObject,
  TimeslotEntry,
} from '../../lib/types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  Calendar,
  CalendarProps,
  dateFnsLocalizer,
  Event,
  stringOrDate,
} from 'react-big-calendar';
import withDragAndDrop, {
  withDragAndDropProps,
} from 'react-big-calendar/lib/addons/dragAndDrop';
import enUS from 'date-fns/locale/en-US';
import * as dfns from 'date-fns';
import { ComponentType, useMemo, useState } from 'react';
import { ContentHeader, PopupWindow } from '../wrappers';
import { Box, Checkbox, Dialog, FormControlLabel } from '@mui/material';
import { TextSolidButton } from '../buttons';
import { ACTION_TYPES } from '../../store/store';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DATE_FORMAT, TIME_FORMAT } from '../../lib/constants';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { buildLastTimeslotState } from '../../lib/scheduleUtils';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  ...dfns,
  locales,
});

export type CalendarEvent = Event & { timeslot: TimeslotEntry };

// Copypasted from React-big-calendar
interface DragAndDropCalendarProps<
  TEvent extends object = Event,
  TResource extends object = object
> extends CalendarProps<TEvent, TResource>,
    withDragAndDropProps<TEvent, TResource> {}

// This type coercion is needed to prevent errors
const DragAndDropCalendar = withDragAndDrop(
  Calendar as unknown as any
) as unknown as ComponentType<DragAndDropCalendarProps<CalendarEvent, object>>;

export const Schedule = () => {
  const {
    scheduleTimeslots,
    draggedItem,
    draggedItemType,
    lastTimeslotStates,
    now,
  } = useSelector((state: GlobalState) => state.scheduler);
  const dispatch = useDispatch();

  const [newTimeslot, setNewTimeslot] = useState<TimeslotEntry | null>(null);
  const [editTimeslot, setEditTimeslot] = useState<TimeslotEntry | null>(null);

  const events = useMemo(() => {
    const list: CalendarEvent[] = [];

    if (!scheduleTimeslots) {
      return list;
    }

    scheduleTimeslots.forEach((ts) => {
      if (ts.state === EntryState.DELETED) {
        return;
      }

      const entry = ts.bucket || ts.playlist;

      if (!entry) {
        return;
      }

      list.push({
        title: entry.name,
        start: dfns.parseISO(`${ts.day}T${ts.from}`),
        end: dfns.parseISO(`${ts.day}T${ts.to}`),
        timeslot: ts,
      });
    });

    return list;
  }, [scheduleTimeslots]);

  const onMove: any = (e: {
    event: CalendarEvent;
    start: stringOrDate;
    end: stringOrDate;
    isAllDay: boolean;
  }) => {
    const timeslot = e.event.timeslot;

    const day = dfns.format(dfns.startOfDay(new Date(e.start)), DATE_FORMAT);

    const from = dfns.format(new Date(e.start), TIME_FORMAT);
    let to = dfns.format(new Date(e.end), TIME_FORMAT);
    if (to < from) {
      to = dfns.format(dfns.endOfDay(new Date(e.start)), TIME_FORMAT);
    }

    const newTimeslots = [
      ...(scheduleTimeslots || []).filter((t) => t !== timeslot),
    ];

    const newTimeslot: TimeslotEntry = {
      ...timeslot,
      day,
      from,
      to,
      state:
        timeslot.state === EntryState.NEW ? EntryState.NEW : EntryState.CHANGED,
      originalTs: timeslot.originalTs || timeslot,
      repeatUntilDate: timeslot.repeatUntilDate,
    };

    // if day changed, timeslot needs to be recreated
    if (day !== timeslot.day) {
      newTimeslot.state = EntryState.NEW;

      if (timeslot.state !== EntryState.NEW) {
        const deletedTimeslot: TimeslotEntry = {
          ...timeslot,
          state: EntryState.DELETED,
          originalTs: timeslot.originalTs || timeslot,
          repeatUntilDate:
            timeslot.repeatUntilDate &&
            dfns.format(dfns.endOfWeek(new Date(e.end)), DATE_FORMAT),
        };

        newTimeslots.push(deletedTimeslot);
      }
    }

    newTimeslots.push(newTimeslot);

    dispatch({
      type: ACTION_TYPES.SET_SCHEDULER,
      data: {
        scheduleTimeslots: newTimeslots,
        lastTimeslotStates: buildLastTimeslotState(
          lastTimeslotStates,
          newTimeslots
        ),
      } as Partial<GlobalState['scheduler']>,
    });
  };

  const onDropFromOutside: any = useMemo(
    () => (e: Event) => {
      if (!draggedItem) {
        throw new Error('error in logic: draggedItem is null');
      }

      const time = e.start;

      if (!time) {
        throw new Error('error in logic: e.start is null');
      }

      setNewTimeslot({
        day: dfns.format(time, DATE_FORMAT),
        from: dfns.format(time, TIME_FORMAT),
        to: dfns.format(dfns.addMinutes(time, 60), TIME_FORMAT),
        bucket: (draggedItemType === 'bucket' && draggedItem) || undefined,
        playlist:
          (draggedItemType === 'playlist' && (draggedItem as PlaylistObject)) ||
          undefined,
        isRepeat: false,
        state: EntryState.NEW,
      });
    },
    [draggedItem, draggedItemType]
  );

  const eventStyleGetter = ((e: CalendarEvent) => {
    if (!e.timeslot) {
      // There is a bug when dragging an item over the top bar causes this handler
      return;
    }

    let backgroundColor = 'blue';

    switch (true) {
      case !!e.timeslot.bucket && e.timeslot.state === EntryState.CHANGED:
        backgroundColor = 'darkgreen';
        break;
      case !!e.timeslot.bucket && e.timeslot.state === EntryState.UNCHANGED:
        backgroundColor = 'green';
        break;
      case !!e.timeslot.playlist && e.timeslot.state === EntryState.CHANGED:
        backgroundColor = 'blue';
        break;
      case !!e.timeslot.playlist && e.timeslot.state === EntryState.UNCHANGED:
        backgroundColor = 'darkblue';
        break;
    }

    return {
      style: {
        backgroundColor,
      },
    };
  }) as unknown as (e: Event) => { style: { backgroundColor: string } };

  const timetable = (
    <div style={{ height: '90vh' }}>
      <DragAndDropCalendar
        localizer={localizer}
        events={events}
        selectable={true}
        onSelectEvent={(e) => {
          setEditTimeslot(e.timeslot);
        }}
        getNow={() => {
          const markedNow = now;
          Object.defineProperty(markedNow, 'initial', { value: true });

          return markedNow;
        }}
        onNavigate={(newDate) => {
          let now = newDate;
          if (
            Object.getOwnPropertyDescriptor(newDate, 'initial')?.value === true
          ) {
            now = new Date();
          }

          dispatch({
            type: ACTION_TYPES.SET_SCHEDULER,
            data: {
              now,
            } as Partial<GlobalState['scheduler']>,
          });
        }}
        onView={() => {}} // prevent warning in console
        dragFromOutsideItem={() => {
          return (e: Event) => {
            return new Date();
          };
        }}
        onEventDrop={onMove}
        onDropFromOutside={onDropFromOutside}
        view="week"
        views={['week']}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );

  return (
    <div>
      {/* BODY */}
      {scheduleTimeslots && timetable}
      {/* POPUPS */}
      {/* drop */}
      <Dialog
        open={!!newTimeslot}
        onBackdropClick={() => setNewTimeslot(null)}
        onClose={() => setNewTimeslot(null)}
      >
        {newTimeslot && (
          <EditTimeslot
            timeslot={newTimeslot}
            onChange={(oldTs, newTs) => {
              if (newTs) {
                const newTimeslots = [
                  ...(scheduleTimeslots || []),
                  { ...newTs, state: EntryState.NEW },
                ];

                dispatch({
                  type: ACTION_TYPES.SET_SCHEDULER,
                  data: {
                    draggedItem: null,
                    draggedItemType: null,
                    scheduleTimeslots: newTimeslots,
                    lastTimeslotStates: buildLastTimeslotState(
                      lastTimeslotStates,
                      newTimeslots
                    ),
                  } as GlobalState['scheduler'],
                });
              }

              setNewTimeslot(null);
            }}
          />
        )}
      </Dialog>

      {/* edit */}
      <Dialog
        open={!!editTimeslot}
        onBackdropClick={() => setEditTimeslot(null)}
        onClose={() => setEditTimeslot(null)}
      >
        {editTimeslot && (
          <EditTimeslot
            timeslot={editTimeslot}
            onChange={(oldTs, newTs) => {
              const otherTimeslots = (scheduleTimeslots || []).filter(
                (ts) => ts !== oldTs
              );

              if (!newTs) {
                otherTimeslots.push({
                  ...oldTs,
                  state: EntryState.DELETED,
                  originalTs: oldTs.originalTs || oldTs,
                });

                dispatch({
                  type: ACTION_TYPES.SET_SCHEDULER,
                  data: {
                    scheduleTimeslots: otherTimeslots,
                    lastTimeslotStates: buildLastTimeslotState(
                      lastTimeslotStates,
                      otherTimeslots
                    ),
                  } as GlobalState['scheduler'],
                });
              } else {
                const newTimeslot: TimeslotEntry = {
                  ...newTs,
                  state:
                    oldTs.state === EntryState.NEW
                      ? oldTs.state
                      : EntryState.CHANGED,
                  id: oldTs.id,
                  originalTs: oldTs.originalTs || oldTs,
                };

                const newTimeslots = [...otherTimeslots, newTimeslot];

                dispatch({
                  type: ACTION_TYPES.SET_SCHEDULER,
                  data: {
                    scheduleTimeslots: newTimeslots,
                    lastTimeslotStates: buildLastTimeslotState(
                      lastTimeslotStates,
                      newTimeslots
                    ),
                  } as GlobalState['scheduler'],
                });
              }

              setEditTimeslot(null);
            }}
          />
        )}
      </Dialog>
    </div>
  );
};

const EditTimeslot = (props: {
  timeslot: TimeslotEntry;
  onChange: (old: TimeslotEntry, result: TimeslotEntry | null) => void;
}) => {
  const [newTimeslot, setNewTimeslot] = useState<TimeslotEntry>(props.timeslot);
  const [valid, setValid] = useState<boolean>(true);
  const [repeatValid, setRepeatValid] = useState<boolean>(true);
  const [repeat, setRepeat] = useState(!!props.timeslot.repeatUntilDate);

  return (
    <PopupWindow>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box display="flex" flexDirection="column" gap="5px">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format={DATE_FORMAT}
            margin="normal"
            id="date-picker-date"
            label="Date"
            value={dfns.parse(newTimeslot.day, DATE_FORMAT, new Date())}
            onChange={(date) => {
              if (!date) return;

              setNewTimeslot({
                ...newTimeslot,
                day: dfns.format(new Date(date), DATE_FORMAT),
              });
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardTimePicker
            margin="normal"
            id="time-picker-from"
            label="From"
            value={dfns.parse(newTimeslot.from, TIME_FORMAT, new Date())}
            onChange={(date) => {
              if (!date) return;

              const from = dfns.format(new Date(date), TIME_FORMAT);

              setNewTimeslot({
                ...newTimeslot,
                from,
              });

              setValid(newTimeslot.to > from);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
          <KeyboardTimePicker
            margin="normal"
            id="time-picker-to"
            label="To"
            value={dfns.parse(newTimeslot.to, TIME_FORMAT, new Date())}
            onChange={(date) => {
              if (!date) return;

              const to = dfns.format(new Date(date), TIME_FORMAT);
              setNewTimeslot({
                ...newTimeslot,
                to,
              });

              setValid(to > newTimeslot.from);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            error={!valid}
            helperText={!valid && 'To should be > From'}
          />
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={repeat}
              onChange={(e) => {
                setNewTimeslot({
                  ...newTimeslot,
                  repeatUntilDate: e.target.checked
                    ? dfns.format(
                        dfns.addDays(
                          dfns.parse(newTimeslot.day, DATE_FORMAT, new Date()),
                          7
                        ),
                        DATE_FORMAT
                      )
                    : undefined,
                });

                setRepeat(e.target.checked);
              }}
            />
          }
          label="Repeat till"
        />
        {repeat && (
          <Box>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format={DATE_FORMAT}
              margin="normal"
              id="date-picker-repeat"
              label="Repeat till"
              value={dfns.parse(
                newTimeslot.repeatUntilDate!,
                DATE_FORMAT,
                new Date()
              )}
              onChange={(date) => {
                if (!date) return;

                const repeatUntilDate = dfns.format(
                  new Date(date),
                  DATE_FORMAT
                );

                setNewTimeslot({
                  ...newTimeslot,
                  repeatUntilDate: repeatUntilDate,
                });

                setRepeatValid(repeatUntilDate > newTimeslot.day);
              }}
              error={!repeatValid}
            />
            {!repeatValid && <Box color="red">{'Should be > Date'}</Box>}
          </Box>
        )}

        <ContentHeader>
          <TextSolidButton
            text="Apply"
            active={valid}
            onClick={() => {
              props.onChange(props.timeslot, newTimeslot);
            }}
          />
          <TextSolidButton
            text="Remove"
            onClick={() => {
              props.onChange(props.timeslot, null);
            }}
          />
        </ContentHeader>
      </MuiPickersUtilsProvider>
    </PopupWindow>
  );
};

import { LoadingAnimation } from '../../LoadingAnimation';

export const SpotifyPlaylistComponent = (props: {
  name: string;
  trackCount: number;
  imageUrl?: string;
  selected: boolean;
  loading?: boolean;
  onClick: Function;
}) => {
  return (
    <div
      style={{
        padding: '20px',
        position: 'relative',
      }}
    >
      <img
        onClick={() => props.onClick()}
        style={{
          objectFit: 'cover',
          borderRadius: '10px',
          cursor: 'pointer',
          filter: props.selected ? 'blur(3px)' : undefined,
        }}
        width="150px"
        height="150px"
        src={props.imageUrl}
        alt={`Playlist: ${props.name}`}
      />
      {props.selected && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            top: '70px',
            left: '60px',
            userSelect: 'none',
            cursor: 'pointer',
          }}
          onClick={() => props.onClick()}
        >
          <LoadingAnimation
            animate={!!props.loading}
            color={props.loading ? 'cyan' : 'orange'}
            sizePx={50}
          />
        </div>
      )}
      <div style={{ width: '150px' }}>{props.name}</div>
      <div>{props.trackCount} tracks</div>
    </div>
  );
};

import { LoginForm } from './LoginForm';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { ACTION_TYPES } from '../store/store';
import { authenticateUser } from '../lib/auth';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingAnimation } from './LoadingAnimation';
import { GlobalState } from '../lib/types';

export const Login = () => {
  const userEmail = useSelector((state: GlobalState) => state.userEmail);
  const authToken = useSelector((state: GlobalState) => state.authToken);
  let history = useHistory();
  const dispatch = useDispatch();
  const store = useStore<GlobalState>();
  const [err, setErr] = useState<string | null>(null);
  const [status, setStatusMessage] = useState<string>('Login to Audalize');
  const [loading, setLoading] = useState(false);

  const loginWindow = (
    <div className="login-container">
      <div style={{ paddingBottom: '30px' }}>{status}</div>
      <LoginForm
        onSubmit={async ({ email, password }) => {
          const authRequestObjectRef = store.getState().authRequestObjectRef;

          try {
            dispatch({
              type: ACTION_TYPES.SET_AUTH_REF,
              authRequestObjectRef,
            });
            setLoading(true);
            const authToken = await authenticateUser(email, password);

            if (
              authRequestObjectRef !== store.getState().authRequestObjectRef
            ) {
              console.error('Reponse for previous input. Ignore it');
              return;
            }

            setLoading(false);
            setStatusMessage('Authenticated!');
            dispatch({
              type: ACTION_TYPES.SET_AUTH_DATA,
              authData: { authToken, userEmail: email },
            });

            window.localStorage.setItem('authToken', authToken);
            window.localStorage.setItem('userEmail', email);

            let redirectPath = store.getState().lastLoggedInPath;
            if ((redirectPath = '/login')) {
              redirectPath = '/';
            }
            history.push(redirectPath);
          } catch (err) {
            if (
              authRequestObjectRef !== store.getState().authRequestObjectRef
            ) {
              console.error('Error for previous input', err);
              return;
            }
            console.error(`Cannot authenticate: ${err}`);
            setLoading(false);
            setErr(`Login failed`);
          }
        }}
      />
      <div style={{ paddingBottom: '30px' }}>{err && <div>{err}</div>}</div>
      <div style={{ paddingBottom: '30px' }}>
        <LoadingAnimation color="black" animate={loading} sizePx={100} />
      </div>
    </div>
  );

  const alreadyLoggenInWindow = (
    <div className="login-container">
      <div> Already logged in as {userEmail}</div>

      <div>
        <button onClick={() => history.push('/')}>Go to main</button>
      </div>
    </div>
  );

  return authToken ? alreadyLoggenInWindow : loginWindow;
};

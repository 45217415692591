import { gql } from '@apollo/client';
import { TRACKS_IN_PLAYLIST_LIMIT } from './constants';

const playlistFragment = `id
name
generalGenre
mediaType
isCore
isActive
colourCode
createdBy {
  userLogin
}
trackCount
duration
logoImageUrl
createdAt`;

const bucketFragment = `id
name
colourCode
createdBy {
	userLogin
}
trackCount
duration
createdAt`;

export const loadVenuePlaylistQuery = gql`
  query ($venueId: Int!) {
    venue(id: $venueId) {
      name
      playlists {${playlistFragment}}
    }
  }
`;

export const loadVenueVideoPlaylistQuery = gql`
  query ($venueId: Int!) {
    venue(id: $venueId) {
      name
      videoPlaylists {${playlistFragment}}
    }
  }
`;

export const loadVenueChannelPlaylistQuery = gql`
  query ($venueId: Int!) {
    venue(id: $venueId) {
      name
      channelPlaylists {${playlistFragment}}
    }
  }
`;

export const TrackSegment = `
id
name
artist {
 name
}
 releaseYear
isrc
duration
contentSupplier {
 name
}
duration
genre {
 name
}
isBlacklisted
label
tempo
album {
  id
  name
}
`;

export const VideoTrackSegment = `
id
name
artist {
 name
}
 releaseYear
isrc
duration
contentSupplier {
 name
}
duration
genre {
 name
}
isBlacklisted
label
tempo
album {
  id
  name
}
`;

export const searchTrackMutation = gql`
  mutation($searchText: String!, $offset: Int!, $limit: Int!) {
    createCatalogSearch(
      text: $searchText
      scope: TRACK
      offset: $offset
      limit: $limit
      allowBlacklisted: true
    ) {
      isComplete
      tracks {
        ${TrackSegment}
      }
      meta {
        tracks {
          found
          offset
          limit
        }
      }
    }
  }
`;

export const searchVideoTrackMutation = gql`
  mutation($searchText: String!, $offset: Int!, $limit: Int!) {
    createCatalogSearch(
      text: $searchText
      scope: VIDEO_TRACK
      offset: $offset
      limit: $limit
      allowBlacklisted: true
    ) {
      isComplete
      videoTracks {
        ${VideoTrackSegment}
      }
      meta {
        videoTracks {
          found
          offset
          limit
        }
      }
    }
  }
`;

export const searchChannelTrackMutation = gql`
  mutation($searchText: String!, $offset: Int!, $limit: Int!) {
    createCatalogSearch(
      text: $searchText
      scope: CHANNEL_TRACK
      offset: $offset
      limit: $limit
      allowBlacklisted: true
    ) {
      isComplete
      channelTracks {
        ${VideoTrackSegment}
      }
      meta {
        channelTracks {
          found
          offset
          limit
        }
      }
    }
  }
`;

export const searchLastAudioTrackQuery = gql`
  query ($limit: Int!) {
    lastUploadedTracks(limit: $limit, mediaType: MUSIC) {
      ${TrackSegment}
    }
  }
`;

export const searchLastVideoTrackQuery = gql`
  query ($limit: Int!) {
    lastUploadedTracks(limit: $limit, mediaType: VIDEO) {
      ${VideoTrackSegment}
    }
  }
`;

export const searchLastChannelTrackQuery = gql`
  query ($limit: Int!) {
    lastUploadedTracks(limit: $limit, mediaType: CHANNEL) {
      ${VideoTrackSegment}
    }
  }
`;

export const addTrackToPlaylistMutation = gql`
  mutation ($trackIds: [Int!]!, $playlistId: Int!) {
    addTracksToPlaylist(trackIds: $trackIds, playlistId: $playlistId)
  }
`;

export const createPlaylistMutation = gql`
  mutation ($name: String!, $venueId: Int!, $mediaType: MediaType) {
    createPlaylist(name: $name, venueId: $venueId, mediaType: $mediaType) {${playlistFragment}}
  }
`;

export const deleteS3TracksMutation = gql`
  mutation ($id: Int!) {
    deleteS3Track(id: $id)
  }
`;

export const deletePlaylists = gql`
  mutation ($ids: [Int!]!) {
    deletePlaylists(ids: $ids)
  }
`;

export const setBlacklistedMutation = gql`
  mutation (
    $trackId: Int!
    $blacklisted: Boolean!
    $removeFromQueues: Boolean
  ) {
    setTrackManualBlacklistState(
      trackId: $trackId
      blacklisted: $blacklisted
      removeFromQueues: $removeFromQueues
    )
  }
`;

export const editTrackAttributesMutation = gql`
  mutation ($id: Int!, $values:TrackAttributesInput! ){
  editTrackAttributes(id:$id, values:$values) {
    ${TrackSegment}  
  }
}
`;

export const updatePlaylist = gql`
  mutation ($id: Int!, $isCore: Boolean!, $isActive: Boolean!, $name: String) {
    updatePlaylist(
      id: $id
      values: { isCore: $isCore, isActive: $isActive, name: $name }
    ) {${playlistFragment}}
  }
`;

export const getTrackContentUrlQuery = gql`
  query ($id: Int!) {
    track(id: $id) {
      id
      name
      processedContent(normProcessConfig: LUFS_V2) {
        contentUrl
      }
    }
  }
`;

export const getVideoTrackContentUrlQuery = gql`
  query ($id: Int!) {
    track(id: $id) {
      id
      contentUrl
      name
    }
  }
`;
export const playlistTracksQuery = gql`
  query ($id: Int!) {
  	playlist(id: $id) {
  		id
  		tracks(first: ${TRACKS_IN_PLAYLIST_LIMIT}) {
  			${TrackSegment}
  		}
  	}
  }
  `;

export const removeTracksFromPlaylistMutation = gql`
  mutation ($trackIds: [Int!]!, $playlistId: Int!) {
    removeTracksFromPlaylist(trackIds: $trackIds, playlistId: $playlistId)
  }
`;

export const createSpotifyPlaylistImportMutation = gql`
  mutation ($venueId: Int!, $name: String!, $spotifyPlaylistId: String!) {
    createSpotifyPlaylistImport(
      venueId: $venueId
      name: $name
      spotifyPlaylistId: $spotifyPlaylistId
    ) {
      id
    }
  }
`;

export const addTracksToSpotifyPlaylistImportMutation = gql`
  mutation (
    $spotifyPlaylistImportId: Int!
    $tracks: [SpotifyPlaylistImportTrackMatchInput!]!
  ) {
    addTracksToSpotifyPlaylistImport(
      tracks: $tracks
      spotifyPlaylistImportId: $spotifyPlaylistImportId
    ) {
      id
    }
  }
`;

export const createPlaylistFromSpotifyPlaylistImportMutation = gql`
  mutation ($spotifyPlaylistImportId: Int!) {
    createPlaylistFromSpotifyPlaylistImport(
      spotifyPlaylistImportId: $spotifyPlaylistImportId
    ) {
      ${playlistFragment}
    }
  }
`;

export const matchSpotifyPlaylistTracksMutation = gql`
  mutation ($tracks: [SpotifyPlaylistImportTrackMatchInput!]!) {
    matchSpotifyPlaylistTracks(tracks: $tracks) {
      firstMatchingTrack {
        id
      }
    }
  }
`;

export const deduplicateTracksInPlaylistMutation = gql`
  mutation ($playlistId: Int!) {
    deduplicateTracksInPlaylist(playlistId: $playlistId)
  }
`;

export const venuesQuery = gql`
  {
    venues {
      id
      name
      bannerImageUrl
      zones {
        id
        name
        mediaType
      }
    }
  }
`;

const bucketOrPlaylistSegment = `
id
name
colourCode
`;

const timeslotSegment = `
__typename
... on Timeslot {
  id
  day
  from
  to
  repeatUntilDate
  playlist {
    ${bucketOrPlaylistSegment}
  }
  bucket {
    ${bucketOrPlaylistSegment}
  }
}
... on RepeatedTimeslot {
  repeatOf {
  id
 } 
  day
  from
  to
  repeatUntilDate
  playlist {
    ${bucketOrPlaylistSegment}
  }
  bucket {
    ${bucketOrPlaylistSegment}
  }
}`;

export const zoneRunsheetQuery = gql`
  query ($zoneId: Int!, $from: Date!, $to: Date!) {
    zone(id: $zoneId) {
      id
      name
      schedule {
        id
        timeslots(from: $from, to: $to) {
          ${timeslotSegment}
        }
      }
    }
  }
`;

export const scheduleTimeslotsQuery = gql`
  query ($scheduleId: Int!, $from: Date!, $to: Date!) {
    schedule(id: $scheduleId) {
      id
      timeslots(from: $from, to: $to) {
        ${timeslotSegment}
      }
    }
  }
`;

export const playlistConnectionQuery = gql`
  query ($venueId: Int!, $first: Int!, $mediaType: MediaType!, $after: Cursor) {
    playlistsConnection(
      venueId: $venueId
      first: $first
      mediaType: $mediaType
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ${playlistFragment}
        }
      }
    }
  }
`;

export const bucketConnectionQuery = gql`
  query ($venueId: Int!, $first: Int!, $after: Cursor) {
    bucketsConnection(
      venueId: $venueId
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          __typename
          ${bucketFragment}
        }
      }
    }
  }
`;

export const createTimeslotMutation = gql`
  mutation (
    $scheduleId: Int!
    $day: Date!
    $from: Time!
    $to: Time!
    $playlistId: Int
    $bucketId: Int
    $repeatUntilDate: Date
    $deleteOverlapped: Boolean
  ) {
    createTimeslot(
      scheduleId: $scheduleId
      day: $day
      from: $from
      to: $to
      playlistId: $playlistId
      bucketId: $bucketId
      repeatUntilDate: $repeatUntilDate
      deleteOverlapped: $deleteOverlapped
    ) {
      id
    }
  }
`;

export const updateTimeslotMutation = gql`
  mutation (
    $id: Int!
    $values: TimeslotInput!
    $deleteOverlapped: Boolean
    $freezeTimeslotsInAWeek: Boolean
  ) {
    updateTimeslot(
      id: $id
      values: $values
      deleteOverlapped: $deleteOverlapped
      freezeTimeslotsInAWeek: $freezeTimeslotsInAWeek
    ) {
      id
    }
  }
`;

export const deleteTimeslotMutation = gql`
  mutation ($id: Int!, $includingRepeats: Boolean) {
    deleteTimeslot(id: $id, includingRepeats: $includingRepeats)
  }
`;

export const createScheduleMutation = gql`
  mutation ($venueId: Int!) {
    createSchedule(venueId: $venueId) {
      id
    }
  }
`;

export const cloneScheduleMutation = gql`
  mutation ($id: Int!, $toVenueId: Int) {
    cloneSchedule(id: $id, toVenueId: $toVenueId) {
      id
    }
  }
`;

export const updateScheduleMutation = gql`
  mutation ($id: Int!, $isDraft: Boolean!) {
    updateSchedule(id: $id, values: { isDraft: $isDraft }) {
      id
    }
  }
`;

export const updateZoneMutation = gql`
  mutation ($zoneId: Int!, $scheduleId: Int!) {
    updateZone(zoneId: $zoneId, values: { scheduleId: $scheduleId }) {
      id
    }
  }
`;

export const backupScheduleMutation = gql`
  mutation ($id: Int!, $name: String) {
    cloneSchedule(id: $id, backupName: $name) {
      id
    }
  }
`;

export const venueScheduleBackupsQuery = gql`
  query ($venueId: Int!) {
    venue(id: $venueId) {
      scheduleBackups {
        id
        name
        createdAt
      }
    }
  }
`;

export const deleteScheduleMutation = gql`
  mutation ($id: Int!) {
    deleteSchedule(id: $id)
  }
`;

export const cloneZoneToNewVenueMutation = gql`
  mutation ($zoneId: Int!, $venueName: String!) {
    cloneZoneToNewVenue(zoneId: $zoneId, venueName: $venueName) {
      id
      name
      venue {
        id
        name
        bannerImageUrl
        zones {
          id
          name
          mediaType
        }
      }
    }
  }
`;

export const removePlaylistMutation = gql`
  mutation ($id: Int!) {
    removePlaylist(id: $id)
  }
`;

export const removeBucketMutation = gql`
  mutation ($id: Int!) {
    removeBucket(id: $id)
  }
`;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { GlobalState } from '../lib/types';
import { ACTION_TYPES } from '../store/store';
import { RegularTextButton } from './buttons';

export const Header = () => {
  const userEmail = useSelector((state: GlobalState) => state.userEmail);
  const { pathname } = useLocation();
  const [path, setPath] = useState('pathname');
  const dispatch = useDispatch();

  useEffect(() => {
    setPath(pathname);
  }, [pathname]);

  return (
    <div className="header">
      <div>
        <img src="/AudalizeCMS.png" alt="AudalizeCMS" />
      </div>

      {userEmail && (
        <div className="user-info">
          <div>Logged in as: {userEmail}</div>
          <RegularTextButton
            text="Logout"
            active={true}
            onClick={() => {
              dispatch({
                type: ACTION_TYPES.SET_LAST_PATH,
                lastLoggedInPath: path,
              });
              window.localStorage.removeItem('authToken');
              window.localStorage.removeItem('userEmail');
              dispatch({
                type: ACTION_TYPES.SET_AUTH_DATA,
                authData: null,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

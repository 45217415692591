import axios from 'axios';
import {
  SPOTIFY_API_URL,
  SPOTIFY_AUTH_URL,
  SPOTIFY_CALLBACK_PATH,
  SPOTIFY_CLIENT_ID,
  SPOTIFY_CLIENT_SECRET,
} from '../lib/constants';
import { SpotifyTokenData } from '../lib/types';
import { generateRandomString, getChallenge } from '../lib/utils';

export type SpotifyPlaylist = {
  collaborative: boolean;
  description: string;
  external_urls: { spotify: string };
  href: string;
  id: string;
  images: {
    height: number;
    width: number;
    url: string;
  }[];
  name: string;
  owner: { display_name: string; href: string; id: string; type: string };
  primary_color: null;
  public: false;
  snapshot_id: string;
  tracks: { href: string; total: 6 };
  type: string;
  uri: string;
};

export type SpotifyPlaylistTracksResponse = {
  href: string;
  items: {
    track: {
      duration_ms: number;
      explicit: boolean;
      external_ids: {
        isrc: string;
      };
      external_urls: {
        spotify: string;
      };
      href: string;
      id: string;
      name: string;
      popularity: number;
      preview_url: string;
      track: boolean;
      track_number: number;
      uri: string;
      album: {
        id: string;
        name: string;
      };
      artists: {
        id: string;
        name: string;
      }[];
    };
  }[];
  limit: number;
  next: any;
  offset: number;
  previous: any;
  total: number;
};

export type SpotifyPlaylistsResponse = {
  href: string;
  items: SpotifyPlaylist[];
  limit: number;
  next: string;
  offset: number;
  previous: string;
  total: number;
};

class SpotifyService {
  refreshTimeout?: NodeJS.Timeout;

  constructor() {
    this.setRefreshJob();
  }

  private setRefreshJob() {
    this.refreshTimeout && clearTimeout(this.refreshTimeout);

    const tokenData = this.readSpotifyToken();

    if (!tokenData) {
      return;
    }

    const { expiresAt } = tokenData;

    const afterMs = Math.max(expiresAt.getTime() - Date.now() - 1000 * 60, 0);

    // Refresh a minute before the expiration time
    const timeout = setTimeout(() => {
      this.refreshToken();
    }, afterMs);

    console.info(
      `Refresh Spotify Token in ${(afterMs / 1000).toFixed(0)} seconds`
    );

    this.refreshTimeout = timeout;
  }

  private async refreshToken() {
    console.info('Refreshing Spotify Access Token');

    const { refreshToken } = this.readSpotifyToken() || {};

    if (!refreshToken) {
      return;
    }

    const bodyParams = new URLSearchParams();
    bodyParams.append('grant_type', 'refresh_token');
    bodyParams.append('refresh_token', refreshToken);
    bodyParams.append('client_id', SPOTIFY_CLIENT_ID);
    const body = bodyParams.toString();

    const response = await axios.post<{
      access_token: string;
      expires_in: string;
      refresh_token: string;
    }>(`${SPOTIFY_AUTH_URL}/api/token`, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const { access_token, expires_in, refresh_token } = response.data;

    this.saveSpotifyToken({
      accessToken: access_token,
      expiresIn: expires_in,
      refreshToken: refresh_token,
    });

    console.info('Refreshed Spotify Access Token');
    this.setRefreshJob();
  }

  async authorizeSpotify(): Promise<SpotifyTokenData> {
    const url = await this.prepareAuthorizeUrl();

    const authPopup = window.open(
      url,
      'Login Spotify',
      'width=full,height=full'
    );

    return await new Promise((res, rej) => {
      var timer = setInterval(() => {
        const spotifyTokenData = this.readSpotifyToken();

        if (authPopup?.closed) {
          clearInterval(timer);

          if (!spotifyTokenData) {
            return rej('Authentication errored');
          }
        }

        if (spotifyTokenData) {
          clearInterval(timer);
          this.setRefreshJob();
          return res(spotifyTokenData);
        }
      }, 100);
    });
  }

  async requestAccessToken(code: string, state: string) {
    const codeVerifier = this.readSpotifyCodeVerifier();
    const savedState = this.readSpotifyOAuthState();

    if (state !== savedState) {
      throw new Error(`States do not match`);
    }

    const bodyParams = new URLSearchParams();
    bodyParams.append('grant_type', 'authorization_code');
    bodyParams.append('code', code || '');
    bodyParams.append(
      'redirect_uri',
      window.location.origin + '/' + SPOTIFY_CALLBACK_PATH
    );
    bodyParams.append('client_id', SPOTIFY_CLIENT_ID);
    bodyParams.append('code_verifier', codeVerifier!);
    const body = bodyParams.toString();

    const encodedClient = window.btoa(
      `${SPOTIFY_CLIENT_ID}:${SPOTIFY_CLIENT_SECRET}`
    );

    const result = await axios.post<{
      access_token: string;
      refresh_token: string;
      expires_in: string;
    }>(`${SPOTIFY_AUTH_URL}/api/token`, body, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${encodedClient}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const tokenData = {
      accessToken: result.data.access_token,
      refreshToken: result.data.refresh_token,
      expiresIn: result.data.expires_in,
    };

    this.saveSpotifyToken(tokenData);
  }

  readSpotifyToken(): SpotifyTokenData | null {
    const spotifyAccessToken = localStorage.getItem('spotifyAccessToken');
    const spotifyRefreshToken = localStorage.getItem('spotifyRefreshToken');
    const spotifyTokenExpiresAt = localStorage.getItem('spotifyTokenExpiresAt');

    if (!spotifyAccessToken || !spotifyRefreshToken || !spotifyTokenExpiresAt) {
      return null;
    }

    const expiresAt = new Date(spotifyTokenExpiresAt);

    return {
      accessToken: spotifyAccessToken,
      refreshToken: spotifyRefreshToken,
      expiresAt,
    };
  }

  private saveSpotifyToken(params: {
    accessToken: string;
    expiresIn: string;
    refreshToken: string;
  }) {
    const expiresAt = new Date(Date.now() + Number(params.expiresIn) * 1000);

    localStorage.setItem('spotifyAccessToken', params.accessToken);
    localStorage.setItem('spotifyRefreshToken', params.refreshToken);
    localStorage.setItem('spotifyTokenExpiresAt', expiresAt.toISOString());
  }

  private saveSpotifyOAuthState(state: string) {
    localStorage.setItem('spotifyState', state);
  }

  private readSpotifyOAuthState(): string | null {
    return localStorage.getItem('spotifyState');
  }

  private saveSpotifyCodeVerifier(verifier: string) {
    localStorage.setItem('spotifyCodeVerifier', verifier);
  }

  private readSpotifyCodeVerifier(): string | null {
    return localStorage.getItem('spotifyCodeVerifier');
  }

  private async prepareAuthorizeUrl(): Promise<string> {
    const redirectUrl = window.location.origin + '/' + SPOTIFY_CALLBACK_PATH;
    const scopes = ['playlist-read-private'];

    const codeVerifier = generateRandomString(128);
    const codeChallenges = await getChallenge(codeVerifier);
    const state = generateRandomString(24);

    spotifyService.saveSpotifyOAuthState(state);
    spotifyService.saveSpotifyCodeVerifier(codeVerifier);

    const url = new URL(`${SPOTIFY_AUTH_URL}/authorize`);
    url.searchParams.append('client_id', SPOTIFY_CLIENT_ID);
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('redirect_uri', redirectUrl);
    url.searchParams.append('scope', scopes.join(' '));
    url.searchParams.append('state', state);
    url.searchParams.append('code_challenge_method', 'S256');
    url.searchParams.append('code_challenge', codeChallenges);

    return url.toString();
  }

  async getUserPlaylists(): Promise<SpotifyPlaylistsResponse> {
    const tokenData = spotifyService.readSpotifyToken();

    if (!tokenData) {
      throw new Error('No spotify token');
    }

    const limit = 50;

    let initialUrl = new URL(`${SPOTIFY_API_URL}/v1/me/playlists`);
    initialUrl.searchParams.append('limit', limit.toString());

    let result: SpotifyPlaylistsResponse | null = null;

    let url = initialUrl.toString();

    while (url) {
      const res = await axios.get<SpotifyPlaylistsResponse>(url, {
        headers: {
          Authorization: 'Bearer ' + tokenData?.accessToken,
        },
      });

      if (!result) {
        result = res.data;
      } else {
        result.items = result.items.concat(res.data.items);
      }

      url = res.data.next;
    }

    return result!;
  }

  async getTracksInPlaylist(href: string) {
    const tokenData = spotifyService.readSpotifyToken();

    const limit = 50;

    if (!tokenData) {
      throw new Error('No spotify token');
    }

    let initialUrl = new URL(href);
    initialUrl.searchParams.append('limit', limit.toString());

    let url = initialUrl.toString();

    let result: SpotifyPlaylistTracksResponse | null = null;

    while (url) {
      const response = await axios.get<SpotifyPlaylistTracksResponse>(url, {
        headers: {
          Authorization: 'Bearer ' + tokenData?.accessToken,
        },
      });

      if (!result) {
        result = response.data;
      } else {
        result.items = result.items.concat(response.data.items);
      }

      url = response.data.next;
    }

    return result!;
  }
}

const spotifyService = new SpotifyService();

export { spotifyService };

import { Box, ButtonGroup, FormControl, MenuItem } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  mutateCloneSchedule,
  mutateDeleteSchedule,
  mutateUpdateSchedule,
  mutateUpdateZone,
  queryVenueScheduleBackups,
} from '../../lib/gqlRequests';
import { ButtonGroupRow, PopupWindow } from '../wrappers';
import { LoadingAnimation } from '../LoadingAnimation';
import { RegularTextButton } from '../buttons';
import { requestDataLoader } from '../../lib/requestDataLoader';
import { ScheduleBackup } from '../../lib/types';
import { Select } from '@material-ui/core';

export const RestoreScheduleBackup = (props: {
  zoneId: number;
  venueId: number;
  close: Function;
  onDone: Function;
}) => {
  const { zoneId, venueId, close, onDone } = props;

  const [
    { data: backups, error: backupsError, loading: backupsLoading },
    setBackupsState,
  ] = useState({
    loading: false,
    error: null as null | string,
    data: null as null | ScheduleBackup[],
  });

  const [
    { data: updatedZone, error: zoneUpdateError, loading: zoneUpdateLoading },
    setZoneUpdateState,
  ] = useState({
    loading: false,
    error: null as null | string,
    data: null as null | number,
  });

  const [{ error: deleteError, loading: deleteLoading }, setDeleteState] =
    useState({
      loading: false,
      error: null as null | string,
      data: null as null | boolean,
    });

  useEffect(() => {
    if (updatedZone) {
      onDone();
    }
  }, [onDone, updatedZone]);

  const [selectedBackup, setSelectedBackup] = useState(
    null as null | ScheduleBackup
  );

  const { load: loadBackups } = useMemo(() => {
    return requestDataLoader({
      doRequest: async () => {
        const result = await queryVenueScheduleBackups({
          venueId,
        });

        return result;
      },
      onStateChange: (state) =>
        setBackupsState({
          data: state.data,
          loading: state.loading,
          error: state.error && String(state.error),
        }),
      initialValue: null as null | ScheduleBackup[],
      identifier: `getScheduleBackups`,
      formatResponse: (result) => result.venue.scheduleBackups,
    });
  }, [venueId]);

  const { load: restore } = useMemo(() => {
    return requestDataLoader({
      doRequest: async () => {
        if (!selectedBackup) {
          throw new Error('No backup or zone selected');
        }

        const newScheduleResponse = await mutateCloneSchedule({
          id: selectedBackup.id,
        });

        const newScheduleId = newScheduleResponse.cloneSchedule.id;

        await mutateUpdateSchedule({
          id: newScheduleId,
          isDraft: false,
        });

        const result = await mutateUpdateZone({
          zoneId,
          scheduleId: newScheduleId,
        });

        return result;
      },
      onStateChange: (state) =>
        setZoneUpdateState({
          data: state.data,
          loading: state.loading,
          error: state.error && String(state.error),
        }),
      initialValue: null as null | number,
      identifier: `updateZoneScheduleFromBackup`,
      formatResponse: (result) => result.updateZone.id,
    });
  }, [selectedBackup, zoneId]);

  const { load: runDelete } = useMemo(() => {
    return requestDataLoader({
      doRequest: async () => {
        if (!selectedBackup) {
          throw new Error('No backup selected');
        }

        const result = await mutateDeleteSchedule({
          id: selectedBackup?.id,
        });

        return result;
      },
      onStateChange: (state) => {
        setDeleteState({
          data: state.data,
          loading: state.loading,
          error: state.error && String(state.error),
        });

        if (state.data) {
          setBackupsState({
            data:
              backups?.filter((backup) => backup.id !== selectedBackup?.id) ||
              null,
            loading: backupsLoading,
            error: backupsError,
          });
        }
      },
      initialValue: null as null | boolean,
      identifier: `getScheduleBackups`,
      formatResponse: () => true,
    });
  }, [backups, backupsError, backupsLoading, selectedBackup]);

  useEffect(() => {
    loadBackups();
  }, [loadBackups]);

  return (
    <PopupWindow>
      {backupsError && <Box color="red">{backupsError}</Box>}
      {backupsLoading && (
        <Box display="flex" justifyContent="center">
          <LoadingAnimation animate={true} sizePx={20} color="darkblue" />
        </Box>
      )}

      {backups && (
        // <Box width="100%">
        // <InputLabel id="backup-select-label">Backup</InputLabel>
        <FormControl fullWidth>
          <Select
            labelId="select-backup-label"
            id="select-backup-select"
            label="Select backup"
            onChange={(v) => {
              setSelectedBackup(
                backups?.find((backup) => backup.id === v.target.value)!
              );
            }}
          >
            {backups?.map((backup) => (
              <MenuItem value={backup.id} key={backup.id}>
                {backup.name} ({backup.createdAt})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        // </Box>
      )}
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box>
          Restore schedule {selectedBackup?.name} to zone {zoneId}?
        </Box>
        {(zoneUpdateError || deleteError) && (
          <Box color="red">{zoneUpdateError || deleteError}</Box>
        )}
        {(zoneUpdateLoading || deleteLoading) && (
          <Box display="flex" justifyContent="center">
            <LoadingAnimation animate={true} sizePx={20} color="darkblue" />
          </Box>
        )}
        {updatedZone && <Box color="green">Zone updated</Box>}
        <ButtonGroupRow>
          <ButtonGroup>
            <RegularTextButton
              text="Restore from backup"
              active={
                !zoneUpdateLoading &&
                !!selectedBackup &&
                !zoneUpdateLoading &&
                !deleteLoading
              }
              onClick={() => {
                restore();
              }}
            />
            <RegularTextButton
              text="Delete backup"
              active={
                !!backups &&
                !backupsLoading &&
                !!selectedBackup &&
                !zoneUpdateLoading &&
                !deleteLoading
              }
              onClick={() => {
                runDelete();
              }}
            />
            <RegularTextButton
              text="Close"
              active={true}
              onClick={() => {
                close();
              }}
            />
          </ButtonGroup>
        </ButtonGroupRow>
      </Box>
    </PopupWindow>
  );
};

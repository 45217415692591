export const CONTENT_PATH = '/content';
export const PLAYLISTS_PATH = '/playlists';
export const SCHEDULER_PATH = '/scheduler';
export const MANAGE_MUSIC_AUDIO_PATH = 'manage_music_video';
export const MANAGE_MUSIC_VIDEO_PATH = 'manage_music_audio';
export const MANAGE_CHANNELS_PATH = 'manage_channels';
export const PLAYLISTS_MANAGE_MUSIC_AUDIO_PATH_PREFIX = `${PLAYLISTS_PATH}/${MANAGE_MUSIC_AUDIO_PATH}`;
export const PLAYLISTS_MANAGE_MUSIC_VIDEO_PATH_PREFIX = `${PLAYLISTS_PATH}/${MANAGE_MUSIC_VIDEO_PATH}`;
export const PLAYLISTS_MANAGE_CHANNELS_PATH_PREFIX = `${PLAYLISTS_PATH}/${MANAGE_CHANNELS_PATH}`;

export enum MEDIA_TYPES {
  MUSIC_AUDIO = 'musicAudio',
  MUSIC_VIDEO = 'musicVideo',
  CHANNEL = 'channel',
}

export const SPOTIFY_CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
export const SPOTIFY_CLIENT_SECRET =
  process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;

export const SPOTIFY_AUTH_URL = 'https://accounts.spotify.com';
export const SPOTIFY_API_URL = 'https://api.spotify.com';
export const SPOTIFY_CALLBACK_PATH = 'spotify_callback';

export const PLAYLISTS_LIMIT = 1000;
export const TRACKS_LIMIT = 1000;
export const TRACKS_IN_PLAYLIST_LIMIT = 5000;
export const MATCH_TRACKS_LIMIT = 1000;
export const LIST_SIZE_LIMIT = 10;

export const LOCAL_STORAGE_VENUES_KEY = 'venues';
export const LOCAL_STORAGE_VENUE_ID_KEY = 'venue_id';
export const LOCAL_STORAGE_ZONE_ID_KEY = 'zone_id';

export const DATE_FORMAT = 'yyyy-MM-dd'; // date-fns format
export const TIME_FORMAT = 'HH:mm:ss'; // date-fns format

export const SCHEDULE_HISTORY_LIMIT = 20;

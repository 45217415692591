import { DropzoneAreaProps } from 'react-mui-dropzone';
import { GridColType } from '@mui/x-data-grid';
import { MEDIA_TYPES } from './constants';

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_API_ADDRESS: string;
      REACT_APP_DISCOVERY_VENUE_ID: string;
      REACT_APP_SPOTIFY_CLIENT_ID: string;
      REACT_APP_SPOTIFY_CLIENT_SECRET: string;
    }
  }
}

export enum LIST_SOURCE {
  LIBRARY = 'LIBRARY',
  CATALOGUE = 'CATALOGUE',
}
export enum LIST_TYPE {
  PLAYLIST = 'PLAYLIST',
  BUCKET = 'BUCKET',
}

export type PlayableItemType = 'bucket' | 'playlist';
export type DraggedItemType = PlayableItemType | null;

export type GlobalState = {
  apiAddress: string;
  authToken: string | null;
  authRequestObjectRef: Object;
  lastLoggedInPath: string;
  userEmail: string | null;

  spotifyTokenData: SpotifyTokenData | null;

  content: {
    [MEDIA_TYPES.MUSIC_AUDIO]: ContentState;
    [MEDIA_TYPES.MUSIC_VIDEO]: ContentState;
    [MEDIA_TYPES.CHANNEL]: ContentState;
  };
  preview: PreviewState;

  playlists: {
    [MEDIA_TYPES.MUSIC_AUDIO]: PlaylistState;
    [MEDIA_TYPES.MUSIC_VIDEO]: PlaylistState;
    [MEDIA_TYPES.CHANNEL]: PlaylistState;
  };

  playlistManagement: {
    [MEDIA_TYPES.MUSIC_AUDIO]: PlaylistManagement;
    [MEDIA_TYPES.MUSIC_VIDEO]: PlaylistManagement;
    [MEDIA_TYPES.CHANNEL]: PlaylistManagement;
  };

  // venues: {
  //   loading: boolean;
  //   error: string | null;
  //   entries: Venue[] | null; // TODO;
  // };

  venues: Venue[] | null;

  scheduler: {
    selectedVenueId: number | null;
    selectedZoneId: number | null;

    scheduleLoading: boolean;
    scheduleError: string | null;
    scheduleId: number | null;
    scheduleTimeslots: TimeslotEntry[] | null;
    initialScheduleTimeslots: TimeslotEntry[] | null;

    now: Date;

    lastTimeslotStates: TimeslotEntry[][];

    draggedItem: PlaylistObject | Bucket | null;
    draggedItemType: DraggedItemType;

    selectedContentType: LIST_TYPE;
    selectedContentSource: LIST_SOURCE;

    libraryPlaylistConnection: ConnectionState<PlaylistObject>;
    catalogPlaylistConnection: ConnectionState<PlaylistObject>;
    libraryBucketConnection: ConnectionState<Bucket>;
    catalogBucketConnection: ConnectionState<Bucket>;
    listFilterText: string;
  };
};

// export type Schedule = {
//   id?: number;
//   playlist?: PlaylistObject;
//   bucket?: {
//     id: number;
//     name: string;
//     colourCode: string;
//   };
//   day: string;
//   fromTime: string;
//   toTime: string;
// };

export type Venue = {
  id: number;
  name: string;
  bannerImageUrl: string;
  zones: Zone[];
};

export type Zone = {
  id: number;
  name: string;
  mediaType: API_MEDIA_TYPES;
};

export type ContentState = {
  searchResult: AudioTrackObject[];
  selectedIds: Set<number>;
  searchInput: string;
  loading: boolean;
  error: Error | null;

  editEntries: {
    [key: string]: Partial<AudioTrackObject> & { [key: string]: any };
  };

  uploadFiles: UploadFile[];
};

export type PlaylistState = {
  playlists: PlaylistObject[];
  loading: boolean;
  error: Error | null;
  counter: number;
  selectedIds: Set<number>;
  filterText: string;
  editEntries: {
    [key: string]: Partial<PlaylistObject> & { [key: string]: any };
  };
};

export type PlaylistManagement = {
  playlistId: number | null;
  lastPlaylistId?: number;
  tracks: AudioTrackObject[];
  selectedIds: Set<number>;
  filterText: string;
  loading: boolean;
  error: Error | null;
  duplicateCount: number;
};

export type PreviewState = {
  visible: boolean;
  url: string | null;
  type?: 'audio' | 'video';
};

export type PlaylistObject = {
  id: number;
  name: string;
  generalGenre?: string;
  mediaType: string;
  isCore: boolean;
  isActive: boolean;
  colourCode: string;
  createdBy: {
    userLogin: string;
  };
  createdAt: string;
  trackCount: number;
  duration: string;
  logoImageUrl?: string;
};

export type ColumnMatcher<T> = {
  field: string;
  title: string;
  prop?: string;
  type?: GridColType;
  editable?: true;
  flex?: number;
  resolve?: (row: T) => string | any;
};

export type AudioTrackObject = {
  id: number;
  name: string;
  artist?: {
    name: string;
  };
  releaseYear: number;
  isrc: string;
  duration: string;
  contentSupplier: {
    name: string;
  };
  genre?: { name: string };
  isBlacklisted: boolean;
  label: string;
  tempo: number;
  album?: {
    id: number;
    name: string;
  };
};

export type VideoTrackObject = {
  id: number;
  name: string;
  artist?: {
    name: string;
  };
  releaseYear: number;
  isrc: string;
  duration: string;
  contentSupplier: {
    name: string;
  };
  genre?: { name: string };
  isBlacklisted: boolean;
  label: string;
  tempo: number;
  album?: {
    id: number;
    name: string;
  };
};

export type AudioTrackResponseData = {
  createCatalogSearch: {
    tracks: AudioTrackObject[];
    meta: {
      tracks?: {
        found: number;
        offset: number;
        limit: number;
      };
    };
  };
};

export type VideoTrackResponseData = {
  createCatalogSearch: {
    videoTracks: VideoTrackObject[];
    meta: {
      videoTracks?: {
        found: number;
        offset: number;
        limit: number;
      };
    };
  };
};

export type ChannelTrackResponseData = {
  createCatalogSearch: {
    channelTracks: VideoTrackObject[];
    meta: {
      channelTracks?: {
        found: number;
        offset: number;
        limit: number;
      };
    };
  };
};

export type LastAudioTracksResponseData = {
  lastUploadedTracks: AudioTrackObject[];
};

export type LastVideoTracksResponseData = {
  lastUploadedTracks: VideoTrackObject[];
};

export type VenuePlaylistsResponse = {
  venue: {
    playlists: PlaylistObject[];
    name: string;
  };
};

export type VenueVideoPlaylistsResponse = {
  venue: {
    videoPlaylists: PlaylistObject[];
    name: string;
  };
};

export type VenueChannelPlaylistsResponse = {
  venue: {
    channelPlaylists: PlaylistObject[];
    name: string;
  };
};

export type AddTracksToPlaylistResponse = {
  addTracksToPlaylist: boolean;
};

export type CreatePlaylistResponse = {
  createPlaylist: PlaylistObject;
};

export type DeleteS3TrackResponse = {
  deleteS3Track: boolean;
};

export type DeletePlaylistsResponse = {
  deletePlaylists: boolean;
};

export type SetBlacklistedResponse = {
  setTrackManualBlacklistState: boolean;
};

export enum API_MEDIA_TYPES {
  MUSIC = 'MUSIC',
  VIDEO = 'VIDEO',
  CHANNEL = 'CHANNEL',
}

export type File = Parameters<Required<DropzoneAreaProps>['onChange']>['0'][0];
export type UploadFile = {
  file: File;
  name?: string;
  artistName?: string;
  isrc?: string;
};

export type editTrackAttributesResponse = {
  editTrackAttributes: AudioTrackObject;
};

export type updatePlaylistResponse = {
  updatePlaylist: PlaylistObject;
};

export type AudioTrackContentResponse = {
  track: {
    id: number;
    contentUrl: string;
    name: string;
    processedContent: {
      contentUrl: string;
    };
  };
};

export type PlaylistTracksResponseData = {
  playlist: {
    id: number;
    tracks: AudioTrackObject[];
  };
};

export type RemoveTracksFromPlaylistResponse = {
  removeTracksFromPlaylist: boolean;
};

export type CreateSpotifyPlaylistImportResponse = {
  createSpotifyPlaylistImport: { id: number };
};

export type SpotifyTokenData = {
  accessToken: string;
  expiresAt: Date;
  refreshToken: string;
};

export type SpotifyPlaylistImportTrackMatchInput = {
  isrc: string;
  spotifyAlbumName: string;
  spotifyArtistNames: string[];
  spotifyTrackId: string;
  spotifyTrackName: string;
};

export type AddTracksToSpotifyPlaylistImportResponse = {
  addTracksToSpotifyPlaylistImport: { id: number }[];
};

export type CreatePlaylistFromSpotifyPlaylistImportReponse = {
  createPlaylistFromSpotifyPlaylistImport: PlaylistObject;
};

export type MatchSpotifyPlaylistTracksResponse = {
  matchSpotifyPlaylistTracks: {
    firstMatchingTrack: {
      id: number;
    } | null;
  }[];
};

export type DeduplicateTracksInPlaylistResponse = {
  deduplicateTracksInPlaylist: boolean;
};

export type VenuesResponse = {
  venues: Venue[];
};

export type ZoneRunsheetResponse = {
  zone: {
    id: number;
    name: string;
    schedule?: {
      id: number;
      timeslots: TimeslotResponse[];
    };
  };
};

export type ScheduleTimeslotsResponse = {
  schedule?: {
    id: number;
    timeslots: TimeslotResponse[];
  };
};

export type Timeslot = {
  id?: number;
  isRepeat: boolean;
  playlist?: PlaylistObject;
  bucket?: Bucket;
  day: string;
  from: string;
  to: string;
  repeatUntilDate?: string;
};

export type TimeslotResponse = {
  id?: number;
  repeatOf?: {
    id: number;
  };
  playlist?: PlaylistObject;
  bucket?: Bucket;
  day: string;
  from: string;
  to: string;
  repeatUntilDate?: string;
};

export enum EntryState {
  UNCHANGED = 'UNCHANGED',
  CHANGED = 'CHANGED',
  DELETED = 'DELETED',
  NEW = 'NEW',
}

export type TimeslotEntry = Timeslot & {
  state: EntryState;
  originalTs?: Timeslot;
};

export type Bucket = {
  id: number;
  name: string;
  colourCode: string;
};

export type Connection<BaseType = any> = {
  totalCount: number;
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string;
    endCursor?: string;
  };
  edges: {
    cursor: string;
    node: BaseType & { __typename: 'Bucket' | 'Playlist' };
  }[];
};

export type PlaylistConnectionResponse = {
  playlistsConnection: Connection<PlaylistObject>;
};

export type BucketConnectionResponse = {
  bucketsConnection: Connection<Bucket>;
};

export type ConnectionState<T> = {
  loading: boolean;
  error: string | null;
  connection: Connection<T> | null;
};

export type CreateTimeslotResponse = {
  createTimeslot: {
    id: number;
  };
};

export type TimeslotInput = {
  from?: string;
  to?: string;
  playlistId?: number;
  bucketId?: number;
  repeatUntilDate?: string;
};

export type UpdateTimeslotResponse = {
  updateTimeslot: {
    id: number;
  };
};

export type DeleteTimeslotResponse = {
  deleteTimeslot: boolean;
};

export type CreateScheduleResponse = {
  createSchedule: {
    id: number;
  };
};

export type CloneScheduleResponse = {
  cloneSchedule: {
    id: number;
  };
};

export type UpdateScheduleResponse = {
  updateSchedule: {
    id: number;
  };
};

export type UpdateZoneResponse = {
  updateZone: {
    id: number;
  };
};

export type BackupScheduleResponse = {
  cloneSchedule: {
    id: number;
  };
};

export type VenueScheduleBackupsResponse = {
  venue: {
    scheduleBackups: ScheduleBackup[];
  };
};

export type ScheduleBackup = {
  id: number;
  name: string;
  createdAt: string;
};

export type DeleteScheduleResponse = {
  deleteSchedule: boolean;
};

export type CloneZoneToNewVenueResponse = {
  cloneZoneToNewVenue: {
    id: number;
    name: string;
    venue: Venue;
  };
};

export type RemovePlaylistReponse = {
  removePlaylist: boolean;
};

export type RemoveBucketReponse = {
  removeBucket: boolean;
};

import { spotifyService } from '../../services/spotifyService';
import { useEffect, useMemo, useState } from 'react';
import { requestDataLoader } from '../../lib/requestDataLoader';

export const SpotifyCallback = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const receivedState = params.get('state');
  const accessError = params.get('error');

  const [status, setStatus] = useState({
    error: null as any,
    loading: false,
    data: false,
  });

  const { load } = useMemo(
    () =>
      requestDataLoader({
        doRequest: () => {
          return spotifyService.requestAccessToken(code!, receivedState!);
        },
        formatResponse: () => true,
        identifier: 'requestSpotifyAccessToken',
        initialValue: false,
        onStateChange: (state) => {
          setStatus(state);
        },
      }),
    [code, receivedState]
  );

  const { error, loading, data } = status;

  useEffect(() => {
    if (accessError || !code) {
      return;
    }

    load();
  }, [accessError, code, load]);

  if (accessError) {
    return <div>You have denied the access</div>;
  }

  if (!code) {
    return <div>Invalid return data. Something went wrong</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Wait a nanosecond...</div>;
  }

  window.close();

  return <div>Good, thank you...</div>;
};

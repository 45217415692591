import { useState } from 'react';
import { mutateAddTrackToPlaylist } from '../lib/gqlRequests';
import { requestDataLoader } from '../lib/requestDataLoader';
import { TextSolidButton } from './buttons';
import { LoadingAnimation } from './LoadingAnimation';
import { ContentHeader, PopupWindow } from './wrappers';

export const AddToPlaylistCheckout = (props: {
  trackIds: number[];
  playlists: { id: number; name: string }[];
  close: () => void;
}) => {
  const { trackIds, playlists, close } = props;
  const [startedLoad, setStartedLoad] = useState(false);
  const [playlistStatuses, setPlaylistStatuses] = useState(
    playlists.map((playlist) => ({
      playlistId: playlist.id,
      playlistName: playlist.name,
      loading: false,
      error: null as Error | null,
      data: false,
    }))
  );

  const loaders = playlistStatuses.map(({ playlistId }, i) =>
    requestDataLoader({
      identifier: `addTracksToPlaylist/${playlistId}`,
      initialValue: false,
      doRequest: () => mutateAddTrackToPlaylist({ playlistId, trackIds }),
      formatResponse: (res) => true,
      onStateChange: (output) => {
        playlistStatuses[i] = {
          ...playlistStatuses[i],
          data: output.data,
          error: output.error,
          loading: output.loading,
        };

        setPlaylistStatuses([...playlistStatuses]);
      },
    })
  );

  return (
    <PopupWindow>
      <div>
        Add {trackIds.length} tracks to {playlists.length} playlists? Dublicates
        will be skipped.
      </div>
      <table style={{ width: '100%' }}>
        <tbody>
          {playlistStatuses.map(
            ({ playlistId, playlistName, data, error, loading }) => (
              <tr key={playlistId}>
                <td style={{ whiteSpace: 'nowrap' }}>{playlistName}</td>
                <td>
                  <LoadingAnimation
                    sizePx={20}
                    animate={loading}
                    color={
                      data
                        ? 'darkgreen'
                        : loading
                        ? 'orange'
                        : error
                        ? 'tomato'
                        : 'black'
                    }
                  />
                </td>
                <td>{data ? 'Done' : loading ? 'Loading' : error || 'TODO'}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
      <div>
        <ContentHeader>
          {startedLoad || (
            <TextSolidButton
              text="Confirm"
              onClick={() => {
                setStartedLoad(true);
                loaders.forEach((l) => l.load());
              }}
            />
          )}
          <TextSolidButton
            text="Back"
            onClick={() => {
              close();
            }}
          />
        </ContentHeader>
      </div>
    </PopupWindow>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MEDIA_TYPES } from '../../../lib/constants';
import { mutateRemoveTracksFromPlaylist } from '../../../lib/gqlRequests';
import { requestDataLoader } from '../../../lib/requestDataLoader';
import { GlobalState } from '../../../lib/types';
import { SET_PLAYLISTS_MANAGE_STATE } from '../../../store/store';
import { TextSolidButton } from '../../buttons';
import { LoadingAnimation } from '../../LoadingAnimation';
import { ContentHeader, PopupWindow } from '../../wrappers';

export const RemoveFromPlaylist = (props: {
  close: () => void;
  mediaType: MEDIA_TYPES;
}) => {
  const dispatch = useDispatch();
  const { close } = props;
  const [startedLoad, setStartedLoad] = useState(false);

  const { selectedIds, playlistId, tracks } = useSelector(
    (state: GlobalState) => state.playlistManagement[props.mediaType]
  );

  useEffect(() => {
    if (!playlistId) {
      close();
    }
  }, [playlistId, close]);

  const selectedTracks = useMemo(() => {
    return tracks.filter((track) => selectedIds.has(track.id));
  }, [tracks, selectedIds]);

  const [status, setStatus] = useState({
    loading: false,
    error: null as Error | null,
    data: false,
  });

  const load = useMemo(() => {
    const { load } = requestDataLoader({
      identifier: `removeFromPlaylist/${playlistId}`,
      initialValue: false,
      doRequest: () =>
        mutateRemoveTracksFromPlaylist({
          playlistId: playlistId!,
          trackIds: selectedTracks.map((track) => track.id),
        }),
      formatResponse: (res) => res.removeTracksFromPlaylist,
      onStateChange: (output) => {
        setStatus({
          data: output.data,
          error: output.error,
          loading: output.loading,
        });

        if (output.data) {
          dispatch({
            type: SET_PLAYLISTS_MANAGE_STATE[props.mediaType],
            data: {
              selectedIds: new Set(),
              tracks: tracks.filter((t) => !selectedIds.has(t.id)),
            },
          });
        }
      },
    });

    return load;
  }, [
    playlistId,
    selectedTracks,
    dispatch,
    props.mediaType,
    tracks,
    selectedIds,
  ]);

  const { data, error, loading } = status;

  return (
    <PopupWindow>
      {data || <div>Remove {selectedIds.size} tracks from playlist?</div>}
      {data && <div>Tracks removed</div>}
      <div>
        {selectedTracks.map((track) => (
          <span style={{ padding: '5px' }} key={track.id}>
            {track.name}
          </span>
        ))}
      </div>
      <LoadingAnimation
        sizePx={20}
        animate={loading}
        color={
          data ? 'darkgreen' : loading ? 'orange' : error ? 'tomato' : 'black'
        }
      />
      {loading && <div>Removing...</div>}
      <div>
        <ContentHeader>
          {startedLoad || (
            <TextSolidButton
              text="Confirm"
              onClick={() => {
                setStartedLoad(true);

                load();
              }}
            />
          )}

          <TextSolidButton
            text="Back"
            onClick={() => {
              close();
            }}
          />
        </ContentHeader>
      </div>
    </PopupWindow>
  );
};

import { client } from './apolloClient';
import { MEDIA_TYPES } from './constants';
import {
  AddTracksToPlaylistResponse,
  AudioTrackContentResponse,
  AudioTrackResponseData,
  CreatePlaylistResponse,
  DeletePlaylistsResponse,
  DeleteS3TrackResponse,
  updatePlaylistResponse,
  editTrackAttributesResponse,
  LastAudioTracksResponseData,
  LastVideoTracksResponseData,
  SetBlacklistedResponse,
  VenuePlaylistsResponse,
  VenueVideoPlaylistsResponse,
  VideoTrackResponseData,
  PlaylistTracksResponseData,
  RemoveTracksFromPlaylistResponse,
  CreateSpotifyPlaylistImportResponse,
  SpotifyPlaylistImportTrackMatchInput,
  AddTracksToSpotifyPlaylistImportResponse,
  CreatePlaylistFromSpotifyPlaylistImportReponse,
  MatchSpotifyPlaylistTracksResponse,
  DeduplicateTracksInPlaylistResponse,
  ChannelTrackResponseData,
  VenueChannelPlaylistsResponse,
  VenuesResponse,
  ZoneRunsheetResponse,
  API_MEDIA_TYPES,
  PlaylistConnectionResponse,
  BucketConnectionResponse,
  CreateTimeslotResponse,
  UpdateTimeslotResponse,
  TimeslotInput,
  DeleteTimeslotResponse,
  CreateScheduleResponse,
  CloneScheduleResponse,
  UpdateScheduleResponse,
  UpdateZoneResponse,
  ScheduleTimeslotsResponse,
  BackupScheduleResponse,
  VenueScheduleBackupsResponse,
  DeleteScheduleResponse,
  CloneZoneToNewVenueResponse,
  RemovePlaylistReponse,
  RemoveBucketReponse,
} from './types';
import { doGQLOperation } from './utils';

import {
  loadVenuePlaylistQuery,
  loadVenueVideoPlaylistQuery,
  loadVenueChannelPlaylistQuery,
  searchTrackMutation,
  searchVideoTrackMutation,
  searchChannelTrackMutation,
  searchLastAudioTrackQuery,
  searchLastVideoTrackQuery,
  searchLastChannelTrackQuery,
  addTrackToPlaylistMutation,
  createPlaylistMutation,
  deleteS3TracksMutation,
  deletePlaylists,
  setBlacklistedMutation,
  editTrackAttributesMutation,
  updatePlaylist,
  getTrackContentUrlQuery,
  getVideoTrackContentUrlQuery,
  playlistTracksQuery,
  removeTracksFromPlaylistMutation,
  createSpotifyPlaylistImportMutation,
  addTracksToSpotifyPlaylistImportMutation,
  createPlaylistFromSpotifyPlaylistImportMutation,
  matchSpotifyPlaylistTracksMutation,
  deduplicateTracksInPlaylistMutation,
  venuesQuery,
  zoneRunsheetQuery,
  playlistConnectionQuery,
  bucketConnectionQuery,
  createTimeslotMutation,
  updateTimeslotMutation,
  deleteTimeslotMutation,
  createScheduleMutation,
  cloneScheduleMutation,
  updateScheduleMutation,
  updateZoneMutation,
  scheduleTimeslotsQuery,
  venueScheduleBackupsQuery,
  backupScheduleMutation,
  deleteScheduleMutation,
  cloneZoneToNewVenueMutation,
  removePlaylistMutation,
  removeBucketMutation,
} from './queries';

const CATALOG_VENUE_ID = Number(process.env.REACT_APP_DISCOVERY_VENUE_ID);
if (!CATALOG_VENUE_ID) {
  throw new Error(`Provide REACT_APP_DISCOVERY_VENUE_ID`);
}

export const queryMusicAudioDiscoverPlaylists = async () => {
  return await doGQLOperation<VenuePlaylistsResponse>(async () => {
    return await client.query({
      query: loadVenuePlaylistQuery,
      variables: { venueId: CATALOG_VENUE_ID },
    });
  });
};

export const queryDiscoveryVideoPlaylists = async () => {
  return await doGQLOperation<VenueVideoPlaylistsResponse>(async () => {
    return await client.query({
      query: loadVenueVideoPlaylistQuery,
      variables: { venueId: CATALOG_VENUE_ID },
    });
  });
};

export const queryDiscoveryChannelPlaylists = async () => {
  return await doGQLOperation<VenueChannelPlaylistsResponse>(async () => {
    return await client.query({
      query: loadVenueChannelPlaylistQuery,
      variables: { venueId: CATALOG_VENUE_ID },
    });
  });
};

export const mutateTrackSearch = async (params: {
  offset: number;
  limit: number;
  searchText: string;
}) => {
  const result = await doGQLOperation<AudioTrackResponseData>(async () => {
    const result = await client.mutate({
      mutation: searchTrackMutation,
      variables: params,
    });

    return result;
  });

  return result;
};

export const mutateVideoTrackSearch = async (params: {
  offset: number;
  limit: number;
  searchText: string;
}) => {
  const result = await doGQLOperation<VideoTrackResponseData>(async () => {
    const result = await client.mutate({
      mutation: searchVideoTrackMutation,
      variables: params,
    });

    return result;
  });

  return result;
};

export const mutateChannelTrackSearch = async (params: {
  offset: number;
  limit: number;
  searchText: string;
}) => {
  const result = await doGQLOperation<ChannelTrackResponseData>(async () => {
    const result = await client.mutate({
      mutation: searchChannelTrackMutation,
      variables: params,
    });

    return result;
  });

  return result;
};

export const queryLastAudioTracks = async (params: { limit: number }) => {
  const result = await doGQLOperation<LastAudioTracksResponseData>(async () => {
    const result = await client.query({
      query: searchLastAudioTrackQuery,
      variables: params,
    });

    return result;
  });

  return result;
};

export const queryLastVideoTracks = async (params: { limit: number }) => {
  const result = await doGQLOperation<LastVideoTracksResponseData>(async () => {
    const result = await client.query({
      query: searchLastVideoTrackQuery,
      variables: params,
    });

    return result;
  });

  return result;
};

export const queryLastChannelTracks = async (params: { limit: number }) => {
  const result = await doGQLOperation<LastVideoTracksResponseData>(async () => {
    const result = await client.query({
      query: searchLastChannelTrackQuery,
      variables: params,
    });

    return result;
  });

  return result;
};

export const mutateAddTrackToPlaylist = async (params: {
  playlistId: number;
  trackIds: number[];
}) => {
  const result = await doGQLOperation<AddTracksToPlaylistResponse>(async () => {
    const result = await client.mutate({
      mutation: addTrackToPlaylistMutation,
      variables: params,
    });

    return result;
  });

  return result;
};

export const mutateNewPlaylist = async (params: {
  name: string;
  venueId: number;
  mediaType: string;
}) => {
  const result = await doGQLOperation<CreatePlaylistResponse>(async () => {
    const result = await client.mutate({
      mutation: createPlaylistMutation,
      variables: params,
    });

    return result;
  });

  return result;
};

export const mutateDeleteS3Track = async (params: { id: number }) => {
  const result = await doGQLOperation<DeleteS3TrackResponse>(async () => {
    const result = await client.mutate({
      mutation: deleteS3TracksMutation,
      variables: params,
    });

    return result;
  });

  return result;
};

export const mutateDeletePlaylists = async (params: { ids: number[] }) => {
  const result = await doGQLOperation<DeletePlaylistsResponse>(async () => {
    const result = await client.mutate({
      mutation: deletePlaylists,
      variables: params,
    });

    return result;
  });

  return result;
};

export const mutateSetBlacklisted = async (params: {
  trackId: number;
  blacklisted: boolean;
  removeFromQueues: boolean;
}) => {
  const result = await doGQLOperation<SetBlacklistedResponse>(async () => {
    const result = await client.mutate({
      mutation: setBlacklistedMutation,
      variables: params,
    });

    return result;
  });

  return result;
};

export const mutateEditTrackAttributes = async (params: {
  id: number;
  label?: string;
  artistName?: string;
  tempo?: number;
  name?: string;
  releaseYear?: number;
}) => {
  const result = await doGQLOperation<editTrackAttributesResponse>(async () => {
    const result = await client.mutate({
      mutation: editTrackAttributesMutation,
      variables: {
        id: params.id,
        values: {
          label: params.label,
          name: params.name,
          artistName: params.artistName,
          tempo: params.tempo,
          releaseYear: params.releaseYear,
        },
      },
    });

    return result;
  });

  return result;
};

export const mutateUpdatePlaylist = async (
  id: number,
  params: {
    isCore: boolean;
    isActive: boolean;
    name: string;
  }
) => {
  const result = await doGQLOperation<updatePlaylistResponse>(async () => {
    const result = await client.mutate({
      mutation: updatePlaylist,
      variables: {
        id,
        isCore: params.isCore,
        isActive: params.isActive,
        name: params.name,
      },
    });

    return result;
  });

  return result;
};

export const queryTrackCOntentUrl = async (params: {
  id: number;
  mediaType: MEDIA_TYPES;
}) => {
  const result = await doGQLOperation<AudioTrackContentResponse>(async () => {
    const result = await client.query({
      query:
        params.mediaType === MEDIA_TYPES.MUSIC_AUDIO
          ? getTrackContentUrlQuery
          : getVideoTrackContentUrlQuery,
      variables: {
        id: params.id,
      },
    });

    return result;
  });

  return result;
};

export const queryPlaylistTracks = async (variables: { id: number }) => {
  const result = await doGQLOperation<PlaylistTracksResponseData>(async () => {
    const result = await client.query({
      query: playlistTracksQuery,
      variables,
    });

    return result;
  });

  return result;
};

export const mutateRemoveTracksFromPlaylist = async (variables: {
  trackIds: number[];
  playlistId: number;
}) => {
  const result = await doGQLOperation<RemoveTracksFromPlaylistResponse>(
    async () => {
      const result = await client.mutate({
        mutation: removeTracksFromPlaylistMutation,
        variables,
      });

      return result;
    }
  );

  return result;
};

export const mutateCreateSpotifyPlaylistImport = async (variables: {
  name: string;
  spotifyPlaylistId: string;
}) => {
  const result = await doGQLOperation<CreateSpotifyPlaylistImportResponse>(
    async () => {
      const result = await client.mutate({
        mutation: createSpotifyPlaylistImportMutation,
        variables: { ...variables, venueId: CATALOG_VENUE_ID },
      });

      return result;
    }
  );

  return result;
};

export const mutateAddTracksToSpotifyPlaylistImport = async (variables: {
  spotifyPlaylistImportId: number;
  tracks: SpotifyPlaylistImportTrackMatchInput[];
}) => {
  const result = await doGQLOperation<AddTracksToSpotifyPlaylistImportResponse>(
    async () => {
      const result = await client.mutate({
        mutation: addTracksToSpotifyPlaylistImportMutation,
        variables: { ...variables, venueId: CATALOG_VENUE_ID },
      });

      return result;
    }
  );

  return result;
};

export const mutateCreatePlaylistFromSpotifyPlaylistImport = async (variables: {
  spotifyPlaylistImportId: number;
}) => {
  const result =
    await doGQLOperation<CreatePlaylistFromSpotifyPlaylistImportReponse>(
      async () => {
        const result = await client.mutate({
          mutation: createPlaylistFromSpotifyPlaylistImportMutation,
          variables,
        });

        return result;
      }
    );

  return result;
};

export const mutateMatchSpotifyPlaylistTracks = async (variables: {
  tracks: SpotifyPlaylistImportTrackMatchInput[];
}) => {
  const result = await doGQLOperation<MatchSpotifyPlaylistTracksResponse>(
    async () => {
      const result = await client.mutate({
        mutation: matchSpotifyPlaylistTracksMutation,
        variables,
      });

      return result;
    }
  );

  return result;
};

export const mutateDeduplicateTracksInPlaylist = async (variables: {
  playlistId: number;
}) => {
  const result = await doGQLOperation<DeduplicateTracksInPlaylistResponse>(
    async () => {
      const result = await client.mutate({
        mutation: deduplicateTracksInPlaylistMutation,
        variables,
      });

      return result;
    }
  );

  return result;
};

export const queryVenues = async () => {
  return await doGQLOperation<VenuesResponse>(async () => {
    const result = await client.query({
      query: venuesQuery,
    });

    return result;
  });
};

export const queryZoneRunsheet = async (variables: {
  zoneId: number;
  from: string;
  to: string;
}) => {
  return await doGQLOperation<ZoneRunsheetResponse>(async () => {
    const result = await client.query({
      query: zoneRunsheetQuery,
      variables,
    });

    return result;
  });
};

export const queryScheduleTimeslots = async (variables: {
  scheduleId: number;
  from: string;
  to: string;
}) => {
  return await doGQLOperation<ScheduleTimeslotsResponse>(async () => {
    const result = await client.query({
      query: scheduleTimeslotsQuery,
      variables,
    });

    return result;
  });
};

// SCHEDULER CONNECTIONS

export const queryLibraryPlaylistConnection = async (variables: {
  venueId: number;
  first: number;
  mediaType: API_MEDIA_TYPES;
  after?: string;
}) => {
  return await doGQLOperation<PlaylistConnectionResponse>(async () => {
    const result = await client.query({
      query: playlistConnectionQuery,
      variables,
    });

    return result;
  });
};

export const queryCatalogPlaylistConnection = async (variables: {
  first: number;
  mediaType: API_MEDIA_TYPES;
  after?: string;
}) => {
  return await doGQLOperation<PlaylistConnectionResponse>(async () => {
    const result = await client.query({
      query: playlistConnectionQuery,
      variables: { ...variables, venueId: CATALOG_VENUE_ID },
    });

    return result;
  });
};

export const queryLibraryBucketConnection = async (variables: {
  venueId: number;
  first: number;
  after?: string;
}) => {
  return await doGQLOperation<BucketConnectionResponse>(async () => {
    const result = await client.query({
      query: bucketConnectionQuery,
      variables,
    });

    return result;
  });
};

export const queryCatalogBucketConnection = async (variables: {
  first: number;
  after?: string;
}) => {
  return await doGQLOperation<BucketConnectionResponse>(async () => {
    const result = await client.query({
      query: bucketConnectionQuery,
      variables: { ...variables, venueId: CATALOG_VENUE_ID },
    });

    return result;
  });
};

export const mutateCreateTimeslot = async (variables: {
  scheduleId: number;
  day: string;
  from: string;
  to: string;
  playlistId?: number;
  bucketId?: number;
  repeatUntilDate?: string;
  deleteOverlapped?: boolean;
}) => {
  return await doGQLOperation<CreateTimeslotResponse>(async () => {
    const result = await client.mutate({
      mutation: createTimeslotMutation,
      variables,
    });

    return result;
  });
};

export const mutateUpdateTimeslot = async (variables: {
  id: number;
  values: TimeslotInput | TimeslotInput[];
  deleteOverlapped?: boolean;
  freezeTimeslotsInAWeek?: boolean;
}) => {
  return await doGQLOperation<UpdateTimeslotResponse>(async () => {
    const result = await client.mutate({
      mutation: updateTimeslotMutation,
      variables,
    });

    return result;
  });
};

export const mutateDeleteTimeslot = async (variables: {
  id: number;
  includingRepeats: boolean;
}) => {
  return await doGQLOperation<DeleteTimeslotResponse>(async () => {
    const result = await client.mutate({
      mutation: deleteTimeslotMutation,
      variables,
    });

    return result;
  });
};

export const mutateCreateSchedule = async (variables: { venueId: number }) => {
  return await doGQLOperation<CreateScheduleResponse>(async () => {
    const result = await client.mutate({
      mutation: createScheduleMutation,
      variables,
    });

    return result;
  });
};

export const mutateCloneSchedule = async (variables: {
  id: number;
  toVenueId?: number;
}) => {
  return await doGQLOperation<CloneScheduleResponse>(async () => {
    const result = await client.mutate({
      mutation: cloneScheduleMutation,
      variables,
    });

    return result;
  });
};

export const mutateUpdateSchedule = async (variables: {
  id: number;
  isDraft: boolean;
}) => {
  return await doGQLOperation<UpdateScheduleResponse>(async () => {
    const result = await client.mutate({
      mutation: updateScheduleMutation,
      variables,
    });

    return result;
  });
};

export const mutateUpdateZone = async (variables: {
  zoneId: number;
  scheduleId: number;
}) => {
  return await doGQLOperation<UpdateZoneResponse>(async () => {
    const result = await client.mutate({
      mutation: updateZoneMutation,
      variables,
    });

    return result;
  });
};

export const mutateBackupSchedule = async (variables: {
  id: number;
  name: string;
}) => {
  return await doGQLOperation<BackupScheduleResponse>(async () => {
    const result = await client.mutate({
      mutation: backupScheduleMutation,
      variables,
    });

    return result;
  });
};

export const queryVenueScheduleBackups = async (variables: {
  venueId: number;
}) => {
  return await doGQLOperation<VenueScheduleBackupsResponse>(async () => {
    const result = await client.query({
      query: venueScheduleBackupsQuery,
      variables,
    });

    return result;
  });
};

export const mutateDeleteSchedule = async (variables: { id: number }) => {
  return await doGQLOperation<DeleteScheduleResponse>(async () => {
    const result = await client.mutate({
      mutation: deleteScheduleMutation,
      variables,
    });

    return result;
  });
};

export const mutateCloneZoneToNewVenu = async (variables: {
  zoneId: number;
  venueName: string;
}) => {
  return await doGQLOperation<CloneZoneToNewVenueResponse>(async () => {
    const result = await client.mutate({
      mutation: cloneZoneToNewVenueMutation,
      variables,
    });

    return result;
  });
};

export const mutateRemovePlaylist = async (variables: { id: number }) => {
  return await doGQLOperation<RemovePlaylistReponse>(async () => {
    const result = await client.mutate({
      mutation: removePlaylistMutation,
      variables,
    });

    return result;
  });
};

export const mutateRemoveBucket = async (variables: { id: number }) => {
  return await doGQLOperation<RemoveBucketReponse>(async () => {
    const result = await client.mutate({
      mutation: removeBucketMutation,
      variables,
    });

    return result;
  });
};

import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  concat,
} from '@apollo/client/core';
import { store } from '../store/store';

const authMiddleware = new ApolloLink((operation, forward) => {
  const authorization = `Bearer ${store.getState().authToken}`;

  operation.setContext({
    headers: {
      authorization,
    },
  });

  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
  link: concat(
    authMiddleware,
    new HttpLink({
      uri: process.env.REACT_APP_API_ADDRESS + '/',
    })
  ),
});

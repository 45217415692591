import { TextField } from '@mui/material';
import { useState } from 'react';

export const FormTextInput = ({
  onChange,
  onSubmit,
  placeholder,
  type,
  name,
  id,
  inputValue,
  pattern,
  label,
}: {
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  placeholder: string;
  type: string;
  name?: string;
  id?: string;
  inputValue?: string;
  pattern?: string;
  label?: string;
}) => {
  const [value, setValue] = useState(inputValue || '');

  return (
    <TextField
      className="search-input"
      autoComplete="on"
      label={label}
      id={id}
      type={type}
      value={inputValue}
      name={name}
      placeholder={placeholder}
      inputProps={{
        pattern,
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && onSubmit) {
          onSubmit(value);
        }
      }}
      onChange={(e) => {
        setValue(e.target.value);
        onChange?.(e.target.value);
      }}
    />
  );
};

export const FileUploadInput = ({
  onChange,
  onSubmit,
  placeholder,
  type,
  name,
  id,
  inputValue,
}: {
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  placeholder: string;
  type: string;
  name?: string;
  id?: string;
  inputValue?: string;
}) => {
  const [value, setValue] = useState('');

  return (
    <div className="form-element">
      <input
        className="search-input"
        autoComplete="on"
        id={id}
        type={type}
        value={inputValue}
        name={name}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && onSubmit) {
            onSubmit(value);
          }
        }}
        onChange={(e) => {
          setValue(e.target.value);
          onChange && onChange(e.target.value);
        }}
      />
    </div>
  );
};

import { Backdrop } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MEDIA_TYPES } from '../lib/constants';
import { queryTrackCOntentUrl } from '../lib/gqlRequests';
import { requestDataLoader } from '../lib/requestDataLoader';
import { GlobalState, PreviewState } from '../lib/types';
import { ACTION_TYPES } from '../store/store';
import { PreviewCloseButton, RegularTextButton } from './buttons';
import { LoadingAnimation } from './LoadingAnimation';

type ContentResponse = {
  url: string;
  name: string;
  blob?: Blob;
};

export const PreviewCell = (props: { id: number; mediaType: MEDIA_TYPES }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(null as Error | null);
  const [data, setData] = useState(null as ContentResponse | null);

  const { load } = requestDataLoader({
    initialValue: undefined as undefined | ContentResponse,
    doRequest: async () => {
      const data = await queryTrackCOntentUrl({
        id: props.id,
        mediaType: props.mediaType,
      });

      const contentAddr =
        props.mediaType === MEDIA_TYPES.MUSIC_AUDIO
          ? data.track.processedContent.contentUrl
          : data.track.contentUrl;

      try {
        const res = await fetch(contentAddr, {
          mode: 'cors',
        });

        const url = new URL(contentAddr);
        const extension = url.pathname.match(/\.[A-z0-9]{2,4}$/gi)?.[0];
        return {
          url: contentAddr,
          name: data.track.name + extension,
          blob: await res.blob(),
        };
      } catch (err) {
        return {
          url: contentAddr,
          name: data.track.name,
          blob: undefined,
        };
      }
    },
    identifier: `trackContent/${props.id}`,
    formatResponse: (res) => res,
    onStateChange: (state) => {
      setLoading(state.loading);
      setError(state.error);
      state.data && setData(state.data);

      if (state.data?.blob) {
        // Create an object URL
        const url = URL.createObjectURL(state.data.blob);
        showPreview();

        // Release the object URL
        URL.revokeObjectURL(url);
      }
    },
  });

  const showPreview = useCallback(() => {
    if (data?.url) {
      dispatch({
        type: ACTION_TYPES.SET_PREVIEW,
        data: {
          visible: true,
          url: data.url,
          type: props.mediaType,
        },
      });
    }
  }, [data?.url, props.mediaType, dispatch]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        justifySelf: 'center',
        width: '100%',
      }}
    >
      {loading ? (
        <LoadingAnimation
          sizePx={35}
          period={2}
          color={'blue'}
          animate={true}
        />
      ) : data?.url ? (
        <RegularTextButton
          active={!loading}
          onClick={showPreview}
          text={'Play'}
        />
      ) : (
        <RegularTextButton active={!loading} onClick={load} text={'Load'} />
      )}
    </div>
  );
};

export const PlayerOverlay = () => {
  const dispatch = useDispatch();
  const { url, visible, type } = useSelector<
    GlobalState,
    GlobalState['preview']
  >(({ preview }): PreviewState => preview);

  const handleClose = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.SET_PREVIEW,
      data: {
        visible: false,
        url: null,
      },
    });
  }, [dispatch]);

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      open={visible}
      onClick={handleClose}
    >
      {url ? (
        <>
          {type === 'audio' ? (
            <audio
              style={{
                objectFit: 'contain',
                padding: 30,
              }}
              src={url}
              controls
              autoPlay
            />
          ) : (
            <video
              style={{
                objectFit: 'contain',
                padding: 30,
              }}
              src={url}
              controls
              autoPlay
            />
          )}
        </>
      ) : (
        <div style={{ color: '#FFF' }}>Content error</div>
      )}
      <PreviewCloseButton onClick={handleClose} />
    </Backdrop>
  );
};

import { useDispatch, useSelector } from 'react-redux';
import { SET_PLAYLISTS_MANAGE_STATE } from '../../../store/store';
import { queryPlaylistTracks } from '../../../lib/gqlRequests';
import {
  AudioTrackObject,
  GlobalState,
  PlaylistManagement,
  PlaylistTracksResponseData,
} from '../../../lib/types';
import { requestDataLoader } from '../../../lib/requestDataLoader';

import { useEffect, useMemo, useState } from 'react';
import { RegularTextButton } from '../../buttons';
import { PlaylistTracksTable } from './PlaylistTrackTable';
import { MEDIA_TYPES, PLAYLISTS_PATH } from '../../../lib/constants';
import { ButtonGroup, Dialog } from '@mui/material';
import { RemoveFromPlaylist } from '../shared/RemoveFromPlaylist';
import { DeduplicatePlaylist } from '../shared/DeduplicatePlaylist';
import { ButtonGroupRow } from '../../wrappers';

export const ManagePlaylist = () => {
  const dispatch = useDispatch();
  const [deletePopup, setDeletePopup] = useState(false);
  const [deduplicatePopup, setDeduplicatePopup] = useState(false);
  const { selectedIds, playlistId, duplicateCount, lastPlaylistId } =
    useSelector<GlobalState, GlobalState['playlistManagement'][MEDIA_TYPES]>(
      (state) => state.playlistManagement.musicAudio
    );

  useEffect(() => {
    if (!playlistId) {
      document.location.href = PLAYLISTS_PATH;
    }
  }, [playlistId]);

  /**Since DataGrid updates selection model on each render, this workaround is used to prevent cyclic re-rendering */

  const load = useMemo(() => {
    const { load } = requestDataLoader<
      PlaylistTracksResponseData,
      AudioTrackObject[]
    >({
      doRequest: () =>
        queryPlaylistTracks({
          id: playlistId!,
        }),
      onStateChange: (output) => {
        let uniqueTracks: AudioTrackObject[] = [];

        let duplicateCount = 0;

        if (output.data) {
          const uniqueTrackIds = new Set<number>(output.data.map((t) => t.id));

          uniqueTracks = output.data.filter((track) => {
            const removed = uniqueTrackIds.delete(track.id);
            if (!removed) {
              duplicateCount++;
            }
            return removed;
          });
        }

        dispatch({
          type: SET_PLAYLISTS_MANAGE_STATE[MEDIA_TYPES.MUSIC_AUDIO],
          data: {
            tracks: uniqueTracks,
            error: output.error,
            loading: output.loading,
            duplicateCount: duplicateCount,
          } as PlaylistManagement,
        });
      },
      initialValue: [],
      identifier: 'musicAudioTracks',
      formatResponse: (result) => result.playlist.tracks,
    });

    return load;
  }, [dispatch, playlistId]);

  useEffect(() => {
    if (lastPlaylistId === playlistId) {
      return;
    }

    load();

    dispatch({
      type: SET_PLAYLISTS_MANAGE_STATE[MEDIA_TYPES.MUSIC_AUDIO],
      data: {
        lastPlaylistId: playlistId,
      } as PlaylistManagement,
    });
  }, [playlistId, lastPlaylistId, dispatch, load]); // Should be called only once unless playlistId changes

  return (
    <div>
      {/* HEADER */}
      <ButtonGroupRow>
        <ButtonGroup>
          <RegularTextButton
            text="Refresh"
            active={true}
            onClick={() => load()}
          />
          {(duplicateCount && (
            <RegularTextButton
              text={`Deduplicate ${duplicateCount} tracks`}
              active={true}
              onClick={() => setDeduplicatePopup(true)}
            />
          )) ||
            null}
        </ButtonGroup>

        <ButtonGroup>
          <RegularTextButton
            text="Remove"
            active={selectedIds.size > 0}
            onClick={() => setDeletePopup(true)}
          />
        </ButtonGroup>
      </ButtonGroupRow>

      {/* BODY */}
      <PlaylistTracksTable />

      {/**POPUPS */}
      <Dialog
        open={deletePopup}
        onBackdropClick={() => setDeletePopup(false)}
        onClose={() => setDeletePopup(false)}
        maxWidth="xl"
      >
        <RemoveFromPlaylist
          close={() => setDeletePopup(false)}
          mediaType={MEDIA_TYPES.MUSIC_AUDIO}
        />
      </Dialog>
      <Dialog
        open={deduplicatePopup}
        onBackdropClick={() => setDeduplicatePopup(false)}
        onClose={() => setDeduplicatePopup(false)}
        maxWidth="xl"
      >
        <DeduplicatePlaylist
          close={() => setDeduplicatePopup(false)}
          mediaType={MEDIA_TYPES.MUSIC_AUDIO}
        />
      </Dialog>
    </div>
  );
};

import {
  AudioTrackObject,
  ColumnMatcher,
  PlaylistObject,
  VideoTrackObject,
} from './types';

export const audioContentTableColumnResolver: ColumnMatcher<AudioTrackObject>[] =
  [
    {
      title: 'Content ID',
      prop: 'id',
      field: 'id',
      type: 'string',
      // resolve: (row) => row.id,
      flex: 80,
    },
    {
      title: 'Artist',
      field: 'artistName',
      editable: true,
      resolve: (row) => row?.artist?.name,
    },
    {
      title: 'Album',
      field: 'albumName',
      resolve: (row) => row?.album?.name,
    },
    {
      title: 'Track Name',
      prop: 'name',
      field: 'name',
      editable: true,
      // resolve: (row) => row?.name,
    },
    {
      title: 'ISRC',
      field: 'isrc',
      prop: 'isrc',
      // resolve: (row) => row?.isrc,
    },
    {
      title: 'Content Supplier',
      field: 'contentSupplierName',
      resolve: (row) => row?.contentSupplier?.name,
    },
    {
      title: 'Label',
      prop: 'label',
      field: 'label',
      editable: true,
      flex: 60,
      resolve: (row) => `-`,
    },
    {
      title: 'Duration',
      field: 'duration',
      prop: 'duration',
      // resolve: (row) => row?.duration,
      flex: 80,
    },
    {
      title: 'Year',
      prop: 'releaseYear',
      field: 'year',
      editable: true,
      type: 'string',
      flex: 50,
      // resolve: (row) => row?.releaseYear,
    },
    {
      title: 'Era',
      field: 'era',
      flex: 70,
      resolve: ({ releaseYear }) => {
        if (!releaseYear) return '-';

        let decade = (Math.floor((releaseYear % 100) / 10) * 10).toString();
        if (releaseYear >= 2000) {
          decade = '20' + decade.padStart(2, '0');
        }

        let decadePart = '';
        const yearInDecade = releaseYear % 10;
        switch (true) {
          case yearInDecade >= 0 && yearInDecade <= 2:
            decadePart = 'Early';
            break;
          case yearInDecade >= 3 && yearInDecade <= 6:
            decadePart = 'Mid';
            break;
          case yearInDecade >= 7 && yearInDecade <= 9:
            decadePart = 'Late';
            break;
        }

        return `${decadePart} ${decade}s`;
      },
    },
    {
      title: 'Tempo',
      field: 'tempo',
      prop: 'tempo',
      editable: true,
      flex: 60,
      resolve: (row) => `-`,
    },
    {
      title: 'Genre',
      field: 'genre',
      resolve: (row) => row?.genre?.name,
    },
    {
      title: 'BL?',
      prop: 'isBlacklisted',
      field: 'isBlacklisted',
      type: 'boolean',
      flex: 50,
      resolve: (row) => (row?.isBlacklisted ? 'yes' : ''),
    },
    {
      title: 'Download',
      field: 'link',
      type: 'contentUrlButton',
      resolve: (row) => {
        return row;
      },
    },
    {
      title: 'Preview',
      field: 'preview',
      type: 'contentPlaybackButton',
      resolve: (row) => {
        return row;
      },
    },
  ];

export const playlistColumnResolver: ColumnMatcher<PlaylistObject>[] = [
  {
    title: 'ID',
    prop: 'id',
    field: 'id',
    resolve: (row) => row.id,
  },
  {
    title: 'Name',
    prop: 'name',
    field: 'name',
    editable: true,
    resolve: (row) => row.name,
  },
  {
    title: 'General Genre',
    prop: 'generalGenre',
    field: 'generalGenre',
    resolve: (row) => row.generalGenre,
  },
  {
    title: 'Media Type',
    prop: 'mediaType',
    field: 'mediaType',
    resolve: (row) => row.mediaType,
  },
  {
    title: 'Created At',
    prop: 'createdAt',
    field: 'createdAt',
  },
  {
    title: 'Creator',
    field: 'creator',
    resolve: (row) => row.createdBy?.userLogin || '?',
  },
];

export const fullPlaylistColumnResolver: ColumnMatcher<PlaylistObject>[] = [
  ...playlistColumnResolver,
  {
    title: 'Core?',
    prop: 'isCore',
    field: 'isCore',
    type: 'boolean',
    editable: true,
  },
  {
    title: 'Active?',
    prop: 'isActive',
    field: 'isActive',
    type: 'boolean',
    editable: true,
  },
  {
    title: 'Manage',
    field: 'manage',
    type: 'tabLink',
    resolve: (row) => {
      return row;
    },
  },
];

export const videoContentTableColumnResolver: ColumnMatcher<VideoTrackObject>[] =
  [
    {
      title: 'Content ID',
      prop: 'id',
      field: 'id',
      type: 'string',
      // resolve: (row) => row.id,
      flex: 80,
    },
    {
      title: 'Artist',
      field: 'artistName',
      editable: true,
      resolve: (row) => row?.artist?.name,
    },
    {
      title: 'Album',
      field: 'albumName',
      resolve: (row) => row?.album?.name,
    },
    {
      title: 'Track Name',
      prop: 'name',
      field: 'name',
      editable: true,
      // resolve: (row) => row?.name,
    },
    {
      title: 'ISRC',
      field: 'isrc',
      prop: 'isrc',
      // resolve: (row) => row?.isrc,
    },
    {
      title: 'Content Supplier',
      field: 'contentSupplierName',
      resolve: (row) => row?.contentSupplier?.name,
    },
    {
      title: 'Label',
      prop: 'label',
      field: 'label',
      editable: true,
      flex: 60,
      resolve: (row) => `-`,
    },
    {
      title: 'Duration',
      field: 'duration',
      prop: 'duration',
      // resolve: (row) => row?.duration,
      flex: 80,
    },
    {
      title: 'Year',
      prop: 'releaseYear',
      field: 'year',
      editable: true,
      type: 'string',
      flex: 50,
      // resolve: (row) => row?.releaseYear,
    },
    {
      title: 'Era',
      field: 'era',
      flex: 70,
      resolve: ({ releaseYear }) => {
        if (!releaseYear) return '-';

        let decade = (Math.floor((releaseYear % 100) / 10) * 10).toString();
        if (releaseYear >= 2000) {
          decade = '20' + decade.padStart(2, '0');
        }

        let decadePart = '';
        const yearInDecade = releaseYear % 10;
        switch (true) {
          case yearInDecade >= 0 && yearInDecade <= 2:
            decadePart = 'Early';
            break;
          case yearInDecade >= 3 && yearInDecade <= 6:
            decadePart = 'Mid';
            break;
          case yearInDecade >= 7 && yearInDecade <= 9:
            decadePart = 'Late';
            break;
        }

        return `${decadePart} ${decade}s`;
      },
    },
    {
      title: 'Tempo',
      field: 'tempo',
      prop: 'tempo',
      editable: true,
      flex: 60,
      resolve: (row) => `-`,
    },
    {
      title: 'Genre',
      field: 'genre',
      resolve: (row) => row?.genre?.name,
    },
    {
      title: 'BL?',
      prop: 'isBlacklisted',
      field: 'isBlacklisted',
      type: 'boolean',
      flex: 50,
      resolve: (row) => (row?.isBlacklisted ? 'yes' : ''),
    },
    {
      title: 'Download',
      field: 'link',
      type: 'contentUrlButton',
      resolve: (row) => {
        return row;
      },
    },
    {
      title: 'Preview',
      field: 'preview',
      type: 'contentPlaybackButton',
      resolve: (row) => {
        return row;
      },
    },
  ];

export const channelContentTableColumnResolver: ColumnMatcher<VideoTrackObject>[] =
  [
    {
      title: 'Content ID',
      prop: 'id',
      field: 'id',
      type: 'string',
      // resolve: (row) => row.id,
      flex: 80,
    },
    {
      title: 'Artist',
      field: 'artistName',
      editable: true,
      resolve: (row) => row?.artist?.name,
    },
    {
      title: 'Album',
      field: 'albumName',
      resolve: (row) => row?.album?.name,
    },
    {
      title: 'Track Name',
      prop: 'name',
      field: 'name',
      editable: true,
      // resolve: (row) => row?.name,
    },
    {
      title: 'ISRC',
      field: 'isrc',
      prop: 'isrc',
      // resolve: (row) => row?.isrc,
    },
    {
      title: 'Content Supplier',
      field: 'contentSupplierName',
      resolve: (row) => row?.contentSupplier?.name,
    },
    {
      title: 'Label',
      prop: 'label',
      field: 'label',
      editable: true,
      flex: 60,
      resolve: (row) => `-`,
    },
    {
      title: 'Duration',
      field: 'duration',
      prop: 'duration',
      // resolve: (row) => row?.duration,
      flex: 80,
    },
    {
      title: 'Year',
      prop: 'releaseYear',
      field: 'year',
      editable: true,
      type: 'string',
      flex: 50,
      // resolve: (row) => row?.releaseYear,
    },
    {
      title: 'Era',
      field: 'era',
      flex: 70,
      resolve: ({ releaseYear }) => {
        if (!releaseYear) return '-';

        let decade = (Math.floor((releaseYear % 100) / 10) * 10).toString();
        if (releaseYear >= 2000) {
          decade = '20' + decade.padStart(2, '0');
        }

        let decadePart = '';
        const yearInDecade = releaseYear % 10;
        switch (true) {
          case yearInDecade >= 0 && yearInDecade <= 2:
            decadePart = 'Early';
            break;
          case yearInDecade >= 3 && yearInDecade <= 6:
            decadePart = 'Mid';
            break;
          case yearInDecade >= 7 && yearInDecade <= 9:
            decadePart = 'Late';
            break;
        }

        return `${decadePart} ${decade}s`;
      },
    },
    {
      title: 'Tempo',
      field: 'tempo',
      prop: 'tempo',
      editable: true,
      flex: 60,
      resolve: (row) => `-`,
    },
    {
      title: 'Genre',
      field: 'genre',
      resolve: (row) => row?.genre?.name,
    },
    {
      title: 'BL?',
      prop: 'isBlacklisted',
      field: 'isBlacklisted',
      type: 'boolean',
      flex: 50,
      resolve: (row) => (row?.isBlacklisted ? 'yes' : ''),
    },
    {
      title: 'Download',
      field: 'link',
      type: 'contentUrlButton',
      resolve: (row) => {
        return row;
      },
    },
    {
      title: 'Preview',
      field: 'preview',
      type: 'contentPlaybackButton',
      resolve: (row) => {
        return row;
      },
    },
  ];

import type { FetchResult } from '@apollo/client';
import type { GraphQLError } from 'graphql';

export const joinErrors = (errors?: readonly GraphQLError[]) => {
  if (!errors || errors.length < 0) {
    return undefined;
  }
  return (
    'Server error: ' +
    errors.map((err) => `${err.message} (${(err as any).id})`).join(';')
  );
};

export async function doGQLOperation<T>(
  operation: () => Promise<FetchResult<T>>
) {
  try {
    const result = await operation();

    const err = joinErrors(result.errors);
    if (err) {
      throw new Error(`Server responded with errors: ${err}`);
    }

    if (!result.data && !result.errors)
      throw new Error(
        'No errors returned by @apollo/client despite they have been returned by Server'
      );

    return result.data!;
  } catch (err) {
    throw new Error(`Cannot perform GraphQL request: ${err}`);
  }
}

export const getParentPath = (path: string) =>
  path.split('/').slice(0, -1).join('/') || '/';

export const removeUndefinedProperties = (obj: Object) =>
  Object.fromEntries(
    Object.entries(obj).filter(([, val]) => val !== undefined)
  );

export function humanSizeBytes(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
}

export function removeExtension(fileName: string) {
  const extension = /\..{2,4}$/g.exec(fileName)?.[0];
  if (!extension) return fileName;

  return fileName.slice(0, 0 - extension.length);
}

export const generateRandomString = (length: number) => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let codeChallenge = '';
  for (let i = 0; i < length; i++) {
    codeChallenge += chars[Math.floor(Math.random() * chars.length)];
  }
  return codeChallenge;
};

async function sha256(plain: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return await window.crypto.subtle.digest('SHA-256', data);
}

function base64urlencode(a: ArrayBuffer) {
  var str = '';
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export async function getChallenge(verifier: string) {
  const hashed = await sha256(verifier);
  const base64encoded = base64urlencode(hashed);
  return base64encoded;
}
